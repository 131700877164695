import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Panel } from 'rsuite';

import { Loader } from '@components/loader/loader';
import { FormField } from '@components/form/form-field';
import { getCarFields, getCarFieldsGroups, putUpdateCarFields } from '@api/cars';

import { EnumField } from './components/enum-field';
import { BooleanField } from './components/boolean-field';
import { ModalConfirm } from './components/modal-confirm';
import { fieldType, getArraySubmitValues } from './utils';

import './car-form-components.scss';

export const Completeness = ({ onStepChange, edit, carData, showConfirmPopup, onConfirmChangeStep }) => {
  const carId = carData?.id;

  const [carFields, setCarFields] = useState();
  const [fieldsGroups, setFieldsGroups] = useState();
  const [loading, setLoading] = useState(edit);
  const [defaultValues, setDefaultValues] = useState({});

  const context = useForm({ defaultValues: {}, mode: 'onChange' });
  const values = context.watch();
  const { isDirty } = context.formState;

  const fields = useMemo(() => fieldsGroups?.[0]?.fields || [], [fieldsGroups]);

  useEffect(() => {
    getCarFields(carId).then(setCarFields);
    getCarFieldsGroups(carData?.car?.type?.key).then(setFieldsGroups);
  }, [carId]);

  useEffect(() => {
    if (edit && carFields?.length) {
      const newDefaultValues = {};
      const category = carFields.find((item) => item.name === 'Комплектность');

      category?.fields.forEach((field) => {
        if (field.type === 'bool' || field.type === 'int') {
          newDefaultValues[`${field.id}_`] = String(field.saved_value?.value);
        } else if (field.type === 'enum') {
          newDefaultValues[`${field.id}_`] = { label: field.saved_value?.value, value: field.saved_value?.value };
        } else if (field.type === 'array') {
          newDefaultValues[`${field.id}_`] = field.saved_value?.value?.map((item) => {
            return { label: item, value: item };
          });
        } else {
          newDefaultValues[`${field.id}_`] = field.saved_value?.value;
        }
      });

      context.reset(newDefaultValues);

      setDefaultValues(newDefaultValues);
      setLoading(false);
    }
  }, [carFields, edit]);

  const saveRequest = () => {
    const fields = getArraySubmitValues(values);

    if (fields.length) {
      return putUpdateCarFields(carId, { fields });
    }
  };

  const onSubmit = context.handleSubmit(
    async () => {
      try {
        // setFormState({ formName: 'completeness', values });
        const response = await saveRequest();
        if (!response?.message) onStepChange();
      } catch (err) {
        console.error(err);
      }
    },
  );

  return (
    <div>
      <div className="title mb-4">
        <span>Комплектность</span>
      </div>
      {loading
        ? <Loader />
        : (
          <FormProvider {...context}>
            <Panel className="all-info-wrapper">
              {fields.map((field) => {
                const { type, name } = field;
                const fieldName = field?.key || `${field?.id}_`;

                if (type === 'enum' || type === 'array') {
                  return (
                    <div className="info-field-wrapper" key={name}>
                      <EnumField
                        field={field}
                        defaultValue={defaultValues[fieldName]}
                        isClearable={true}
                      />
                    </div>
                  );
                }

                if (type === 'bool') {
                  return (
                    <div className="info-field-wrapper" key={name}>
                      <BooleanField
                        field={{ ...field, fieldName }}
                        value={values[fieldName]}
                      />
                    </div>
                  );
                }

                return (
                  <div className="info-field-wrapper" key={name}>
                    <FormField
                      name={field.key || `${field.id}_`}
                      label={field.name}
                      className="info-field"
                      placeholder={field.name === 'Комплект ключей' ? 'Количество' : ''}
                      as={fieldType[type]}
                      defaultValue={values[fieldName]}
                      key={field.name}
                      isClearable={true}
                    />
                  </div>
                );
              })}
            </Panel>
            <div className="d-flex align-items-center gap-3 mt-3 mob-button-wrapper">
              <button className="btn btn-primary flex-grow-1" type="submit" onClick={onSubmit}>
                Сохранить
              </button>
              {edit && (
                <button className="btn btn-light flex-grow-1" type="button" onClick={onStepChange}>
                  Пропустить
                </button>
              )}
            </div>
          </FormProvider>
        )}
      {showConfirmPopup && (
        <ModalConfirm
          onSave={saveRequest}
          onClose={onConfirmChangeStep}
          isDirty={isDirty}
        />
      )}
    </div>
  );
};
