import React from 'react';

export const Tabs = ({ value, options = [], onChange = () => {} }) => {
  if (!options.length) return null;

  const onChangeHandler = (val) => () => {
    onChange(val);
  };

  return (
    <div className="tabs d-flex">
      {options.map((option) => (
        <div key={`${option.value}-${option.label}`}>
          <button
            onClick={onChangeHandler(option.value)}
            className={`tab-btn ${option.value === value ? 'tab-btn-active' : ''}`}
            type="button"
          >
            {option.label}
          </button>
        </div>
      ))}
    </div>
  );
};
