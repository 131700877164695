import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { getSaleModelsRequest } from '@api/dictionaries';
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { Input } from '@components/input/input';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import { Select } from '@components/select/select';
import { Button } from '@components/button/button';
import { carBrandsStore } from '@mobx/car-brands';
import { getCarsList, getParamsWithFilters } from '@api/cars';

import { COLUMNS } from './utils';

import './cars-modal.scss';

const emptyFilters = {
  'vin': '',
  'brand_id': '',
  'model_id': '',
  'reg_number': '',
  'department_ids': '',
  'in_department_ids': '',
};

export const CarsModal = ({ show, onHide, onChangeSales, sales, singleSelect }) => {
  const { brands } = carBrandsStore;
  const [cars, setCars] = useState();
  const [models, setModels] = useState([]);

  const [selectedSales, setSelectedSales] = useState(sales || []);
  const [selectedRows, setSelectedRows] = useState(sales ? sales.map((item) => item.id) : []);
  const [filters, setFilters] = useState(emptyFilters);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
    selected: selectedRows,
    onSelectAll: (isSelect) => {
      if (singleSelect) {
        setSelectedSales([]);
        setSelectedRows([]);
      } else if (isSelect) {
        setSelectedSales(() => cars.data);
        setSelectedRows(() => cars.data.map((item) => item.id));
      } else {
        setSelectedSales([]);
        setSelectedRows([]);
      }
    },
    onSelect: (row, isSelect) => {
      if (singleSelect) {
        if (isSelect) {
          setSelectedSales([row]);
          setSelectedRows([row.id]);
        } else {
          setSelectedSales([]);
          setSelectedRows([]);
        }
      } else if (isSelect) {
        setSelectedSales((prev) => [...prev, row]);
        setSelectedRows((prev) => [...prev, row.id]);
      } else {
        setSelectedSales((prev) => prev.filter((item) => item.id !== row.id));
        setSelectedRows((prev) => prev.filter((item) => item !== row.id));
      }
    },
  };

  useEffect(() => {
    requestCars(1);
    if (!brands.length) carBrandsStore.fetch();
  }, []);

  const requestCars = (page, per_page) => {
    getCarsList({ 'filters[status_code]': 'sale_ready_for_sale', page, per_page }).then(setCars);
  };

  const saveSales = () => {
    onChangeSales(selectedSales);
    onHide();
  };

  const changePage = (index) => {
    if (index > 0) {
      requestCars(index);
    }
  };

  const pages = [];

  if (cars) {
    const count = Math.ceil(cars.total / cars.per_page);

    for (let i = 1; i <= count; i += 1) {
      pages.push(i);
    }
  }

  const filterSales = () => {
    const newFilters = { ...filters };

    if (filters.reg_number.length >= 3) {
      newFilters.reg_number = `%${filters.reg_number}%`;
    } else {
      delete newFilters.reg_number;
    }

    const newParams = getParamsWithFilters({ 'filters[status_code]': 'sale_ready_for_sale' }, newFilters);

    getCarsList(newParams).then(setCars);
  };

  const clearAllFilters = () => {
    requestCars(1);
    setFilters(emptyFilters);
  };

  const handleChangeFilters = (e) => {
    const newFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };

    if (e.target.name === 'brand_id') {
      newFilters.model_id = '';
    }

    setFilters(newFilters);
  };

  const elFilters = () => {
    return (
      <div className="car-sales__filters-wrapper">
        <div className="filter">
          <div className="d-flex align-items-center flex-wrap gap-10px">
            <Select
              options={brands}
              valueId={filters?.brand_id}
              size="xs"
              placeholder="Марка"
              onChange={({ value }) => {
                handleChangeFilters({ target: { name: 'brand_id', value } });
                getSaleModelsRequest(value, 'car').then(setModels);
              }}
            />
            <Select
              options={models?.map((model) => ({ value: model.id, label: model.name }))}
              valueId={filters?.model_id}
              size="xs"
              placeholder="Модель"
              onChange={({ value }) => handleChangeFilters({ target: { name: 'model_id', value } })}
            />
            <Input
              placeholder="VIN"
              name="vin"
              value={filters.vin}
              onChange={handleChangeFilters}
              className="input_small"
              size="xs"
            />
            <Input
              placeholder="Гос. номер"
              name="reg_number"
              value={filters.reg_number}
              onChange={handleChangeFilters}
              className="input_small"
              size="xs"
            />
            <InputRequisitesSearch
              placeholder="Компании или подразделения"
              size="xs"
              onChange={(value) => {
                handleChangeFilters({ target: { name: 'department_ids', value: [value] } });
              }}
            />
            <InputRequisitesSearch
              placeholder="Компании"
              size="xs"
              onChange={(value) => {
                handleChangeFilters({ target: { name: 'in_department_ids', value: [value] } });
              }}
            />
          </div>
          <div className="car-sales__filters-buttons">
            <Button onClick={filterSales} size="s">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} size="s" preset="secondary" className="d-flex align-items-center gap-5px">
              <img src="/img/icons/filters-off-icon.svg" />
              Очистить
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="car-modal-wrapper">
      <Modal show={show} onHide={onHide} className="content-modal-car">
        <div className="d-flex align-items-start header-car">
          <div className="title">Выберите автомобили для добавления в лот</div>
        </div>
        {elFilters()}
        <div className="car-table">
          {cars?.data?.length ? (
            <Table
              data={cars?.data}
              selectRow={selectRow}
              columns={COLUMNS}
            />
          ) : (
            <p>Доступных машин нет</p>
          )}
          <Pagination
            data={cars}
            onChangePage={changePage}
            onChangePerPage={(e) => requestCars(1, e.target.value)}
            perPageList={[25, 50, 100, 250, 500]}
          />
          <Button onClick={saveSales} preset="primary">
            Добавить
          </Button>
        </div>
      </Modal>
    </div>
  );
};
