import React from 'react';
import { Link } from 'react-router-dom';

import { getPhotoSrc } from '@utils/photo';
import { VIEW_HORIZONTAL } from '@pages/current-trades/current-trades-filters/utils';
import { CarChecks } from '@components/car-checks/car-checks';
import { formatValueWithSpaces } from '@utils/format';

export const CarItem = ({ sale, view, params, lot }) => {
  const carLink = `/car/${sale?.id}${params ? `?${params}` : ''}`;

  const carName = (
    <Link to={carLink} className="car-name">
      {sale?.display_name}
    </Link>
  );

  return (
    <div className="car-wrapper">
      <Link to={carLink}>
        <img
          src={getPhotoSrc(sale?.preview?.thumbnails_urls?.small || sale?.preview?.file?.url)}
          className="car-card__main-image"
          alt=""
        />
      </Link>
      <div>
        {view === VIEW_HORIZONTAL ? (
          <>
            <div className="d-flex align-items-center justify-content-between mt-12px">
              {carName}
              <CarChecks
                statusReportFile={sale?.status_report_file}
                inspectionType={sale?.inspection_type}
                vin={sale?.car?.vin}
                lot={lot}
                isLotSale={true}
              />
            </div>
            <div className="car-card__header-line" />
          </>
        ) : carName}
        <Link className="car-card__info-block" to={carLink}>
          <div className="d-flex align-items-center car-card__info-block-a">
            <div className="year icon"><img src="/img/icons/date-icon.svg" alt="" /></div>
            <div className="value">{sale?.car?.year} г</div>
          </div>
          <div className="d-flex align-items-center car-card__info-block-b">
            <div className="city icon"><img src="/img/icons/map-marker-icon.svg" alt="" /></div>
            <div className="value">{sale?.city?.name}</div>
          </div>
          <div className="d-flex align-items-center car-card__info-block-c">
            <div className="mileage icon"><img src="/img/icons/speed-icon.svg" alt="" /></div>
            <div className="value">{sale?.mileage ? formatValueWithSpaces(sale?.mileage) : '-'} км</div>
          </div>
          <div className="d-flex align-items-center car-card__info-block-d">
            <div className="engine icon"><img src="/img/icons/engine-icon.svg" alt="" /></div>
            <div className="value">{sale?.car?.engine_volume} см3</div>
          </div>
          <div className="d-flex align-items-center car-card__info-block-e">
            <div className="reg_number icon"><img src="/img/icons/gearbox.svg" alt="" /></div>
            <div className="value">{sale?.car?.transmission?.name}</div>
          </div>
          <div className="d-flex align-items-center car-card__info-block-f">
            <div className="engine icon"><img src="/img/icons/power-icon.svg" alt="" /></div>
            <div className="value">{sale?.car?.power} л.с.</div>
          </div>
        </Link>
        <div className="d-flex mt-2">
          <div className="vin-title">VIN:</div>
          <div className="vin">
            <span>{sale?.car?.vin}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
