import { makeAutoObservable } from 'mobx';

import { getSingleCar } from '@api/cars';

class CarSaleStore {
  constructor() {
    this.data = {};

    makeAutoObservable(this);
  }

  fetchCar = (id) => {
    return getSingleCar(id).then(this.setData);
  };

  setData = (carSale) => {
    this.data = carSale;
  };
}

export const carSaleStore = new CarSaleStore();
