import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@components/button/button';
import { Input } from '@components/input/input';
import { Select } from '@components/select/select';
import { getSaleModelsRequest } from '@api/dictionaries';

import './filters.scss';

export const FiltersModal = ({ handleClose, clearAllFilters, handleFilterChange, filters, brands, applyFilters }) => {
  const [models, setModels] = useState([]);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="filters-modal-wrapper"
    >
      <div className="filters-modal-wrapper__header">
        <div className="d-flex align-items-center gap-10px">
          <div className="filters-modal-wrapper__header-text">Фильтры</div>
          <div
            onClick={() => {
              clearAllFilters();
              handleClose();
            }}
            className="btn-clear"
          >
            <img src="/img/icons/filters-off-icon.svg" />
            Очистить
          </div>
        </div>
        <img src="/img/icons/close-icon.svg" alt="" onClick={() => handleClose()} className="cursor-pointer" />
      </div>
      <div className="filters-wrapper">
        <div className="d-flex align-items-center flex-wrap gap-10px">
          <div className="filter">
            <div className="filter-name">VIN</div>
            <Input
              size="m"
              onChange={handleFilterChange}
              value={filters?.vin ?? ''}
              name="vin"
              placeholder="VIN"
            />
          </div>
          <div className="filter">
            <div className="filter-name">Бренд</div>
            <Select
              options={brands}
              valueId={filters?.brand_id || ''}
              size="m"
              placeholder="Бренд"
              onChange={({ value }) => {
                handleFilterChange({ target: { name: 'brand_id', value } });
                getSaleModelsRequest(value, 'car').then(setModels);
              }}
            />
          </div>
          <div className="filter">
            <div className="filter-name">Модель</div>
            <Select
              options={models?.map((model) => ({ value: model.id, label: model.name }))}
              valueId={filters?.model_id || ''}
              size="m"
              placeholder="Модель"
              onChange={({ value }) => {
                handleFilterChange({ target: { name: 'model_id', value } });
              }}
            />
          </div>
          <div className="filter">
            <div className="filter-name">Гос. номер</div>
            <Input
              placeholder="Гос. номер"
              name="reg_number"
              value={filters.reg_number}
              onChange={handleFilterChange}
              className="input_small"
              size="m"
            />
          </div>
        </div>
      </div>
      <div className="filters-modal-wrapper__footer">
        <Button
          preset="primary"
          onClick={() => {
            applyFilters();
            handleClose();
          }}
        >Применить
        </Button>
      </div>
    </Modal>
  );
};
