import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { CarsModal } from '../../../cars-modal/cars-modal';

import './lots.scss';

export const LotSalesTable = observer(({ sales, onChangeSales, withCreateSale, singleSelect, isSavedLot }) => {
  const [show, setShow] = useState(!sales.length);

  const onRemove = (saleId) => {
    onChangeSales(sales.filter((item) => item.id !== saleId));
  };

  const elTable = () => {
    return (
      <div className="lot__table">
        {sales?.map((sale) => {
          return (
            <div className="lot__table-item-row" key={`car-sale-${sale.id}`}>
              <div className="lot__table-item-tr">
                <a href={`/car/${sale.id}`} className="lot__table-item-tr-car-link" target="_blank" rel="noreferrer">{sale.car?.brand?.name}</a>
              </div>
              <div className="lot__table-item-tr">{sale.car?.vin}</div>
              <div className="lot__table-item-tr">{sale.city?.name}</div>
              {withCreateSale && (
                <img src="/img/icons/delete-icon.svg" alt="" onClick={() => onRemove(sale.id)} className="cursor-pointer" />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between w-100">
          {withCreateSale && !sales?.length && (
            <>
              <button onClick={() => setShow(true)} className="btn btn-primary" type="button">
                <img src="/img/icons/plus-icon.svg" alt="" />
                Добавить автомобиль
              </button>
              <div className="text-info">
                <img src="/img/icons/info-icon.svg" alt="" />
                Для создания лота добавьте минимум один автомобиль
              </div>
            </>
          )}
        </div>
        {withCreateSale && show && (
          <CarsModal
            show={true}
            onHide={() => setShow(false)}
            onChangeSales={onChangeSales}
            sales={sales}
            singleSelect={singleSelect}
          />
        )}
      </div>
      {sales?.length > 0 && elTable()}
      {sales?.length > 0 && !isSavedLot && (
        <button onClick={() => setShow(true)} className="btn-add-car mt-12px" type="button">
          <img src="/img/icons/icon-left-blue.svg" alt="" />
          Добавить автомобиль
        </button>
      )}
    </>
  );
});
