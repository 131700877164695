import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { meStore } from '@mobx/me';
import { Dropdown } from '@components/dropdown/dropdown';
import { postImpersonationCompany, postStopImpersonation } from '@api/auth';

import './header.scss';

export const Header = observer(({ toggleSideBar, hideBurger }) => {
  const user = meStore.data;
  const impersonateAs = meStore.data?.impersonate_as;
  const navigate = useNavigate();

  const onChangeImpersonation = (company_id) => {
    postImpersonationCompany({ company_id })
      .then((resp) => {
        if (!resp.message) {
          meStore.setData(resp);
        }
      });
  };

  const options = useMemo(() => {
    const list = [{
      value: `${meStore.data?.user?.id}u`,
      label: 'Физлицо',
      onClick: () => {
        postStopImpersonation()
          .then((resp) => {
            if (!resp.message) {
              meStore.setData(resp);
            }
          });
      },
    }];

    meStore.data?.available_impersonation?.forEach((item) => {
      list.push({
        value: `${item.id}c`,
        label: item.title,
        onClick: () => onChangeImpersonation(item.id),
      });
    });

    list.push({
      value: '',
      label: (
        <div className="dropdown-item-logout">
          <img src="/img/icons/logout-icon.svg" alt="" />
          Выйти из приложения
        </div>
      ),
      onClick: () => {
        navigate('/user/out');
      },
    });

    return list;
  }, [meStore.data?.available_impersonation]);

  return (
    <div className="header-menu d-flex align-items-center justify-content-between">
      {hideBurger ? <span /> : <img onClick={toggleSideBar} id="burger-img" className="cursor-pointer burger-img" src="/img/header/burger.svg" alt="" />}
      <div className="d-flex user align-items-center">
        <img src="/img/header/user.svg" alt="" />
        <Dropdown
          customToggle={(
            <div className="user-info cursor-pointer">
              <div className="d-flex">
                <div className="name">
                  <span>{user?.user?.full_name}</span>
                </div>
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div className="info">
                {impersonateAs?.entity?.id === meStore.data?.user?.id && impersonateAs?.type === 'user'
                  ? 'Физлицо'
                  : impersonateAs?.entity?.title}
              </div>
            </div>
            )}
          options={options}
          value={`${impersonateAs?.entity?.id}${impersonateAs?.type === 'user' ? 'u' : 'c'}`}
        />
      </div>
    </div>
  );
});
