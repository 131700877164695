import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { RadioButton } from '@components/radio-button/radio-button';

export const RadioGroupField = (props) => {
  const context = useFormContext();

  return (
    <Controller
      control={context.control}
      name={props.name}
      render={({ field: { onChange } }) => (
        <div className="radio-field__container">
          {props.options.map((item, index) => {
            return (
              <RadioButton
                key={`${item.label}-${index}`}
                name={`${props.name}${index}`}
                label={item.label}
                checked={props.value ? props.value === item.value : false}
                value={item.value}
                onChange={(e) => {
                  props.onChange?.(e);
                  onChange(e.target.value);
                }}
                disabled={props.disabled}
              />
            );
          })}
        </div>
      )}
      rules={{ required: props.required }}
    />
  );
};
