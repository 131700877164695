import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './table.scss';

export const Table = (props) => {
  const table = (
    <BootstrapTable
      bootstrap4
      keyField={props.keyField || 'id'}
      data={props.data}
      columns={props.columns}
      selectRow={props.selectRow}
      classes={`table-class ${props.classes}`}
      bordered={false}
      headerClasses={props.headerClasses}
      rowEvents={props.rowEvents}
    />
  );
  return <div className="table-class-wrap border">{table}</div>;
};
