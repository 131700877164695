import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Login } from '@pages/auth/login';
import { Out } from '@pages/auth/out';
import { Signup } from '@pages/auth/signup';
import { Recovery } from '@pages/auth/recovery';
import { ResetConfirm } from '@pages/auth/reset-confirm';
import { LayoutAuth, LayoutMain, LayoutPageNotFound } from '@components/layout/layout';
import { NotFoundPage } from '@pages/not-found-page';
import { AuctionForm } from '@pages/auction-form/auction-form';
import { UserGroupForm } from '@pages/user-group-form/user-group-form';
import { Auctions } from '@pages/auctions/auctions';
import { AuctionsCalendar } from '@pages/auctions-calendar/auctions-calendar';
import { Cars } from '@pages/cars/cars';
import { Companies } from '@pages/companies/companies';
import { CarForm } from '@pages/car-form/car-form';
import { CompanyForm } from '@pages/company-form/company-form';
import { DepartmentForm } from '@pages/department-form/department-form';
import { UserForm } from '@pages/user-form/user-form';
import { Company } from '@pages/company/company';
import { UserGroup } from '@pages/user-group/user-group';
import { CurrentTrades } from '@pages/current-trades/current-trades';
import { FavouriteLots } from '@pages/favourite-lots/favourite-lots';
import { Report } from '@pages/report/report';
import { Auction } from '@pages/auction/auction';
import { Car } from '@pages/car/car';
import { User } from '@pages/user/user';
import { Buy } from '@pages/buy/buy';
import { Sell } from '@pages/sell/sell';
import { UserProfile } from '@pages/user-profile/user-profile';
import { Users } from '@pages/users/users';
import ProtectedRoute from '@components/protected-route/protected-route';
import { Department } from '@pages/department/department';
import { Exchange } from '@pages/exchange/exchange';
import { ExchangeOffer } from '@pages/exchange-offer/exchange-offer';
import { AddLot } from '@pages/exchange/add-lot';
import { ChangePassword } from '@pages/change-password/change-password';
import { TemporaryRegister } from '@pages/temporary-register/temporary-register';
import { CodeAuth } from '@pages/code-auth/code-auth';
import { Root } from '@pages/root/root';
import { ReportMake } from '@pages/report-make/report-make';
import { isKZ } from '@utils/common';

import './main.scss';

function App() {
  useEffect(() => {
    document.title = isKZ ? 'AuctionCar' : 'Автомарт';

    const link = document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = isKZ ? '/favicon_kz.ico' : '/favicon.ico';
    document.head.appendChild(link);
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Root />}
      />
      <Route
        path="/auth/login"
        element={(
          <Login />
        )}
      />
      <Route
        path="/auth/login/:code?"
        element={(
          <Login />
        )}
      />
      <Route
        path="/auth/signup"
        element={(
          <LayoutAuth>
            <Signup />
          </LayoutAuth>
        )}
      />
      <Route
        path="/auth/simple-register/:code"
        element={(
          <LayoutAuth>
            <TemporaryRegister />
          </LayoutAuth>
        )}
      />
      <Route
        path="/auth/code/:code"
        element={(
          <CodeAuth />
        )}
      />
      <Route
        path="/auth/recovery"
        element={(
          <LayoutAuth>
            <Recovery />
          </LayoutAuth>
        )}
      />
      <Route
        path="/user/reset"
        element={(
          <LayoutAuth>
            <ResetConfirm />
          </LayoutAuth>
        )}
      />
      <Route
        path="/users"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Users} />
          </LayoutMain>
        )}
      />
      <Route
        path="/user/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={User} />
          </LayoutMain>
        )}
      />
      <Route
        path="/users/create"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={UserForm} />
          </LayoutMain>
        )}
      />
      <Route
        path="/users/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={UserForm} props={{ isEdit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/profile/change-password"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={ChangePassword} props={{ isProfile: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/users/:id/change-password"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={ChangePassword} />
          </LayoutMain>
        )}
      />
      <Route
        path="/current-trades"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={CurrentTrades} />
          </LayoutMain>
        )}
      />
      <Route
        path="/exchange"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Exchange} />
          </LayoutMain>
        )}
      />
      <Route
        path="/exchange-offer/add"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={AddLot} />
          </LayoutMain>
        )}
      />
      <Route
        path="/exchange-offer/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={AddLot} props={{ isEdit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/exchange-offer/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={ExchangeOffer} />
          </LayoutMain>
        )}
      />
      <Route
        path="/car/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Car} />
          </LayoutMain>
        )}
      />
      <Route
        path="/car/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={CarForm} props={{ edit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/car/create"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={CarForm} />
          </LayoutMain>
            )}
      />
      <Route
        path="/auction/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Auction} />
          </LayoutMain>
        )}
      />
      <Route
        path="/auction/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={AuctionForm} props={{ edit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/add-auction"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={AuctionForm} />
          </LayoutMain>
        )}
      />
      <Route
        path="/auctions"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Auctions} />
          </LayoutMain>
        )}
      />
      <Route
        path="/auction-calendar"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={AuctionsCalendar} />
          </LayoutMain>
        )}
      />
      <Route
        path="/companies"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Companies} />
          </LayoutMain>
        )}
      />
      <Route
        path="/create-company"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={CompanyForm} />
          </LayoutMain>
        )}
      />
      <Route
        path="/company/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Company} />
          </LayoutMain>
        )}
      />
      <Route
        path="/company/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={CompanyForm} props={{ isEdit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/user-group/create"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={UserGroupForm} />
          </LayoutMain>
        )}
      />
      <Route
        path="/user-group/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={UserGroupForm} props={{ isEdit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/user-group/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={UserGroup} />
          </LayoutMain>
        )}
      />
      <Route
        path="/company/:id/add-department"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={DepartmentForm} />
          </LayoutMain>
        )}
      />
      <Route
        path="/profile"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={UserProfile} />
          </LayoutMain>
        )}
      />
      <Route
        path="/buy"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Buy} />
          </LayoutMain>
        )}
      />
      <Route
        path="/sell"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Sell} />
          </LayoutMain>
        )}
      />
      <Route
        path="/sell/auction/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Auction} props={{ isSell: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/car/:saleId/report/:reportId"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Report} />
          </LayoutMain>
        )}
      />
      <Route
        path="/favourite-lots"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={FavouriteLots} />
          </LayoutMain>
        )}
      />
      <Route
        path="/cars"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Cars} />
          </LayoutMain>
        )}
      />
      <Route
        path="/department/:id"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={Department} />
          </LayoutMain>
        )}
      />
      <Route
        path="/department/:id/edit"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={DepartmentForm} props={{ isEdit: true }} />
          </LayoutMain>
        )}
      />
      <Route
        path="/reporting"
        element={(
          <LayoutMain>
            <ProtectedRoute Component={ReportMake} />
          </LayoutMain>
        )}
      />
      <Route
        path="auth/registration-confirm"
        element={<Navigate to="/profile" replace />}
      />
      <Route path="/user/out" element={<Out />} />
      <Route
        path="*"
        element={(
          <LayoutPageNotFound>
            <NotFoundPage />
          </LayoutPageNotFound>
        )}
      />
    </Routes>
  );
}

export default App;
