import React, { useState } from 'react';

import { removeLotRequest } from '@api/auctions';
import { getCurrencySymbol } from '@utils/common';
import { formatDateWithTime } from '@utils/date';
import { formatValueWithSpaces } from '@utils/format';

import { LotSalesTable } from './lot-sales-table';

import './lots.scss';

export const Lot = ({ typeAuction, lot, ...props }) => {
  const isUpAuction = typeAuction === 'parallel_increase';
  const isDownAuction = typeAuction === 'sequential_decrease';
  const isAuctionBuyNow = typeAuction === 'buy_now';
  const [lotError, setLotError] = useState('');
  const canEdit = props.auctionStatus === 'auction_new';

  const onRemove = () => {
    removeLotRequest(props.auctionId, lot.id).then((resp) => {
      if (resp.message) {
        setLotError(resp.errors || resp.message);

        setTimeout(() => setLotError(''), 5000);
      } else {
        props.onRemove(lot.id);
      }
    });
  };

  return (
    <div className="lot-wrapper-card mt-16px">
      <div className="info-wrapper__header">
        <div className="info-wrapper__header-edit">
          Лот #{lot.id}
          {canEdit && (
            <button
              onClick={() => props.onEdit(lot.id)}
              className="info-wrapper__header-edit-btn"
              type="button"
            >
              <img src="/img/icons/edit-icon.svg" alt="" />
              Редактировать
            </button>
          )}
        </div>
        {canEdit && <img src="/img/icons/delete-icon.svg" alt="" onClick={onRemove} className="cursor-pointer" />}
      </div>
      <div className="lot-wrapper-card__body">
        <LotSalesTable sales={lot.sales} isSavedLot={true} />
        <div className="d-flex gap-5px justify-content-around mt-20px">
          {isDownAuction && (
            <div className="">
              <div className="head-name">№</div>
              <div className="row-text">{lot.order}</div>
            </div>
          )}
          <div>
            <div className="head-name">Старт. стоимость</div>
            <div className="row-text">{formatValueWithSpaces(lot.starting_price)} {getCurrencySymbol()}</div>
          </div>
          {isDownAuction ? (
            <div className="text-align-center">
              <div className="head-name">Минимальная ставка</div>
              <div className="row-text">{formatValueWithSpaces(lot.minimal_price)} {getCurrencySymbol()}</div>
            </div>
          ) : (
            ''
          )}
          {isUpAuction && (
            <div className="text-align-center">
              <div className="head-name">Шаг торгов/понижения</div>
              <div className="row-text">{formatValueWithSpaces(lot.minimal_step)} {getCurrencySymbol()}</div>
            </div>
          )}
          {(isUpAuction || isAuctionBuyNow) && (
            <div className="text-align-center">
              <div className="head-name">Дата завершения</div>
              <div className="row-text">{formatDateWithTime(lot.expires_at)}</div>
            </div>
          )}
          <div className="text-align-center">
            <div className="head-name">НДС</div>
            <div className="row-text">{lot.is_vat_included ? 'Включён' : 'Не включён'}</div>
          </div>
          <div className="text-align-center">
            <div className="head-name">Комиссия</div>
            <div className="row-text">{lot.commission}%, мин. {formatValueWithSpaces(lot.minimal_commission)} {getCurrencySymbol()}</div>
          </div>
          <div className="text-align-center">
            <div className="head-name">Штраф</div>
            <div className="row-text">{formatValueWithSpaces(lot.surcharge)} {getCurrencySymbol()}</div>
          </div>
        </div>
        {Boolean(lotError) && (
          <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(lotError, null, ' ')}</div>
        )}
      </div>
    </div>
  );
};
