import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioButton } from '@components/radio-button/radio-button';

import { FormSelect } from './form-select';
import { Input } from './input';

import './form-select.scss';

export const FormField = ({ as, disabled, changeTransform, isClearable, ...props }) => {
  const context = useFormContext();

  const elField = (onChange) => {
    const newProps = {
      // todo errors и success
      // error: touched && error,
      // success: touched && !error && input.value,
      ...props,
    };

    if (as === 'select') {
      return (
        <FormSelect
          {...newProps}
          isClearable={isClearable}
          isDisabled={disabled}
          onChange={(value) => {
            props.onChange?.(value);
            onChange(value);
          }}
        />
      );
    }

    if (as === 'radio') {
      return (
        <RadioButton {...newProps} onChange={onChange} disabled={disabled} />
      );
    }

    return (
      <Input
        {...newProps}
        type={as === 'number' ? 'number' : 'text'}
        onChange={(e) => {
          const newValue = changeTransform ? changeTransform(e) : e;
          onChange(newValue);
        }}
        disabled={disabled}
        autoComplete="off"
      />
    );
  };

  return (
    <Controller
      control={context.control}
      name={props.name}
      render={({ field: { onChange } }) => elField(onChange)}
      rules={{ required: props.required }}
    />
  );
};
