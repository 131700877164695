import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Input } from '@components/input/input';
import { Pagination } from '@components/pagination/pagination';
import { addUsersToGroupRequest, deleteUsersFromGroupRequest } from '@api/user-groups';
import { filteredUsers, getUsersList } from '@api/users';
import { Table } from '@components/table/table';
import { Button } from '@components/button/button';

import { COLUMNS } from './utils';

import './users-group-modal.scss';

const PER_PAGE = [25, 50, 100, 250, 500];

export const UsersGroupModal = ({ groupId, groupUsers, show, requestUsers, onHide }) => {
  const [selectedRows, setSelectedRows] = useState(groupUsers?.data ? groupUsers.data.map((item) => item.id) : []);
  const [users, setUsers] = useState();
  const [form, setForm] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
  });

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
    selected: selectedRows,
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows(rows.map((item) => item.id));
      } else {
        setSelectedRows([]);
      }
    },
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        setSelectedRows((prev) => [...prev, row.id]);
      } else {
        setSelectedRows((prev) => prev.filter((item) => item !== row.id));
      }
    },
  };

  const requestAllUsers = (params) => {
    getUsersList(params).then((resp) => {
      if (!resp.message) {
        setUsers(resp);
      }
    });
  };

  useEffect(() => {
    requestAllUsers({ page: 1, per_page: PER_PAGE[0] });
  }, []);

  const addUsers = async () => {
    const usersToRemove = [];

    groupUsers.data.forEach((item) => {
      if (!selectedRows.includes(item.id)) {
        usersToRemove.push(item.id);
      }
    });

    if (usersToRemove.length) {
      await deleteUsersFromGroupRequest(groupId, { user_ids: usersToRemove });
    }

    await addUsersToGroupRequest(groupId, { user_ids: selectedRows });
    requestUsers();
    onHide();
  };

  const getFilteredUsersList = () => {
    filteredUsers(form).then((resp) => {
      if (!resp.message) {
        setUsers(resp);
      }
    });
  };

  const clearAllFilters = () => {
    setForm({
      first_name: '',
      middle_name: '',
      last_name: '',
    });
    requestAllUsers({ page: 1, per_page: PER_PAGE[0] });
  };

  const handleInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="access-users-modal-wrapper">
      <Modal show={show} onHide={onHide} className="users-group-modal-wrapper">
        <div className="filters-wrapper">
          <div>
            <div className="d-flex align-items-center flex-wrap gap-5px">
              <Input
                placeholder="Фамилия"
                name="last_name"
                value={form.last_name}
                onChange={handleInputChange}
                className="input_small"
                size="xs"
              />
              <Input
                placeholder="Имя"
                name="first_name"
                value={form.first_name}
                onChange={handleInputChange}
                className="input_small"
                size="xs"
              />
              <Input
                placeholder="Отчество"
                name="middle_name"
                value={form.middle_name}
                onChange={handleInputChange}
                className="input_small"
                size="xs"
              />
            </div>
            <div className="mt-20px d-flex gap-10px">
              <Button onClick={getFilteredUsersList} preset="primary" size="s">
                Применить фильтры
              </Button>
              <Button onClick={clearAllFilters} className="d-flex gap-5px align-items-center" preset="ghost" size="s">
                <img src="/img/icons/filters-off-icon.svg" />
                Очистить
              </Button>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end header mb-3">
          <button onClick={addUsers} className="btn btn-primary" type="button">
            Сохранить
          </button>
        </div>

        {users?.data ? (
          <Table
            data={users.data}
            columns={COLUMNS}
            selectRow={selectRow}
          />
        ) : (
          <p>No data</p>
        )}
        <Pagination
          data={users}
          onChange={requestAllUsers}
          perPageList={PER_PAGE}
        />
      </Modal>
    </div>
  );
};
