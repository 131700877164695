import React, { useEffect, useState } from 'react';

import { setAccessUsersRequest, deleteUserRequest } from '@api/users';
import { getUserGroupsRequest } from '@api/user-groups';
import { Input } from '@components/input/input';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import { Button } from '@components/button/button';

import { GROUP_COLUMNS } from './utils';

import './access-user-modal.scss';

export const AccessUserGroups = ({ auctionId, show, onHide, onChange, selected, isAccess }) => {
  const [userGroups, setUserGroups] = useState();
  const [error, setError] = useState();
  const [name, setName] = useState('');
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedRows, setSelectedRows] = useState(selected);
  const [groupsToRemove, setGroupsToRemove] = useState([]);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
    selected: selectedRows,
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedUserGroups(() => userGroups.data);
        setSelectedRows(() => userGroups.data.map((item) => item.id));
      } else {
        setGroupsToRemove(() => [...selectedRows]);
        setSelectedUserGroups([]);
        setSelectedRows([]);
      }
    },
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        setSelectedUserGroups((prev) => [...prev, row]);
        setSelectedRows((prev) => [...prev, row.id]);
      } else {
        setSelectedUserGroups((prev) => prev.filter((item) => item.id !== row.id));
        setSelectedRows((prev) => prev.filter((item) => item !== row.id));
        setGroupsToRemove((prev) => [...prev, row.id]);
      }
    },
  };

  useEffect(() => {
    getUserGroupsRequest().then(setUserGroups);
  }, []);

  const saveGroups = () => {
    if (selectedUserGroups.length) {
      const accesses = selectedUserGroups.map((item) => {
        return ({
          owner_type: 'group',
          owner_id: item.id,
          has_permission: isAccess,
        });
      });

      setAccessUsersRequest({ accesses }, auctionId).then((resp) => {
        if (resp.message) {
          setError(resp.errors || resp.message);

          setTimeout(() => setError(null), 5000);
        } else {
          onChange('Группы');
        }
      });
    }

    const dataForRemove = [];

    groupsToRemove.forEach((groupId) => {
      if (selected.includes(groupId)) {
        dataForRemove.push({ owner_type: 'group', owner_id: groupId });
      }
    });

    if (dataForRemove.length) {
      deleteUserRequest({ accesses: dataForRemove }, auctionId).then((resp) => {
        if (resp.message) {
          setError(resp.errors || resp.message);

          setTimeout(() => setError(null), 5000);
        } else {
          onChange('Группы');
        }
      });
    }

    setGroupsToRemove([]);
    onHide();
  };

  const getFilteredUsersList = () => {
    getUserGroupsRequest({ 'filters[name]': name }).then(setUserGroups);
  };

  const clearAllFilters = () => {
    setName('');
    getUserGroupsRequest().then(setUserGroups);
  };

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      <div className="user-access__filters user-group-access__filters">
        <div>
          <div className="d-flex align-items-center flex-wrap gap-15px">
            <Input
              placeholder="Название"
              name="name"
              value={name}
              onChange={handleInputChange}
              size="xs"
            />
          </div>
          <div className="d-flex mt-20px gap-15px">
            <Button onClick={getFilteredUsersList} size="s">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} className="d-flex gap-5px align-items-center" preset="secondary" size="s">
              <img src="/img/icons/filters-off-icon.svg" />
              Очистить
            </Button>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end header mb-3">
        {error && (
          <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(error, null, ' ')}</div>
        )}
        <button onClick={saveGroups} className="btn btn-primary" type="button">
          Сохранить
        </button>
      </div>

      {userGroups?.data?.length > 0 ? (
        <>
          <Table
            data={userGroups.data}
            columns={GROUP_COLUMNS}
            selectRow={selectRow}
          />
          <Pagination
            data={userGroups}
            onChange={(params) => {
              const newParams = { ...params };

              if (name) {
                newParams['filters[name]'] = name;
              }

              getUserGroupsRequest(newParams).then(setUserGroups);
            }}
            perPageList={[25, 50, 100, 250, 500]}
          />
        </>
      ) : (
        <p>Нет данных</p>
      )}
    </>
  );
};
