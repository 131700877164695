import React, { useEffect, useMemo, useState } from 'react';
import { Panel } from 'rsuite';
import { observer } from 'mobx-react-lite';

import { Loader } from '@components/loader/loader';
import { getCarSaleDocumentsRequest, postAddCarSaleDocuments, removeCarSaleDocument } from '@api/cars';
import { dictionariesStore } from '@mobx/dictionaries';
import { COUNTRY } from '@api/config';

import { DocumentField } from './components/document-field';
import { DocumentFieldWithSelect } from './components/document-field-with-select';

import './car-form-components.scss';

const staticDocumentNames = COUNTRY === 'kz' ? [
  'Свидетельство о регистрации',
] : [
  'ПТС Лицевая сторона',
  'ПТС Оборотная сторона',
  'Фотография кузовной таблички с VIN',
];

export const Documents = observer(({ onStepChange, edit, carData, showConfirmPopup, onConfirmChangeStep }) => {
  const id = carData?.id;
  const documentTypesList = dictionariesStore.dictionaries.sale_document_types;

  const [loading, setLoading] = useState(edit);
  const [documents, setDocuments] = useState();
  const [defaultValues, setDefaultValues] = useState({});
  const [error, setError] = useState();

  const requestDocuments = () => {
    getCarSaleDocumentsRequest(id).then(setDocuments);
  };

  useEffect(() => {
    requestDocuments();

    if (!documentTypesList) {
      dictionariesStore.request('sale_document_types', { filters: { car_type_id: '1' } });
    }
  }, []);

  useEffect(() => {
    if (showConfirmPopup) {
      onConfirmChangeStep();
    }
  }, [showConfirmPopup]);

  const { staticDocuments, defaultDocuments } = useMemo(
    () => (documentTypesList || []).reduce(
      (acc, doc) => {
        const { document_type: { name, id } } = doc;

        const isStaticDocument = staticDocumentNames.find((staticDoc) => staticDoc === name);
        if (isStaticDocument) {
          acc.staticDocuments.push({ ...doc, name: id, id, label: name, type: 'file' });
        } else acc.defaultDocuments.push({ ...doc, name: id, label: name });

        return acc;
      },
      { staticDocuments: [], defaultDocuments: [] },
    ),
    [documentTypesList],
  );

  useEffect(() => {
    if (documents) {
      const newDefaultValues = {};

      documents.forEach((document) => {
        let value;

        if (document.document_files.length) {
          value = document.document_files.map((item) => ({ ...item, size: item.file.size, name: item.file.original_name }));
        }

        const prevValue = newDefaultValues[document.document_type_id];

        newDefaultValues[document.document_type_id] = prevValue ? [...prevValue, ...value] : value;
      });

      setDefaultValues(newDefaultValues);

      setLoading(false);
    }
  }, [documents, edit]);

  const onDeleteDocument = (docId) => {
    removeCarSaleDocument(id, [docId]).then(requestDocuments);
  };

  const onSaveRequest = async (file, document_type_id) => {
    try {
      const formData = new FormData();

      formData.append('documents[0][document_type_id]', document_type_id);
      formData.append('documents[0][files][0]', file);

      const response = await postAddCarSaleDocuments(id, formData);

      if (!response?.errors) {
        requestDocuments();
      } else {
        setError(response?.errors);

        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="title mb-4">
        <span>Документы</span>
      </div>
      {loading
        ? <Loader />
        : (
          <>
            <Panel className="all-info-wrapper">
              {staticDocuments.map((doc) => {
                let defaultValue;

                if (defaultValues[doc.document_type_id]?.[0]) {
                  defaultValue = { ...defaultValues[doc.document_type_id][0].file, id: defaultValues[doc.document_type_id][0].document_id };
                }

                return (
                  <DocumentField
                    name={doc.name}
                    label={doc.label}
                    key={doc.document_type_id}
                    defaultValue={defaultValue}
                    onDelete={onDeleteDocument}
                    onSave={onSaveRequest}
                  />
                );
              })}
              <DocumentFieldWithSelect
                onSaveRequest={onSaveRequest}
                options={defaultDocuments}
                defaultValues={defaultValues}
                onDelete={onDeleteDocument}
              />
            </Panel>
            {error && (
              <div className="mt-3 mb-3 alert alert-danger break-word">{JSON.stringify(error, null, ' ')}</div>
            )}
            <div className="d-flex align-items-center gap-3 mt-3 mob-button-wrapper">
              <button className="btn btn-primary flex-grow-1" type="button" onClick={onStepChange}>
                Следующий шаг
              </button>
              <span className="flex-grow-1" />
            </div>
          </>
        )}
    </div>
  );
});
