import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';

import { formatDateWithTime } from '@utils/date';

import { generateTimerFromDate } from '../auction/utils';

export const getColumns = (onSort) => [
  {
    dataField: 'id',
    text: 'Номер',
    sort: true,
    onSort,
    formatter: (cell, row) => <Link to={`/auction/${row.id}`}>{row.id}</Link>,
  },
  {
    dataField: 'seller',
    text: 'Продавец',
    formatter: (cell, row) => {
      return (
        <Link to={`/${row.sellerable?.parent_id ? 'department' : 'company'}/${row.sellerable?.id}`}>
          {row.sellerable?.display_name}
        </Link>
      );
    },
  },
  {
    dataField: 'start_at',
    text: 'Начало',
    sort: true,
    onSort,
    formatter: (cell, row) => (row.start_at && row.start_at ? formatDateWithTime(row.start_at) : '-'),
  },
  {
    dataField: 'date_start',
    text: 'Время до начала',
    formatter: (cell, row) => {
      return generateTimerFromDate(row.start_at ? new Date(row.start_at) : '-');
    },
  },
  {
    dataField: 'finished_at',
    text: 'Завершение (факт)',
    sort: true,
    onSort,
    formatter: (cell, row) => (row.finished_at ? formatDateWithTime(row.finished_at) : '-'),
  },
  {
    dataField: 'planned_finish_at',
    text: 'Завершение (план)',
    formatter: (cell, row) => (row.planned_finish_at && row.planned_finish_at ? moment(row.planned_finish_at).format('DD.MM.YYYY HH:mm') : '-'),
  },
  {
    dataField: 'date_end',
    text: 'До завершения',
    formatter: (cell, row) => {
      const lastLotEnd = row.lots?.length > 0 && moment.max(row.lots.filter((item) => Boolean(item.expires_at)).map((item) => moment(item.expires_at)));

      return (row.is_active && lastLotEnd) ? generateTimerFromDate(lastLotEnd.toDate()) : '-';
    },
  },
  {
    dataField: 'status',
    text: 'Статус',
    formatter: (cell, row) => (row.status ? row.status.name : ''),
  },
  {
    dataField: 'manager_id',
    text: 'Менеджер',
    formatter: (cell, row) => (row.responsible?.full_name || '-'),
  },
  {
    dataField: 'lots',
    text: 'Лотов',
    formatter: (cell, row) => (row.lots?.length ?? '-'),
  },
];
