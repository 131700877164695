import React, { useEffect, useState } from 'react';

import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { getReports, postMakeReport } from '@api/reporting';
import { meStore } from '@mobx/me';
import { Loader } from '@components/loader/loader';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';

import { CARS_COLUMNS } from './utils';

const DEFAULT_VALUES = {
  vin: '',
  seller_id: '',
};

const PER_PAGE = [25, 50, 100, 250, 500];

export const ReportMakeCars = ({ sales }) => {
  const [reports, setReports] = useState();
  const [form, setForm] = useState(DEFAULT_VALUES);
  const [isShowForm, setIsShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultSellerName, setDefaultSellerName] = useState('');

  const getReportsRequest = (params) => {
    getReports(sales?.key, params).then((resp) => {
      setReports(resp);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (sales) {
      getReportsRequest({ page: 1, per_page: PER_PAGE[0] });
    }
  }, [sales]);

  useEffect(() => {
    if (meStore.data?.impersonate_as?.entity?.roles.find((item) => item.name === 'trusted_seller')) {
      setDefaultSellerName(meStore.data.impersonate_as.entity.display_name);
      setForm({ ...form, seller_id: meStore.data.impersonate_as.entity.id });
    }
  }, [meStore.data]);

  if (!meStore.data || loading) {
    return <Loader />;
  }

  const onChangeVin = (e) => {
    setForm({ ...form, vin: e.target.value });
  };

  const onSubmit = () => {
    const data = {
      'category': sales?.key,
      'type': 'CarSaleHistory',
      'params': { ...form, vin: form.vin.replaceAll('\n', ',').split(',') },
    };

    postMakeReport(data).then((resp) => {
      if (!resp.message) {
        getReportsRequest();
        setForm(DEFAULT_VALUES);
        setIsShowForm(false);
      }
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div className="head-text">{sales?.title}</div>
          <div className="text-extra-info">Хранятся 30 календарных дней после создания</div>
        </div>
        {(reports?.data?.length > 0 && !isShowForm) && (
          <button type="button" onClick={() => setIsShowForm(true)} className="btn btn-primary">
            Создать новый отчет
          </button>
        )}
      </div>
      {(isShowForm || !reports?.data?.length) && (
        <div className="reporting-form-wrapper">
          <div className="d-flex align-items-start justify-content-between mt-20px">
            <div className="w-30">
              <div className="name">VIN-номера</div>
              <div className="reporting-wrapper__message-text">Скопируйте из файла или укажите через запятую</div>
            </div>
            <div className="d-flex align-items-center position-relative w-70">
              <textarea
                name="vin"
                value={form.vin}
                onChange={(e) => onChangeVin(e)}
                className="reporting-form-wrapper__textarea"
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-20px">
            <div className="name w-30">Продавец</div>
            <div className="d-flex align-items-center position-relative w-70">
              <InputRequisitesSearch
                name="seller_id"
                defaultValue={defaultSellerName}
                className="input-field"
                placeholder="Начните вводить название"
                onChange={(seller_id) => {
                  setForm({ ...form, seller_id });
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-15px mt-20px">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => onSubmit()}
              disabled={form.vin === '' || form.seller_id === ''}
            >
              Создать новый отчёт
            </button>
            {reports?.data?.length > 0 && (
              <button
                type="button"
                onClick={() => {
                  setForm(DEFAULT_VALUES);
                  setIsShowForm(false);
                }}
                className="btn-secondary"
              >
                Отмена
              </button>
            )}
          </div>
        </div>
      )}
      {reports?.data?.length > 0 ? (
        <div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
          <Table
            data={reports?.data}
            columns={CARS_COLUMNS}
          />
        </div>
      ) : (
        <div className="reporting-wrapper__message">
          <div className="reporting-wrapper__message-title">Отчётов по автомобилям пока нет</div>
          <div className="reporting-wrapper__message-text">Укажите VIN-номера и продавца для создания нового отчёта</div>
        </div>
      )}
      {reports?.data?.length > 0 && (
        <Pagination
          data={reports}
          onChange={getReportsRequest}
          perPageList={PER_PAGE}
        />
      )}
    </div>
  );
};
