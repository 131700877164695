import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Tooltip } from '@components/tooltip/tooltip';
import { AuctionIndicatorWithTimer } from '@components/auction-indicator/auction-indicator';
import { auctionsStore } from '@mobx/auctions';

import { LotCard } from './lot-card';

import './auction-item.scss';

export const AuctionItem = observer(({ auction, view, hasViewPermission, fixedHeader, isMobile }) => {
  const lotStarted = auctionsStore.lotStarted[auction.id];
  const lotFinished = auctionsStore.lotFinished[auction.id];
  const [lotTimer, setLotTimer] = useState(0);

  const isDownAuction = auction?.auction_type === 'sequential_decrease';
  const lastLotEnd = auction.lots?.length > 0 && moment.max(auction.lots.filter((item) => Boolean(item.expires_at)).map((item) => moment(item.expires_at)));
  const expiresAt = lastLotEnd && lastLotEnd.toDate();

  useEffect(() => {
    if (lotStarted) {
      if (auction.status.code !== 'auction_active') {
        auctionsStore.refetchAuction(auction.id);
      }
    }
  }, [lotStarted]);

  useEffect(() => {
    if (lotFinished) {
      if (isDownAuction) {
        const findLotIndex = auction.lots.findIndex((item) => item.id === lotFinished);

        setLotTimer({ index: findLotIndex + 1, timer: Date.now() + 15000 });

        setTimeout(() => {
          setLotTimer(null);
        }, 15000);
      }
    }
  }, [lotFinished]);

  return (
    <div id={auction.id} className={clsx('card-auction mt-3', { 'card-auction_fixed': fixedHeader })}>
      <div className="card-auction__header-wrap">
        <div className="card-auction__header d-flex align-items-center justify-content-between">
          <div className="card-auction__information card-auction__information-top-bar left-side">
            <div className="auction-id">
              № {hasViewPermission ? <Link to={`/auction/${auction.id}`}>{auction.id}</Link> : auction.id}
            </div>
            {!auction.is_seller_hidden ? (
              <div className="seller">
                <Tooltip text="Продавец" isDisabled={isMobile}>
                  <b>{auction?.sellerable?.display_name || auction?.sellerable?.full_name}</b>
                </Tooltip>
              </div>
            ) : (
              <div className="seller">
                <span>
                  <b>Корпоративный парк</b>
                </span>
              </div>
            )}
            <Tooltip text="Ответственный менеджер" isDisabled={isMobile}>
              <div className="manager">
                <img src="/img/user-icon.svg" alt="" />
                <span>
                  {auction.responsible?.full_name || 'Менеджер не указан'}
                </span>
              </div>
            </Tooltip>
            <div className="d-flex date-from-to">
              <img src="/img/icons/date.svg" alt="" />
              <div className="date">
                <span>
                  {auction.start_at ? moment(auction.start_at).format('DD.MM.YY HH:mm') : '-'}
                  {!isDownAuction && auction.lots.length && expiresAt ? (
                    <span>
                      <span> - </span>{' '}
                      {auction.lots.length ? moment(expiresAt).format('DD.MM.YY HH:mm') : '-'}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
            <Tooltip text="Количество лотов в аукционе" isDisabled={isMobile}>
              <div className="user lots-number">
                <img src="/img/icons/hammer-icon.svg" alt="" />
                <div className="name">{auction.lots.length || 0}</div>
              </div>
            </Tooltip>
            {auction?.auction_type === 'buy_now' ? (
              <div className="type-auctions">
                <Tooltip text="Купить сейчас. Победителем становится участник, первым купивший лот" isDisabled={isMobile}>
                  <img
                    src="/img/icons/rocket-icon.svg"
                    alt=""
                  />
                </Tooltip>
              </div>
            ) : (
              <div className="type-auctions">
                <Tooltip
                  isDisabled={isMobile}
                  text={isDownAuction ? 'Аукцион на понижение. Стоимость автомобиля понижается с заданным интервалом времени. Победителем становится участник, который первый подтвердит текущую стоимость лота' : 'Аукцион на повышение. Победителем становится участник с наивысшей ставкой по окончании аукциона'}
                >
                  <img
                    className={isDownAuction ? 'type-of-auction auction-type-icon_down' : 'type-of-auction'}
                    src="/img/icons/type-of-auction-icon.svg"
                    alt=""
                  />
                </Tooltip>
                <Tooltip
                  isDisabled={isMobile}
                  text={isDownAuction ? 'Тип завершения: последовательный. Потоковое проведение торгов на лоты, собранные в данном Аукционе' : 'Тип завершения: параллельный. Торги заканчиваются когда отсутствуют ставки на все лоты, собранные в данном Аукционе'}
                >
                  <img
                    src={isDownAuction ? '/img/icons/auction-end-type.svg' : '/img/icons/end-of-type-icon.svg'}
                    alt=""
                    className="type-of-auction"
                  />
                </Tooltip>
              </div>
            )}
            {Boolean(auction.status_id) && (
              <AuctionIndicatorWithTimer auction={auction} expiresAt={expiresAt} isMobile={isMobile} />
            )}
            {auction?.auction_type !== 'buy_now' && (
              <Tooltip isDisabled={isMobile} text={isDownAuction ? 'Интервал понижения' : 'Интервал продления'}>
                <div className="d-flex seconds-wrapper align-items-center">
                  <img src="/img/icons/watch-icon.svg" alt="" />
                  <div className="name">{Math.ceil(auction.interval / 60)} мин.</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className={`d-flex flex-wrap mt-3 card-auction__lots-wrapper gap-10px ${view}`}>
        {auction.lots.map((lot, i) => {
          return (
            <LotCard
              lot={lot}
              auctionId={auction.id}
              auctionType={auction?.auction_type}
              lotTimer={i === lotTimer?.index && lotTimer?.timer}
              view={view}
              key={`lot-${lot.id}`}
              isMobile={isMobile}
            />
          );
        })}
      </div>
    </div>
  );
});
