import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { EditWinner } from '@pages/auction/auction-edit-trades-result-modal/edit-winner';
import { EditWinningBet } from '@pages/auction/auction-edit-trades-result-modal/edit-winning-bet';
import { EditTradesResult } from '@pages/auction/auction-edit-trades-result-modal/edit-trades-result';

import './edit-trades-result-modal.scss';

const STEPS_NUMBER = 2;

export const EditWinnerModal = ({ handleClose, lot, isEditWinner, setIsAuctionEditTradesWinner }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [selectedWinner, setSelectedWinner] = useState();

  const onStepChange = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, []);

  const onSelectWinner = (row, winnerType) => {
    if (row) {
      setSelectedWinner({ ...row, winnerType });
      onStepChange();
    }
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="edit-trades-result-wrapper"
    >
      <div className="edit-trades-result-wrapper__header">
        <div>
          {lot?.sales?.map((sale, index) => {
            return (
              <div className="edit-trades-result-wrapper__car-name" key={`sale-${index}`}>
                {sale.car?.brand?.name} {sale.car?.model?.name} {sale.car?.year} г.в. {sale.car?.engine_volume} {sale.car?.transmission?.name}  {sale.car?.vin}
              </div>
            );
          })}
        </div>
        <img src="/img/icons/close-icon.svg" alt="" onClick={() => handleClose()} className="cursor-pointer" />
      </div>
      <div className="edit-trades-result-wrapper__title">Изменение результатов торгов</div>
      {isEditWinner ? (
        <>
          {activeStep === 1
            && (
              <EditWinner
                step={1}
                stepsNumber={STEPS_NUMBER}
                onClickUserOrCompany={onSelectWinner}
              />
            )}
          {activeStep === 2
            && (
              <EditWinningBet
                step={2}
                stepsNumber={STEPS_NUMBER}
                handleClose={handleClose}
                selectedWinner={selectedWinner}
                lot={lot}
              />
            )}
        </>
      ) : (
        <EditTradesResult
          lot={lot}
          handleClose={handleClose}
          onStepChange={onStepChange}
          setIsAuctionEditTradesWinner={setIsAuctionEditTradesWinner}
        />
      )}
    </Modal>
  );
};
