import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { getCarTradesHistory } from '@api/cars';
import { managersStore } from '@mobx/managers';
import { Table } from '@components/table/table';

import { historyColumns } from './table-templates';

const PER_PAGE = [10, 25, 50];

export const TradesHistory = observer(({ carSaleInfo }) => {
  const [history, setHistory] = useState();

  useEffect(() => {
    if (!managersStore.managers.length) {
      managersStore.fetch();
    }
  }, []);

  const requestHistory = (params) => getCarTradesHistory(carSaleInfo.id, params).then(setHistory);

  useEffect(() => {
    requestHistory({ per_page: PER_PAGE[0] });
  }, [carSaleInfo]);

  return (
    <div className="section" id="tradesHistory">
      <div className="section-header">
        <div className="title">История торгов</div>
      </div>
      {history?.data?.length > 0
        ? (
          <Table
            data={history.data}
            columns={historyColumns}
          />
        )
        : <div>Пока что здесь нет изменений</div>}
    </div>
  );
});
