import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Accordion, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { meStore } from '@mobx/me';
import { DropdownButton } from '@components/dropdown/dropdown-button';
import { getSingleCar, postChangeStatusReportTransition, putStatusReportId } from '@api/cars';
import { getTechSpecialists } from '@api/users';
import useMediaQuery from '@hooks/use-media-query';
import { Select } from '@components/select/select';
import { getStatusReportsGroups } from '@api/reports';

import { StateAccordion } from './accordion-components/state-accordion';
import { WheelsAccordion } from './accordion-components/wheels-accordion';
import { CommentsAccordion } from './components/comments-accordion';

import './report.scss';

export const Report = observer(() => {
  const { saleId, reportId } = useParams();
  const isMobile = useMediaQuery();

  const [car, setCar] = useState();
  const [report, setReport] = useState();
  const [showModalCloseReport, setShowModalCloseReport] = useState(false);
  const [showModalChangeResponsibleId, setShowModalChangeResponsibleId] = useState(false);
  const [statusReportsGroups, setStatusReportsGroups] = useState();
  const [techSpecialists, setTechSpecialists] = useState();
  const [form, setForm] = useState({
    responsible_id: '',
  });

  const requestCar = () => {
    getSingleCar(saleId).then((resp) => {
      setCar(resp);
      setReport(resp.status_reports?.find((item) => item.id === Number(reportId)));
    });
  };

  useEffect(() => {
    requestCar();
    getTechSpecialists().then((resp) => setTechSpecialists(resp));
    getStatusReportsGroups().then(setStatusReportsGroups);
  }, []);

  const elModalCloseReport = () => {
    return showModalCloseReport && (
      <Modal
        show={true}
        onHide={() => setShowModalCloseReport(false)}
        className="d-flex align-items-center justify-content-center steps-modal-wrapper auto-bet-modal-wrapper"
      >
        <div className="head-text text-center">
          Закрыть отчет о состоянии? Повторное открытие данного отчета будет недоступно.
        </div>
        <div className="modal-actions mt-2">
          <button
            onClick={() => {
              postChangeStatusReportTransition(reportId, 'closed').then(() => {
                requestCar();
                setShowModalCloseReport(false);
              });
            }}
            className="btn btn-primary"
            type="button"
          >
            Закрыть отчет
          </button>
          <button onClick={() => setShowModalCloseReport(false)} className="btn btn-secondary" type="button">
            Отменить
          </button>
        </div>
      </Modal>
    );
  };

  const handleInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const elModalChangeResponsibleId = () => {
    return showModalChangeResponsibleId && (
      <Modal
        show={true}
        onHide={() => setShowModalChangeResponsibleId(false)}
        className="d-flex align-items-center justify-content-center steps-modal-wrapper auto-bet-modal-wrapper"
      >
        <div className="head-text text-center">
          Технический специалист
        </div>
        <div className="d-flex flex-column mt-2 gap-20px">
          <div className="d-flex align-items-center">
            <Select
              options={techSpecialists?.data?.map((user) => ({ value: user.id, label: user.full_name }))}
              valueId={form.responsible_id || ''}
              size="m"
              placeholder="Технический специалист"
              onChange={({ value }) => handleInputChange({ target: { name: 'responsible_id', value } })}
            />
          </div>
          <div className="modal-actions mt-2">
            <button
              onClick={() => {
                putStatusReportId(reportId, Number(form.responsible_id)).then(() => {
                  setShowModalChangeResponsibleId(false);
                  requestCar();
                });
              }}
              className="btn btn-primary"
              type="button"
            >
              Выбрать
            </button>
            <button onClick={() => setShowModalChangeResponsibleId(false)} className="btn btn-secondary" type="button">
              Отменить
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const elActions = () => {
    const options = [];

    if (meStore.hasPermission('admin.auctions.edit')) {
      options.push({
        label: 'Редактировать ответственного',
        value: 'edit',
        onClick: () => {
          setShowModalChangeResponsibleId(true);
        },
      });
    }

    if (report?.status?.code === 'opened') {
      options.push({
        label: 'Закрыть отчет',
        value: 'close',
        onClick: () => {
          setShowModalCloseReport(true);
        },
      });
    }

    if (options.length) {
      return (
        <div className="d-flex actions">
          <div className="d-flex align-items-center action">
            <DropdownButton
              btnText="Действия"
              options={options}
            />
          </div>
        </div>
      );
    }
  };

  const statusReports = useMemo(() => {
    if (statusReportsGroups) {
      return statusReportsGroups.map((item) => {
        if (!item.parent_id) {
          return (
            <Accordion
              key={item.id}
              defaultActiveKey="0"
            >
              <Accordion.Item eventKey={item.id} key={item.id}>
                <Accordion.Header>
                  {item.name === 'Состояние' ? (
                    <img src="/img/icons/state-icon.svg" alt="" />
                  ) : (
                    <img src="/img/icons/settings-icon.svg" alt="" />
                  )}
                  <div className="accordion-head">{item.name}</div>
                </Accordion.Header>
                <Accordion.Body>
                  <StateAccordion accordion={item} isMobile={isMobile} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        }
      });
    }
  }, [JSON.stringify(statusReportsGroups)]);

  const getClassName = (status) => {
    if (status === 'closed') {
      return 'car-status green';
    } if (status === 'opened') {
      return 'car-status orange';
    }
  };

  if (!car?.car) return null;

  return (
    <div className="reports-wrapper reports-wrapper_padding">
      <div className="d-flex gap-20px mob-title-wrapper">
        <div className="title">Отчет о состоянии Автомарт №{reportId}</div>
        <div className={getClassName(report?.status?.code)}>
          {report?.status?.code === 'opened' ? 'На осмотре' : 'Проверка произведена'}
        </div>
      </div>

      <hr className="mt-5" />

      <div className="d-flex align-items-center justify-content-between mob-direction_column">
        <div className="d-flex align-items-center">
          <Link to={`/car/${car.id}`} className="brand-name">{car.car.brand.name} {car.car.model.name}</Link>
        </div>

        <div className="d-flex align-items-center mob-direction_column checked">
          {elActions()}
        </div>
      </div>

      <div className="car-info d-flex align-items-center justify-content-start">
        <div>
          <div className="d-flex align-items-center">
            <div className="key">VIN:</div>
            <div className="value">{car.car.vin}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Размещение автомобиля:</div>
            <div className="value">{car.area?.name}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Площадка:</div>
            <div className="value">{car.location?.name}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Город:</div>
            <div className="value">{car.city?.name}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Технический специалист:</div>
            <div className="value">{report?.responsible?.full_name}</div>
          </div>
        </div>

        <div>
          <div className="d-flex align-items-center">
            <div className="key">Дата создания:</div>
            <div className="value">{report?.created_at ? moment(report?.created_at).format('DD.MM.YYYY') : '-'}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Дата закрытия:</div>
            <div className="value">{report?.closed_at ? moment(report?.closed_at).format('DD.MM.YYYY') : '-'}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Дата последнего изменения:</div>
            <div className="value">{report?.updated_at ? moment(report?.updated_at).format('DD.MM.YYYY') : '-'}</div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="key">Произвел изменения:</div>
            <div className="value">{report?.updated_by?.full_name ? report?.updated_by?.full_name : '-'}</div>
          </div>
        </div>
      </div>

      <div className="accordion-wrapper">
        {statusReports}
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <img src="/img/icons/wheels-icon.svg" alt="" />
              <div className="accordion-head">Колёса</div>
            </Accordion.Header>
            <Accordion.Body>
              <WheelsAccordion />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <img src="/img/icons/comment-icon.svg" alt="" />
              <div className="accordion-head">Комментарии</div>
            </Accordion.Header>
            <Accordion.Body>
              <CommentsAccordion isMobile={isMobile} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {elModalChangeResponsibleId()}
      {elModalCloseReport()}
    </div>
  );
});
