import React, { useEffect, useState } from 'react';

import { FilePreview } from '@components/file-preview/file-preview';
import { getCarSaleDocumentsRequest } from '@api/cars';

export const Documents = ({ id }) => {
  const [documents, setDocuments] = useState();

  useEffect(() => {
    getCarSaleDocumentsRequest(id).then(setDocuments);
  }, []);

  return (
    <div className="section car-info" id="documents">
      <div className="title">Документы</div>
      {documents?.length ? (
        <div className="documents__list">
          {documents?.map(({ id, document_files, document_type }) => (
            <div key={id} className="file col-md-3 ml-md-auto">
              <FilePreview file={{ ...document_files[0], document_type }} />
            </div>
          ))}
        </div>
      )
        : <div>Нет данных</div>}
    </div>
  );
};
