import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { getReports, getReportsCategoriesTypes, postMakeReport } from '@api/reporting';
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { Input } from '@components/input/input';
import { meStore } from '@mobx/me';
import { Loader } from '@components/loader/loader';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import { Select } from '@components/select/select';

import { SELLERS_COLUMNS } from './utils';

const DEFAULT_VALUES = {
  from: '',
  to: '',
  seller_id: '',
};

const PER_PAGE = [25, 50, 100, 250, 500];

export const ReportMakeSellers = ({ seller }) => {
  const [form, setForm] = useState(DEFAULT_VALUES);
  const [typesOfCategories, setTypesOfCategories] = useState();
  const [reports, setReports] = useState();
  const [typeValue, setTypeValue] = useState();
  const [isShowForm, setIsShowForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const getReportsRequest = (params) => {
    getReports(seller?.key, params).then((resp) => {
      setReports(resp);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (seller) {
      getReportsRequest({ page: 1, per_page: PER_PAGE[0] });
      getReportsCategoriesTypes(seller?.key).then((resp) => {
        setTypesOfCategories(resp);
        setTypeValue(resp?.[0]?.key);
      });

      if (seller?.params?.seller_id?.values?.length === 1) {
        setForm({ ...form, seller_id: seller?.params?.seller_id?.values?.[0].value });
      }
    }
  }, [seller]);

  const handleInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  if (meStore.data === null || loading) {
    return <Loader />;
  }

  const elSellerInput = () => {
    if (seller?.params?.seller_id?.type === 'enum') {
      return (
        <Select
          options={seller?.params?.seller_id?.values?.map(({ value, name }) => ({ value, label: name }))}
          valueId={form?.seller_id}
          size="l"
          className="w-70"
          placeholder="Выберите компанию"
          onChange={({ value }) => {
            setForm({ ...form, seller_id: value });
          }}
        />
      );
    }
    if (seller?.params?.seller_id?.type === 'autocomplete') {
      return (
        <InputRequisitesSearch
          name="seller_id"
          placeholder="Начните вводить название"
          onChange={(seller_id) => {
            setForm({ ...form, seller_id });
          }}
        />
      );
    }
  };

  const resetForm = () => {
    setForm(DEFAULT_VALUES);
    setIsShowForm(false);
  };

  const onSubmit = () => {
    const data = {
      'category': seller?.key,
      'type': typeValue,
      'params': { ...form },
    };

    postMakeReport(data).then((resp) => {
      if (!resp.message) {
        getReportsRequest();
        resetForm();
      }
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div className="head-text">{seller?.title}</div>
          <div className="text-extra-info">Хранятся 30 календарных дней после создания</div>
        </div>
        {(reports?.data?.length > 0 && !isShowForm) && (
          <button type="button" onClick={() => setIsShowForm(true)} className="btn btn-primary">
            Создать новый отчет
          </button>
        )}
      </div>
      {(isShowForm || !reports?.data?.length) && (
        <div className="reporting-form-wrapper">
          <div className="d-flex align-items-center justify-content-between mt-20px">
            <div className="name w-30">Тип отчета</div>
            <div className="d-flex align-items-center position-relative w-70">
              <div className="btn-wrapper">
                {typesOfCategories?.map((item, index) => {
                  return (
                    <span
                      onClick={() => setTypeValue(item.key)}
                      key={`interval-${index}`}
                      className={clsx('btn-wrapper__type cursor-pointer', {
                        'btn-wrapper__type-active': typeValue === item.key,
                      })}
                    >
                      {item.key}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-20px">
            <div className="name w-30">Продавец</div>
            <div className="d-flex align-items-center position-relative w-70">
              {elSellerInput()}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-20px">
            <div className="name w-30">За период</div>
            <div className="d-flex gap-10px align-items-center position-relative w-70">
              <div className="d-flex gap-10px align-items-center">
                <span>c</span>
                <Input
                  type="date"
                  name="from"
                  onChange={handleInputChange}
                  value={form.from || 'ДД.ММ.ГГГГ'}
                  className="input-date input-date-width"
                />
              </div>
              <div className="d-flex gap-10px align-items-center">
                <span>по</span>
                <Input
                  type="date"
                  name="to"
                  onChange={handleInputChange}
                  value={form.to || 'ДД.ММ.ГГГГ'}
                  className="input-date input-date-width"
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-15px mt-20px">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={form.from === '' || form.to === '' || form.seller_id === ''}
              onClick={() => onSubmit()}
            >
              Создать новый отчёт
            </button>
            {reports?.data?.length > 0 && (
              <button
                type="button"
                onClick={() => resetForm()}
                className="btn-secondary"
              >
                Отмена
              </button>
            )}
          </div>
        </div>
      )}
      {reports?.data?.length > 0 ? (
        <div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
          <Table
            data={reports?.data}
            columns={SELLERS_COLUMNS}
          />
        </div>
      ) : (
        <div className="reporting-wrapper__message">
          <div className="reporting-wrapper__message-title">Отчётов по продавцам пока нет</div>
          <div className="reporting-wrapper__message-text">Выберите компанию и период для создания нового отчёта</div>
        </div>
      )}
      {reports?.data?.length > 0 && (
        <Pagination
          data={reports}
          onChange={getReportsRequest}
          perPageList={PER_PAGE}
        />
      )}
    </div>
  );
};
