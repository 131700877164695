import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Loader } from '@components/loader/loader';
import { Tooltip } from '@components/tooltip/tooltip';
import { getCurrencySymbol } from '@utils/common';
import { formatDateWithTime } from '@utils/date';
import { formatValueWithSpaces } from '@utils/format';
import { Button } from '@components/button/button';
import { auctionsStore } from '@mobx/auctions';

import './trades-history-modal.scss';

const PER_PAGE = 15;

export const TradesHistoryModal = observer(({ handleClose, auctionId, lot, handleBet, isWinner, isLost, elBetInput, isMobile }) => {
  const { lotBets } = auctionsStore;

  const requestBets = (params, isNextPage) => {
    auctionsStore.getLotBets(auctionId, lot.id, { ...params, per_page: 10 }, isNextPage);
  };

  useEffect(() => {
    requestBets();

    return () => {
      auctionsStore.resetLotBets();
    };
  }, []);

  const elBetBtn = () => {
    let text = 'Сделать ставку';

    if (isWinner) {
      text = 'Лот куплен';
    } else if (isLost) {
      text = 'Торги закончились';
    }

    return (
      <Button
        onClick={() => {
          handleBet(auctionId, lot.id);
          handleClose();
        }}
        className="d-flex align-items-center justify-content-center auction__bet-btn"
      >
        {text}
      </Button>
    );
  };

  const debouncedCallback = debounce(() => {
    requestBets({ page: lotBets.current_page + 1, per_page: PER_PAGE }, true);
  }, 300);

  const handleScroll = (e) => {
    const elem = e.target;
    const scrolledToBottom = (elem?.scrollTop || 0) + (elem?.clientHeight || 0) + 40 >= (elem?.scrollHeight || 0);

    if (scrolledToBottom && lotBets?.last_page > lotBets?.current_page) {
      debouncedCallback();
    }
  };

  return (
    <div className="position-relative">
      <Modal
        show={true}
        onHide={handleClose}
        className={clsx('d-flex align-items-center justify-content-center trades-history-modal-wrapper', {
          'trades-history-modal-wrapper_no-content': !lotBets?.data?.length,
        })}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="head-text">{lotBets?.data?.length ? 'Ход торгов' : 'Ставки пока не ставились'}</div>
          <img src="/img/icons/close-icon.svg" alt="" onClick={() => handleClose()} className="cursor-pointer" />
        </div>
        {lotBets?.data?.length > 0 && (
          <>
            <div className="steps-modal-wrapper-table" onScroll={handleScroll}>
              {lotBets.data.map((step) => (
                <div key={step.id} className="steps-modal-item">
                  <div className="steps-modal-item__bet">
                    <div className="steps-modal-text-left">{formatValueWithSpaces(step.sum)} {getCurrencySymbol()}</div>
                    {step.from_autobet
                      ? (
                        <Tooltip text="Автоставка" isDisabled={isMobile}>
                          <img src="/img/icons/autobet-icon.svg" alt="" />
                        </Tooltip>
                      )
                      : <span className="trades-history-modal-icon-wrap" />}
                  </div>
                  {step.is_my && <div className="steps-modal-item__my-bet">Моя ставка</div>}
                  <div className="steps-modal-item__my-date">
                    <div className="day">{formatDateWithTime(step.created_at)}</div>
                  </div>
                </div>
              ))}
              {lotBets?.last_page > lotBets?.current_page && (
                <Loader />
              )}
            </div>
            <div className="trades-history-modal__actions">
              {elBetInput()}
              {elBetBtn()}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
});
