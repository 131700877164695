export const GREEN_STATUSES = ['sale_ready_for_sale', 'sale_sold', 'sale_in_auction', 'sale_approved', 'sale_sold'];
export const ORANGE_STATUSES = ['sale_new', 'sale_on_sale_reserved', 'sale_on_sale', 'sale_pending_review', 'sale_under_review', 'sale_waiting_approval'];
export const RED_STATUSES = ['sale_declined_seller', 'sale_declined_buyer'];

export const getClassName = (status) => {
  if (GREEN_STATUSES.includes(status)) {
    return 'status green';
  } if (ORANGE_STATUSES.includes(status)) {
    return 'status orange';
  } if (RED_STATUSES.includes(status)) {
    return 'status red';
  }
};
