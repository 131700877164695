import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import useMediaQuery from '@hooks/use-media-query';
import { meStore } from '@mobx/me';
import { dictionariesStore } from '@mobx/dictionaries';
import { NO_PERMISSION_URL } from '@utils/common';
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { clearFilterValues } from '@utils/filters/filter-constants';
import { getFiltersValues, getPageAuctionsRequest } from '@api/auctions';
import { Input } from '@components/input/input';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import { Select } from '@components/select/select';
import { Button } from '@components/button/button';
import { Loader } from '@components/loader/loader';
import { formatToUTC } from '@utils/date';

import { getColumns } from './utils';

import './auctions.scss';

const defaultFilters = { start_at: '' };

export const Auctions = observer(() => {
  const [auctions, setAuctions] = useState();
  const [sorting, setSorting] = useState({
    'field': 'planned_finish_at',
    'order': 'desc',
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [filtersReset, setFiltersReset] = useState();
  const [perPage, setPerPage] = useState(25);
  const [filters, setFilters] = useState(defaultFilters);

  const isMobile = useMediaQuery();

  useEffect(() => {
    dictionariesStore.request('status', { filters: { entity: 'auction' } });
  }, []);

  const requestAuctions = (page) => {
    const params = {
      page: page || auctions?.current_page || 1,
      per_page: perPage,
      ...getFiltersValues(filters),
    };

    if (params.start_at) {
      params.start_at = formatToUTC(params.start_at);
    }

    if (sorting.field && sorting.order) {
      params[`orders[${sorting.field}]`] = sorting.order;
    }

    getPageAuctionsRequest(params).then(setAuctions);
  };

  const onSort = (field, order) => {
    setSorting({ field, order });
  };

  const columns = getColumns(onSort);

  useEffect(() => {
    requestAuctions(1);
  }, [perPage, JSON.stringify(sorting), filtersReset]);

  if (meStore.noPermission('admin.auctions.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (!meStore.data || !auctions) {
    return <Loader />;
  }

  const changePage = (page) => {
    if (page > 0) {
      requestAuctions(page);
    }
  };

  const renderStatusOptions = () => {
    const arr = dictionariesStore.dictionaries?.status?.map((item) => ({ value: item.value, label: item.label })) || [];
    return [{ value: 'all', label: 'Все' }, ...arr];
  };

  const handleChangeFiltersFields = (e) => {
    let newValue = e.target.value;

    if (e.target.name === 'status_ids' || e.target.name === 'ids') {
      if (e.target.value === 'all') {
        newValue = undefined;
      } else {
        newValue = [e.target.value];
      }
    }

    setFilters({
      ...filters,
      [e.target.name]: newValue,
    });
  };

  const clearAllFilters = () => {
    setFilters({ ...clearFilterValues(filters) });
    setOpenFilter(false);
    setFiltersReset(Date.now());
  };

  return (
    <div className="auction-page-wrapper">
      <div className="filters-wrapper">
        <div className="d-flex align-items-center">
          <div onClick={() => setOpenFilter(!openFilter)} className="d-flex align-items-center">
            <div className="title">Фильтры</div>
            <img
              className={clsx('arrow-down cursor-pointer', { 'rotate-180': !openFilter })}
              src="/img/tabs/hide.svg"
              alt=""
            />
          </div>
        </div>
        <div className={!openFilter ? 'd-none' : 'filter'}>
          <div className="d-flex align-items-center flex-wrap">
            <Input
              type="number"
              placeholder="№ аукциона"
              name="ids"
              value={filters.ids}
              onChange={(e) => handleChangeFiltersFields(e)}
              size="m"
            />
            <Input
              size="m"
              type="date"
              name="start_at"
              onChange={(e) => handleChangeFiltersFields(e)}
              value={filters.start_at}
              placeholder="Дата начала от:"
            />
            <Select
              options={renderStatusOptions()}
              valueId={filters?.status_ids || ''}
              size="m"
              placeholder="Статус"
              onChange={({ value }) => handleChangeFiltersFields({ target: { name: 'status_ids', value } })}
            />
            <InputRequisitesSearch
              name="department_ids"
              resetValue={filtersReset}
              placeholder="Компании или подразделения"
              size="m"
              onChange={(value) => {
                handleChangeFiltersFields({ target: { name: 'department_ids', value: [value] } });
              }}
            />
            <InputRequisitesSearch
              name="in_department_ids"
              resetValue={filtersReset}
              placeholder="Компании"
              size="m"
              onChange={(value) => {
                handleChangeFiltersFields({ target: { name: 'in_department_ids', value: [value] } });
              }}
            />
          </div>
          <div className="mt-20px d-flex gap-10px">
            <Button onClick={() => requestAuctions()} preset="primary">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} preset="ghost">
              Отменить все фильтры
            </Button>
          </div>
        </div>
      </div>
      {!openFilter ? <hr className="mt-4" /> : ''}
      <div className="d-flex align-items-center justify-content-end header mb-3">
        {meStore.hasPermission('admin.auctions.edit') && (
          <Link to="/add-auction" className="btn btn-primary">
            Добавить
          </Link>
        )}
      </div>
      {auctions?.data?.length > 0 ? (
        <Table
          headerClasses="align-top"
          data={auctions.data}
          columns={columns}
        />
      ) : (
        <p>Нет аукционов</p>
      )}
      <Pagination
        data={auctions}
        onChangePage={changePage}
        onChangePerPage={(e) => setPerPage(e.target.value)}
        perPageList={[25, 50, 100, 250]}
        hideInfo={isMobile}
      />
    </div>
  );
});
