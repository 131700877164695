import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import { Pagination } from '@components/pagination/pagination';
import { Input } from '@components/input/input';
import { citiesStore } from '@mobx/cities';
import { filteredUsers, getUsersList } from '@api/users';
import { Table } from '@components/table/table';
import { Select } from '@components/select/select';
import { addUsersToCompanyRequest, deleteUsersFromCompanyRequest } from '@api/companies';
import { Button } from '@components/button/button';

import { COLUMNS } from './utils';

import './users-department-modal.scss';

const PER_PAGE = [25, 50, 100, 250, 500];

const DEFAULT_FORM = {
  first_name: '',
  middle_name: '',
  last_name: '',
  city_id: '',
};

export const UsersDepartmentModal = observer(({ company_id, show, onHide, selected, type }) => {
  const [users, setUsers] = useState();
  const { cities } = citiesStore;

  const [selectedRows, setSelectedRows] = useState(selected || []);
  const [filters, setFilters] = useState(DEFAULT_FORM);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
    selected: selectedRows,
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows(rows.map((item) => item.id));
      } else {
        setSelectedRows([]);
      }
    },
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        setSelectedRows((prev) => [...prev, row.id]);
      } else {
        setSelectedRows((prev) => prev.filter((item) => item !== row.id));
      }
    },
  };

  const requestUsers = (params) => {
    getUsersList(params).then(setUsers);
  };

  useEffect(() => {
    requestUsers({ page: 1, per_page: PER_PAGE[0] });

    if (!citiesStore.cities.length) {
      citiesStore.fetch();
    }
  }, []);

  const addUsers = async () => {
    await addUsersToCompanyRequest(company_id, { user_ids: selectedRows });

    const idsToRemove = [];

    selected?.forEach((item) => {
      if (!selectedRows.includes(item)) {
        idsToRemove.push(item);
      }
    });

    if (idsToRemove.length) {
      await deleteUsersFromCompanyRequest(company_id, { user_ids: idsToRemove });
    }

    onHide(true);
  };

  const getFilteredUsersList = () => {
    filteredUsers(filters, { page: 1, per_page: users?.per_page }).then(setUsers);
  };

  const clearAllFilters = () => {
    setFilters(DEFAULT_FORM);
    requestUsers({ page: 1, per_page: users?.per_page });
  };

  const handleInputChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal show={show} onHide={onHide} className="users-department-modal">
      <div className="filters-wrapper">
        <div>
          <div className="d-flex align-items-center flex-wrap justify-content-between">
            <Input
              placeholder="Имя"
              name="first_name"
              value={filters.first_name}
              onChange={handleInputChange}
            />
            <Input
              placeholder="Фамилия"
              name="last_name"
              value={filters.last_name}
              onChange={handleInputChange}
            />
            <Input
              placeholder="Отчество"
              name="middle_name"
              value={filters.middle_name}
              onChange={handleInputChange}
            />
            <Select
              options={cities?.map((city) => ({ value: city.id, label: city.name }))}
              valueId={filters?.city_id}
              size="l"
              name="city_id"
              placeholder="Город"
              onChange={({ value }) => handleInputChange({ target: { name: 'city_id', value } })}
            />
          </div>
          <div className="mt-20px d-flex gap-10px">
            <Button onClick={getFilteredUsersList} preset="primary">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} preset="ghost">
              Отменить все фильтры
            </Button>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end header mb-3">
        <button onClick={addUsers} className="btn btn-primary" type="button">
          Сохранить
        </button>
      </div>
      {users?.data?.length > 0 ? (
        <Table
          data={users.data}
          columns={COLUMNS}
          selectRow={selectRow}
        />
      ) : (
        <p>Нет пользователей</p>
      )}
      <Pagination
        data={users}
        onChange={(params) => filteredUsers(filters, params).then(setUsers)}
        perPageList={PER_PAGE}
      />
    </Modal>
  );
});
