import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';

export const getColumns = (setSorting) => [
  {
    dataField: 'first_name',
    text: 'ФИО',
    sort: true,
    formatter: (cell, row) => <Link to={`/user/${row.id}`}>{row.first_name} {row.last_name} {row.middle_name ? row.middle_name : ''}</Link>,
    sortFunc: () => {},
    onSort: (field, order) => {
      setSorting({ field, order });
    },
  },
  {
    dataField: 'phone',
    text: 'Телефон',
  },
  {
    dataField: 'status',
    text: 'Статус',
    formatter: (cell, row) => (row.status ? row.status.name : ''),
  },
  {
    dataField: 'created_at',
    text: 'Создан',
    formatter: (cell, row) => moment(row.created_at).format('DD.MM.YYYY'),
    sort: true,
    sortFunc: () => {},
    onSort: (field, order) => {
      setSorting({ field, order });
    },
  },
  {
    dataField: 'city',
    text: 'Город',
    formatter: (cell, row) => <div>{row.city ? row.city.name : '-'}</div>,
  },
  {
    dataField: 'last_active_at',
    text: 'Последняя активность',
    formatter: (cell, row) => (row.last_active_at ? moment(row.last_active_at).format('DD.MM.YYYY') : '-'),
    sort: true,
    sortFunc: () => {},
    onSort: (field, order) => {
      setSorting({ field, order });
    },
  },
  {
    dataField: 'roles',
    text: 'Роль',
    formatter: (cell, row) => (row.roles.length ? row.roles.map((role) => role.name).join(', ') : ''),
  },
  {
    dataField: 'email',
    text: 'E-mail',
    formatter: (cell, row) => {
      return (
        <div className="break-word">{row.email || '-'}</div>
      );
    },
  },
  {
    dataField: 'email_verified',
    text: 'E-mail подтвержден',
    formatter: (cell, row) => {
      return (
        <div>{row.email_verified_at ? 'Да' : 'Нет'}</div>
      );
    },
  },
];
