import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { meStore } from '@mobx/me';
import { DEFAULT_APP_URL, NO_AUTH_URL } from '@utils/common';
import { LayoutMain } from '@components/layout/layout';

import { NoVerified } from './components/no-verified';

export const Root = observer(({ children }) => {
  const navigate = useNavigate();

  const checkUser = (data) => {
    if (data.user?.email_verified_at && data.impersonate_as?.entity?.roles.length && (
      meStore.hasPermission('auctions.view') || meStore.hasPermission('admin.auctions.view')
    )) {
      navigate(DEFAULT_APP_URL);
    }
  };

  useEffect(() => {
    if (meStore.data === null) {
      meStore.fetch().then((resp) => {
        if (!resp.message) {
          checkUser(resp);
        } else {
          navigate(NO_AUTH_URL);
        }
      });
    } else {
      checkUser(meStore.data);
    }
  }, [meStore.data]);

  if (!meStore.data) return null;

  if (!meStore.data?.user?.email_verified_at) {
    return <NoVerified />;
  }

  if (!meStore.data?.impersonate_as?.entity?.roles?.length || (meStore.noPermission('auctions.view') && meStore.noPermission('admin.auctions.view'))) {
    return <LayoutMain />;
  }

  return null;
});
