import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { meStore } from '@mobx/me';
import { Input } from '@components/input/input';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import { getUserGroupsRequest } from '@api/user-groups';
import { Button } from '@components/button/button';

import './user-tabs.scss';

const COLUMNS = [
  {
    dataField: 'name',
    text: 'Имя',
    sort: true,
    formatter: (cell, row) => <Link to={`/user-group/${row.id}`}>{row.name}</Link>,
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    formatter: (cell, row) => moment(row.created_at).format('DD-MM-YYYY'),
    sort: true,
  },
];

export const GroupsTab = observer(() => {
  const [userGroups, setUserGroups] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
  });

  useEffect(() => {
    getUserGroupsRequest().then(setUserGroups);
  }, []);

  const handelFiltersChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    getUserGroupsRequest({ 'filters[name]': filters.name }).then(setUserGroups);
  };

  const clearAllFilters = () => {
    setOpenFilter(!openFilter);
    setFilters({ name: '' });
    getUserGroupsRequest().then(setUserGroups);
  };

  return (
    <div className="group-tab-wrapper">
      <div className="filters-wrapper">
        <div className="d-flex align-items-center">
          <div onClick={() => setOpenFilter(!openFilter)} className="d-flex align-items-center">
            <div className="title">Фильтры</div>
            <img
              className={clsx('arrow-down cursor-pointer', { 'rotate-180': !openFilter })}
              src="/img/tabs/hide.svg"
              alt=""
            />
          </div>
        </div>
        <div className={!openFilter ? 'd-none' : 'filter'}>
          <div className="d-flex align-items-center flex-wrap">
            <Input
              size="m"
              placeholder="Название группы"
              onChange={handelFiltersChange}
              value={filters.name}
              name="name"
            />
          </div>
          <div className="mt-20px d-flex gap-10px">
            <Button onClick={handleFilter} preset="primary">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} preset="ghost">
              Отменить все фильтры
            </Button>
          </div>
        </div>
      </div>
      {!openFilter ? <hr className="mt-4" /> : ''}
      <div className="users-list">
        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <div className="title">Группы пользователей</div>
          {meStore.hasPermission('groups.edit') && (
            <Link to="/user-group/create" className="btn btn-primary">
              Добавить
            </Link>
          )}
        </div>
        {userGroups?.data?.length > 0 ? (
          <>
            <Table data={userGroups.data} columns={COLUMNS} />
            <Pagination
              data={userGroups}
              onChange={(params) => {
                const newParams = { ...params };

                if (filters.name) {
                  newParams['filters[name]'] = filters.name;
                }

                getUserGroupsRequest(newParams).then(setUserGroups);
              }}
              perPageList={[25, 50, 100, 250, 500]}
            />
          </>
        ) : (
          <p>Нет данных</p>
        )}
      </div>
    </div>
  );
});
