import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { meStore } from '@mobx/me';
import { carBrandsStore } from '@mobx/car-brands';
import { citiesStore } from '@mobx/cities';
import { dictionariesStore } from '@mobx/dictionaries';
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { Input } from '@components/input/input';
import { Select } from '@components/select/select';
import { Button } from '@components/button/button';
import { getSaleModelsRequest } from '@api/dictionaries';
import { CarsFiltersModal } from '@pages/cars/cars-tabs/cars-filters-modal';

import '../cars.scss';

const DEFAULT_FILTERS = {
  vin: '',
  brand_id: '',
  model_id: '',
  year_gt: '',
  year_ls: '',
  mileage_gt: '',
  mileage_ls: '',
  city_id: '',
  area_id: '',
  status_id: '',
  location_id: '',
};

export const CarsFilters = observer(({ onChange, isMobile, localStorageFilters }) => {
  const isFiltersDataRequested = useRef();

  const { car_locations, areas } = dictionariesStore.dictionaries;
  const { cities } = citiesStore;
  const { brands } = carBrandsStore;
  const { car_status } = dictionariesStore.dictionaries;
  const filtersCount = localStorageFilters && Object.keys(localStorageFilters).length;

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [filtersReset, setFiltersReset] = useState();
  const [isShowCarsFiltersModal, setIsShowCarsFiltersModal] = useState(false);
  const [models, setModels] = useState([]);

  const applyFilters = () => onChange(filters, false);
  const clearAllFilters = () => {
    setFiltersReset(Date.now());
    setFilters(DEFAULT_FILTERS);
    onChange(null, true);
  };

  useEffect(() => {
    if (localStorageFilters) {
      setFilters((prev) => ({ ...prev, ...localStorageFilters }));

      if (localStorageFilters?.brand_id) {
        getSaleModelsRequest(localStorageFilters.brand_id, 'car').then(setModels);
      }
    }
  }, []);

  useEffect(() => {
    if ((openFilter || isShowCarsFiltersModal) && !isFiltersDataRequested.current) {
      isFiltersDataRequested.current = true;

      if (!brands.length) carBrandsStore.fetch();
      if (!citiesStore.cities.length) citiesStore.fetch();
      if (!car_locations?.length) dictionariesStore.request('car_locations');
      if (!areas?.length) dictionariesStore.request('areas');

      if (!car_status?.length) {
        dictionariesStore.request('status', { filters: { entity: 'sale' }, saveKey: 'car_status' });
      }
    }
  }, [openFilter, isShowCarsFiltersModal]);

  const handleFilterChange = (e) => {
    const newFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === 'brand_id') {
      newFilters.model_id = '';
    }

    setFilters(newFilters);
  };

  return (
    <>
      {isMobile ? (
        <button
          type="button"
          onClick={() => setIsShowCarsFiltersModal(true)}
          className="btn-filters"
        >
          <img src="/img/icons/filter-icon.svg" alt="" />
          Фильтры
          {filtersCount > 0 && <div className="filters-count">{filtersCount}</div>}
        </button>
      ) : (
        <div className="car-sales__filters-wrapper">
          <div className="d-flex align-items-center position-relative">
            <div onClick={() => setOpenFilter(!openFilter)} className="d-flex align-items-center">
              <div className="title">Фильтры</div>
              {filtersCount > 0 && <div className="filters-count">{filtersCount}</div>}
              <img
                className={clsx('arrow-down cursor-pointer', { 'rotate-180': !openFilter })}
                src="/img/tabs/hide.svg"
                alt=""
              />
            </div>
          </div>
          {openFilter && (
            <div className="filter">
              <div className="d-flex align-items-center flex-wrap gap-10px">
                <Input
                  size="m"
                  placeholder="VIN"
                  name="vin"
                  value={filters.vin}
                  onChange={handleFilterChange}
                />
                <Select
                  options={brands}
                  valueId={filters?.brand_id}
                  size="m"
                  placeholder="Марка"
                  onChange={({ value }) => {
                    handleFilterChange({ target: { name: 'brand_id', value } });
                    getSaleModelsRequest(value, 'car').then(setModels);
                  }}
                />
                <Select
                  options={models?.map((model) => ({ value: model.id, label: model.name }))}
                  valueId={filters?.model_id}
                  size="m"
                  placeholder="Модель"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'model_id', value } })}
                />
                <Input
                  size="m"
                  type="number"
                  placeholder="Год выпуска от"
                  name="year_gt"
                  value={filters.year_gt}
                  onChange={handleFilterChange}
                />
                <Input
                  size="m"
                  type="number"
                  placeholder="Год выпуска до"
                  name="year_ls"
                  value={filters.year_ls}
                  onChange={handleFilterChange}
                />
                <Input
                  size="m"
                  name="mileage_gt"
                  value={filters.mileage_gt}
                  onChange={handleFilterChange}
                  placeholder="Пробег от:"
                />
                <Input
                  size="m"
                  name="mileage_ls"
                  value={filters.mileage_ls}
                  onChange={handleFilterChange}
                  placeholder="Пробег до:"
                />
                <Select
                  options={cities?.map((city) => ({ value: city.id, label: city.name }))}
                  valueId={filters?.city_id}
                  size="m"
                  placeholder="Город"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'city_id', value } })}
                />
                <Select
                  options={car_status}
                  valueId={filters?.status_id}
                  size="m"
                  placeholder="Статус"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'status_id', value } })}
                />
                <Select
                  options={areas}
                  valueId={filters?.area_id}
                  size="m"
                  placeholder="Регион"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'area_id', value } })}
                />
                <Select
                  options={car_locations}
                  valueId={filters?.location_id}
                  size="m"
                  placeholder="Местонахождение"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'location_id', value } })}
                />
                {meStore.hasPermission('admin.sales.view') && (
                  <InputRequisitesSearch
                    size="m"
                    name="department_ids"
                    resetValue={filtersReset}
                    placeholder="Компании или подразделения"
                    onChange={(value) => {
                      handleFilterChange({ target: { name: 'department_ids', value: [value] } });
                    }}
                  />
                )}
                {meStore.hasPermission('admin.sales.view') && (
                  <InputRequisitesSearch
                    size="m"
                    name="in_department_ids"
                    resetValue={filtersReset}
                    placeholder="Компании"
                    onChange={(value) => {
                      handleFilterChange({ target: { name: 'in_department_ids', value: [value] } });
                    }}
                  />
                )}
              </div>
              <div className="car-sales__filters-buttons">
                <Button onClick={() => applyFilters(filters)} size="m">
                  Применить фильтры
                </Button>
                <Button onClick={clearAllFilters} preset="secondary" size="m">
                  Отменить все фильтры
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {isShowCarsFiltersModal && (
      <CarsFiltersModal
        handleClose={() => setIsShowCarsFiltersModal(false)}
        clearAllFilters={clearAllFilters}
        handleFilterChange={handleFilterChange}
        filters={filters}
        brands={brands}
        cities={cities}
        carStatus={car_status}
        carLocations={car_locations}
        applyFilters={applyFilters}
      />
      )}
    </>
  );
});
