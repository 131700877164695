import React, { useEffect, useState } from 'react';

import { Button } from '@components/button/button';
import { Loader } from '@components/loader/loader';
import { getCarSaleComments, postAddSaleComment, putUpdateSaleComment } from '@api/cars';

import { ModalConfirm } from './components/modal-confirm';

export const Comment = ({ onStepChange, edit, carData, showConfirmPopup, onConfirmChangeStep }) => {
  const id = carData?.id;
  const [loading, setLoading] = useState(edit);
  const [comment, setComment] = useState();
  const [commentId, setCommentId] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [comments, setComments] = useState();

  useEffect(() => {
    getCarSaleComments(id).then(setComments);
  }, []);

  useEffect(() => {
    if (comments?.data?.length) {
      setComment(comments.data?.[0]?.body);
      setCommentId(comments.data?.[0]?.id);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [comments?.data]);

  const saveRequest = () => {
    if (commentId) {
      return putUpdateSaleComment(id, commentId, { body: comment });
    }

    return postAddSaleComment(id, { body: comment });
  };

  const onClickSave = () => {
    saveRequest().then((resp) => {
      if (!resp?.error) onStepChange();
    });
  };

  return (
    <div>
      <div className="title mb-4">
        <span>Комментарий</span>
      </div>
      {loading
        ? <Loader />
        : (
          <>
            <div className="all-info-wrapper">
              <div className="info-field-wrapper">
                <label htmlFor="body" className="input-wrapper d-flex align-items-center justify-content-between">
                  <span className="input-label">Дополнительный комментарий для аукциона</span>
                  <div className="input-field-wrapper d-flex align-items-center info-field">
                    <textarea
                      className="input-field"
                      id="body"
                      name="body"
                      defaultValue={comment}
                      onChange={(e) => {
                        setIsDirty(true);
                        setComment(e.target.value);
                      }}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 mt-3">
              <Button preset="primary" className="flex-grow-1" onClick={onClickSave}>
                Сохранить
              </Button>
              {edit && (
              <Button preset="secondary" className="flex-grow-1" onClick={onStepChange}>
                Пропустить
              </Button>
              )}
            </div>
          </>
        )}
      {showConfirmPopup && (
        <ModalConfirm
          onSave={saveRequest}
          onClose={onConfirmChangeStep}
          isDirty={isDirty}
        />
      )}
    </div>
  );
};
