import React from 'react';

import { VIEW_HORIZONTAL } from '@pages/current-trades/current-trades-filters/utils';
import { getCurrencySymbol } from '@utils/common';
import { Button } from '@components/button/button';
import { auctionsStore } from '@mobx/auctions';

import { LotCars } from './lot-cars/lot-cars';

import './auction-down.scss';

export const LotCardBuyNowAuction = (props) => {
  const { lot, auctionId, view, classes, isWinner, isLost, hideCars, elFavouriteBtn } = props;

  const elBetButton = () => {
    const getClassNameBet = () => {
      if (isWinner) {
        return 'btn__bought-by-me';
      } if (isLost) {
        return 'btn__bought-by-not-me';
      }

      return 'btn__active';
    };

    let text = (
      <div className="d-flex align-items-center justify-content-center">{lot.price.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} {getCurrencySymbol()}  ·  Купить</div>
    );

    if (isWinner) {
      text = (
        <div className="d-flex align-items-center gap-5px justify-content-center">
          <img src="/img/icons/tick-icon.svg" />
          Куплено
        </div>
      );
    } else if (isLost) {
      text = 'Продано';
    }

    return (
      <Button
        onClick={() => lot.is_active && auctionsStore.betAuction(auctionId, lot.id, lot.price)}
        className={`auction__bet-btn auction-buy-now__bet-btn ${getClassNameBet()}`}
        disabled={!lot.is_active}
      >
        {text}
      </Button>
    );
  };

  const elTop = () => {
    if (lot.finished_at || !lot.is_active) {
      return <div className="disabled-auctions" />;
    }
  };

  const elActions = () => {
    if (view === VIEW_HORIZONTAL) {
      return (
        <div className="actions">
          <div className="w-100 position-relative d-flex justify-content-between align-items-center">
            {elBetButton()}
            {elFavouriteBtn}
          </div>
        </div>
      );
    }

    return (
      <div className="actions">
        <div className="w-100 position-relative d-flex justify-content-between align-items-center gap-20px">
          {elBetButton()}
          {elFavouriteBtn}
        </div>
      </div>
    );
  };

  return (
    <div className={`lot-car-wrapper position-relative ${classes.join(' ')}`}>
      {elTop()}
      {!hideCars && <LotCars lot={lot} view={view} auctionId={auctionId} />}
      {elActions()}
    </div>
  );
};
