import React from 'react';
import { Link } from 'react-router-dom';

import { formatValueWithSpaces } from '@utils/format';
import { managersStore } from '@mobx/managers';
import { meStore } from '@mobx/me';

/**
 * Датавремя возвращается с учетом пользовательского часового пояса
 * @param item
 * @return {string}
 */
export const setDateLocale = (item) => {
  return new Intl.DateTimeFormat('ru', {
    dateStyle: 'short',
    timeStyle: 'short',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date(item));
};

/**
 * @type {{DELETE: string, CREATE: string, UPDATE: string}}
 */
const typeLiterals = {
  'CREATE': 'Создание',
  'UPDATE': 'Изменение',
  'DELETE': 'Удаление',
};
/**
 * @type {{fields: string, relations: string, status: string}}
 */
const subtypesLiterals = {
  'status': 'Изменение статуса',
  'fields': 'Изменение авто',
  'relations': 'Действие над фото/документами',
};

// списки колонок для обоих разделов
export const changesColumns = [
  {
    dataField: 'type',
    text: 'Действие',
    formatter: (cell) => typeLiterals[cell] || '-',
  },
  {
    dataField: 'subtypes',
    text: 'Тип изменения',
    formatter: (cell) => (cell?.length > 0 && cell.map((subtype) => subtypesLiterals[subtype]).join(', ')),
  },
  {
    dataField: 'updated_at',
    text: 'Дата изменения',
    formatter: (cell) => setDateLocale(cell) || '-',
  },
  {
    dataField: 'user',
    text: 'Пользователь',
    formatter: (cell) => cell?.full_name || '-',
  },
];

export const historyColumns = [
  {
    dataField: 'auction_id',
    text: '№ Аукциона',
    formatter: (cell, row) => (meStore.noPermission('admin.auctions.view') ? cell : <Link to={`/auction/${cell}`}>{cell}</Link>),
  },
  {
    dataField: 'finished_at',
    text: 'Завершен',
    formatter: (cell) => (cell ? setDateLocale(cell) : '-'),
  },
  {
    dataField: 'responsible_id',
    text: 'Менеджер',
    formatter: (cell, row) => managersStore.items.get(row?.auction?.responsible_id)?.full_name || '-',
  },
  {
    dataField: 'lastBet',
    text: 'Последняя ставка',
    formatter: (cell) => (cell?.sum ? formatValueWithSpaces(cell?.sum) : '-'),
  },
  {
    dataField: 'highest_bet',
    text: 'Максимальная',
    formatter: (cell) => (cell?.sum ? formatValueWithSpaces(cell?.sum) : '-'),
  },
  {
    dataField: 'winning_bet',
    text: 'Победившая',
    formatter: (cell) => (cell?.sum ? formatValueWithSpaces(cell?.sum) : '-'),
  },
];
