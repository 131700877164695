import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Panel } from 'rsuite';
import { Accordion } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import { getCarSaleFillableFields, postCreateCarSale, putUpdateCarSale } from '@api/cars';
import { FormField } from '@components/form/form-field';
import { Loader } from '@components/loader/loader';
import { dictionariesStore } from '@mobx/dictionaries';
import { carSaleStore } from '@mobx/car-sale';

import { BooleanField } from './components/boolean-field';
import { DictionaryField } from './components/dictionary-field';
import { ModalConfirm } from './components/modal-confirm';
import {
  booleanOptions,
  fieldType,
  getGeneralInformationDefaultValues,
  getSubmitValues,
  transformVin,
} from './utils';
import { CarSeller } from './components/car-seller';

import './car-form-components.scss';

export const GeneralInformation = observer(({ onStepChange, carData, edit, showConfirmPopup, onConfirmChangeStep }) => {
  const carTypes = dictionariesStore.dictionaries.car_types;
  const [loading, setLoading] = useState(edit);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState();
  const [defaultValues, setDefaultValues] = useState({
    car_type_key: { value: 'car', label: 'Легковой' },
    is_left_handed: 'true',
    vin: '',
  });
  const context = useForm({ defaultValues, mode: 'onChange' });
  const values = context.watch();
  const { isDirty } = context.formState;

  useEffect(() => {
    if (!carTypes) {
      dictionariesStore.request('car_types');
    }
  }, []);

  useEffect(() => {
    if (values?.car_type_key?.value) {
      getCarSaleFillableFields(values?.car_type_key?.value).then((resp) => {
        if (resp.groups) {
          setGroups(resp.groups);
        }
      });
    }
  }, [values?.car_type_key?.value]);

  useEffect(() => {
    if (edit && carData?.car && groups.length) {
      const newDefaultValues = getGeneralInformationDefaultValues(carData);

      context.reset(newDefaultValues);

      setDefaultValues(newDefaultValues);
      setLoading(false);
    }
  }, [JSON.stringify(carData), groups, edit]);

  useEffect(() => {
    if (context.formState.dirtyFields.city_id) {
      const newArea = dictionariesStore.dictionaries.areas.find((item) => item.value === values.city_id.area_id);

      if (newArea) {
        context.setValue('area_id', newArea, { shouldValidate: true });
      }
    }
  }, [values.city_id]);

  const arrFields = useMemo(() => {
    let arr = [];

    if (carData?.metadata) {
      arr = [...carData.metadata.allowed_fields.update, ...carData.metadata.car.allowed_fields];
    }

    return arr;
  }, [JSON.stringify(carData?.metadata)]);

  const getDisabled = (field) => {
    return edit && !arrFields?.includes(field);
  };

  const saveRequest = () => {
    const submitValues = getSubmitValues(values);

    return edit ? putUpdateCarSale(carData?.id, submitValues) : postCreateCarSale(submitValues);
  };

  const onSubmit = context.handleSubmit(
    async () => {
      try {
        const response = await saveRequest();

        if (response?.error) {
          setError(response.error?.response?.data?.errors || response.error?.response?.data?.message);

          setTimeout(() => {
            setError(null);
          }, 5000);
        } else {
          await carSaleStore.fetchCar(response.id);

          onStepChange();
        }
      } catch (err) {
        console.error(err);
      }
    },
  );

  return (
    <div>
      <div className="title mb-4">Общая информация</div>
      {loading
        ? <Loader />
        : (
          <FormProvider {...context}>
            <Panel className="all-info-wrapper">
              <div className="info-field-wrapper info-field-wrapper_top">
                <FormField
                  name="car_type_key"
                  label="Тип авто"
                  className="info-field"
                  as="select"
                  options={carTypes || []}
                  defaultValue={values.car_type_key}
                  disabled={edit}
                />
              </div>
              <Accordion alwaysOpen defaultActiveKey={[0, 1]}>
                {groups.map((group, index) => (
                  <Accordion.Item key={`${group.name}-${index}`} eventKey={index}>
                    <Accordion.Header>{group.name}</Accordion.Header>
                    <Accordion.Body>
                      {group.fields.map((field) => {
                        const { type, key } = field;

                        if (type === 'dictionary') {
                          return (
                            <div className="info-field-wrapper" key={key}>
                              <DictionaryField
                                id={key}
                                key={key}
                                field={field}
                                formState={values}
                                value={values[field.key]}
                                required
                                disabled={edit && getDisabled(field.key)}
                              />
                            </div>
                          );
                        }
                        if (type === 'boolean') {
                          const fieldName = field?.key || `${field?.id}_`;

                          return (
                            <div className="info-field-wrapper" key={key}>
                              <BooleanField
                                options={booleanOptions}
                                field={{ ...field, fieldName }}
                                value={values[fieldName]}
                                required
                                disabled={edit && getDisabled(field.key)}
                              />
                            </div>
                          );
                        }
                        if (key === 'owner') {
                          return (
                            <CarSeller
                              key={key}
                              setDepartmentId={(value) => context.setValue('owner', value)}
                              defaultDepartmentValue={edit && carData.owner?.display_name}
                              disabled={edit && getDisabled(field.key)}
                            />
                          );
                        }

                        return (
                          <div className="info-field-wrapper" key={key}>
                            <FormField
                              id={key}
                              name={field.key || `${field.id}_`}
                              label={field.name}
                              className="info-field"
                              placeholder={field.name}
                              as={fieldType[type]}
                              defaultValue={field.key === 'vin' ? undefined : defaultValues[field.key]}
                              key={field.name}
                              required
                              disabled={edit && getDisabled(field.key)}
                              changeTransform={field.key === 'vin' ? transformVin : undefined}
                              value={field.key === 'vin' ? values.vin : undefined}
                            />
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Panel>
            {error && (
              <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(error, null, ' ')}</div>
            )}
            <div className="d-flex align-items-center gap-3 mt-3 mob-button-wrapper">
              <button className="btn btn-primary flex-grow-1" type="submit" onClick={onSubmit} disabled={!context.formState.isValid}>
                Сохранить
              </button>
              {edit && (
                <button className="btn btn-light flex-grow-1" type="button" onClick={onStepChange}>
                  Пропустить
                </button>
              )}
            </div>
          </FormProvider>
        )}
      {showConfirmPopup && (
        <ModalConfirm
          onSave={() => saveRequest}
          onClose={onConfirmChangeStep}
          isDirty={isDirty}
        />
      )}
    </div>
  );
});
