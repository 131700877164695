import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import clsx from 'clsx';
import Countdown from 'react-countdown';
import { observer } from 'mobx-react-lite';

import { auctionsStore } from '@mobx/auctions';
import { Tooltip } from '@components/tooltip/tooltip';

import { animationStyle } from './utils';

export const AuctionIndicatorWithTimer = observer(({ auction, expiresAt, isMobile }) => {
  const isDownAuction = auction?.auction_type === 'sequential_decrease';

  const lotEndingWasChange = auctionsStore.lotEndingWasChange[auction.id];
  const lotDecreased = auctionsStore.lotDecreased[auction.id];
  const lotStarted = auctionsStore.lotStarted[auction.id];
  const lotFinished = auctionsStore.lotFinished[auction.id];

  const activeLot = auction.lots.find((item) => item.is_active);
  const [downAuctionCounter, setDownAuctionCounter] = useState(activeLot?.next_update_at || Date.now() + auction.interval * 1000);
  const [animate, setAnimate] = useState(false);
  const $timerRef = useRef();
  const [changedTime, setChangedTime] = useState(15000);

  const calculateTimeLeft = () => {
    if (auction.lots.length && auction.start_at && !isDownAuction) {
      const startDate = moment(Date.now());
      const endDate = moment(expiresAt);

      return endDate.diff(startDate);
    }
  };

  const calculateLeftAnimateTime = () => {
    if (lotEndingWasChange) {
      const startDate = moment(Date.now());
      const endDate = moment(lotEndingWasChange.expires_at);
      return endDate.diff(startDate);
    }
  };

  const getAnimationStyles = () => {
    const startDate = moment(Date.now());
    const endDate = moment(expiresAt);
    const isFinishing = endDate.diff(startDate) <= auction.interval * 1000;

    return isFinishing ? 'auction_finishing' : auction.status.code;
  };

  const startDownAuctionTimer = () => {
    setDownAuctionCounter(Date.now() + (auction.interval * 1000));
  };

  const stopDownAuctionTimer = () => {
    $timerRef?.current?.stop?.();
    setDownAuctionCounter(0);
  };

  useEffect(() => {
    if (lotStarted && isDownAuction) {
      startDownAuctionTimer();
    }
  }, [lotStarted]);

  useEffect(() => {
    if (lotFinished && isDownAuction) {
      stopDownAuctionTimer();
    }
  }, [lotFinished]);

  useEffect(() => {
    if (lotDecreased) {
      setDownAuctionCounter(Date.now() + (auction.interval * 1000));
    }
  }, [lotDecreased, auction.interval]);

  useEffect(() => {
    if (downAuctionCounter) {
      $timerRef?.current?.start?.();
    }
  }, [downAuctionCounter]);

  useEffect(() => {
    if (lotEndingWasChange) {
      const startDate = moment(Date.now());
      const endDate = moment(lotEndingWasChange.expires_at);
      setChangedTime(endDate.diff(startDate));
      setAnimate(true);
    }
  }, [lotEndingWasChange]);

  const animationColor = animationStyle[getAnimationStyles()];

  const elTimer = () => {
    if (auction.is_active) {
      if (isDownAuction) {
        return (
          <>
            {!isMobile && (
              <Tooltip text="До понижения стоимости осталось...">
                <img className="timer-icon" src="/img/icons/timer-icon.svg" alt="" />
              </Tooltip>
            )}
            <div className="timer">
              <Countdown ref={$timerRef} date={downAuctionCounter} />
            </div>
          </>
        );
      }

      return (
        <>
          {!isMobile && <img className="timer-icon" src="/img/icons/timer-icon.svg" alt="" />}
          <div className="timer">
            {changedTime && lotEndingWasChange ? (
              <div>
                <div className={animate ? 'y current-timer' : 'y'}>
                  <Countdown date={Date.now() + calculateLeftAnimateTime()} />
                </div>
                <div className={animate ? 'x changed-timer' : 'x'}>
                  <Countdown date={Date.now() + calculateTimeLeft()} />
                </div>
              </div>
            ) : (
              <Countdown date={Date.now() + calculateTimeLeft()} />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="radar">
      <div
        className={clsx(
          'position-relative animation-circle',
          `animation-${animationColor}`,
        )}
      >
        <div
          className={clsx(
            'animation-container',
            `animation-${animationColor}`,
            `shadow-${animationColor}`,
          )}
        />
      </div>
      {elTimer()}
    </div>
  );
});
