import { makeFiltersList } from '@utils/filters/filter-constants';

import { DELETE, GET, POST, PUT } from './request';

const defaultAuctionParams = {
  'expand[0]': 'lots.sales.car.brand',
  'expand[1]': 'lots.sales.car.model',
  'expand[2]': 'sellerable',
  'expand[3]': 'lots.sales.city',
  'expand[4]': 'status',
  'expand[5]': 'lots.sales.preview.file',
  'expand[6]': 'lots.sales.car.transmission',
  'expand[7]': 'lots.userAutoBet',
  'expand[8]': 'responsible',
  'expand[9]': 'lots.sales.statusReportFile',
  'expand[10]': 'lots.sales.status',
  'expand[11]': 'lots.status',
  'expand[12]': 'lots.winningBet',
  'expand[13]': 'lots.statusHistory',
};

const exchangeParams = (pagination) => {
  const { page, per_page } = pagination;
  return {
    'expand[0]': 'lots.sale.car',
    'expand[1]': 'lots.sale.car.transmission',
    'expand[2]': 'lots.sale.city',
    'expand[3]': 'lots',
    'expand[4]': 'lots.sale.car.brand',
    'expand[5]': 'lots.sale.car.body',
    'expand[6]': 'lots.sale.car.engine',
    'expand[7]': 'lots.sale.preview.file',
    'expand[8]': 'sellerable',
    'orders[created_at]': 'desc',
    'page': page || 1,
    'per_page': per_page || 25,
  };
};

export const getFiltersValues = (filters) => {
  const params = {};

  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      params[`filters[${(key === 'start_at') ? `>${key}` : key}]`] = value;
    }
  }

  return params;
};

export const getAuctionsList = (params, endpointUrl = null, filters) => {
  let newParams = {
    ...defaultAuctionParams,
    ...(params || {}),
  };

  if (filters) {
    newParams = makeFiltersList(filters, { ...defaultAuctionParams, ...(params || {}) });
  }

  return GET(endpointUrl || 'auctions', newParams);
};

export const getPageAuctionsRequest = (params) => {
  const newParams = {
    'expand[0]': 'status',
    'expand[1]': 'sellerable',
    'expand[2]': 'lots',
    'expand[3]': 'responsible',
    ...(params || {}),
  };

  return GET('auctions', newParams);
};

export const getAuction = (auction_id) => {
  return GET(`auctions/${auction_id}`, defaultAuctionParams);
};

export const getAuctionLotsSales = (auctionId, lotId, saleId) => {
  const newParams = {
    'expand[0]': 'owner',
    'expand[1]': 'color',
    'expand[2]': 'car.body',
    'expand[3]': 'car.brand',
    'expand[4]': 'car.engine',
    'expand[5]': 'status',
    'expand[6]': 'preview.file',
    'expand[7]': 'car.model',
    'expand[8]': 'city',
    'expand[9]': 'car.transmission',
    'expand[10]': 'car.type',
    'expand[11]': 'location',
    'expand[12]': 'area',
    'expand[13]': 'lastClosedStatusReport',
    'expand[14]': 'inspectionType',
    'expand[15]': 'statusReportFile',
  };

  return GET(`auctions/${auctionId}/lots/${lotId}/sales/${saleId}`, newParams);
};

export const betAuctionRequest = (auctionId, lotId, sum) => {
  return POST(`auctions/${auctionId}/lots/${lotId}/bet`, { sum });
};

export const deleteBetAuctionRequest = (auctionId, lotId) => {
  return DELETE(`auctions/${auctionId}/lots/${lotId}/bet`);
};

export const createAuctionRequest = (data) => {
  return POST('auctions', data, { 'expand[0]': 'sellerable', 'expand[1]': 'status' });
};

export const getAuctionsTypesRequest = () => {
  return GET('auctions/types');
};

export const editAuctionRequest = (id, data) => {
  return PUT(`auctions/${id}`, data, { 'expand[0]': 'sellerable' });
};

export const postGenerateAuctionLinkRequest = (data) => {
  return POST('temporary-register-links', data);
};

export const postLotRequest = (data, auction_id) => {
  return POST(`auctions/${auction_id}/lots`, data);
};

export const removeLotRequest = (auction_id, lot_id) => {
  return DELETE(`auctions/${auction_id}/lots/${lot_id}`);
};

export const auctionToFavoriteRequest = (auctionId, lotId) => {
  return POST(`auctions/${auctionId}/lots/${lotId}/favourite`);
};

export const auctionRemoveFavoriteRequest = (auctionId, lotId) => {
  return DELETE(`auctions/${auctionId}/lots/${lotId}/favourite`);
};

export const getFavoriteLotsRequest = (params) => {
  const newParams = {
    'expand[0]': 'lots.sales.car.brand',
    'expand[1]': 'lots.sales.car.model',
    'expand[2]': 'sellerable',
    'expand[3]': 'lots.sales.city',
    'expand[4]': 'status',
    'expand[5]': 'lots.sales.preview.file',
    ...params,
  };

  return GET('lots/favourites', newParams);
};

export const getSingleAuctionRequest = (auction_id) => {
  const params = {
    'expand[0]': 'lots.winningBet.user',
    'expand[1]': 'lots.sales.city',
    'expand[2]': 'lots.status',
    'expand[3]': 'lots.sales.car.brand',
    'expand[4]': 'lots.sales.car.model',
    'expand[5]': 'lots.sales.car.type',
    'expand[6]': 'status',
    'expand[7]': 'lots.autoBets',
    'expand[8]': 'lots.autoBets.user',
    'expand[9]': 'lots.autoBets.company',
  };

  return GET(`auctions/${auction_id}`, params);
};

export const getEditFieldAuction = (id, action) => {
  return POST('context/auction/allowed-fields', { id, 'action': action });
};

export const getEditFieldLot = (id, action) => {
  return POST('context/lot/allowed-fields', { 'action': action, 'relations': { 'auction': id } });
};

export const getAuctionsTransitions = () => {
  return GET('auctions/transitions');
};

export const getAuctionsLotsTransitions = () => {
  return GET('auctions/lots/transitions');
};

export const postChangeAuctionTransition = (auctionId, to_status) => {
  return POST(`auctions/${auctionId}/transition`, { to_status });
};

export const postChangeAuctionLotTransition = (auctionId, lotId, to_status) => {
  return POST(`auctions/${auctionId}/lots/${lotId}/transition`, { to_status });
};

export const getAuctionBetsStepsRequest = (auction_id, lot_id, params) => {
  return GET(`auctions/${auction_id}/lots/${lot_id}/bets?expand[0]=user&expand[1]=company`, params);
};

export const postAutoBet = (auction_id, lot_id, data) => {
  return POST(`auctions/${auction_id}/lots/${lot_id}/auto_bet`, data);
};

export const postSetNewWinning = (auction_id, lot_id, data) => {
  return POST(`auctions/${auction_id}/lots/${lot_id}/set_new_winning`, data);
};

export const getAutoBet = (auction_id, lot_id) => {
  return GET(`auctions/${auction_id}/lots/${lot_id}/auto_bet`);
};

export const postMassLot = (auctionId, data) => {
  return POST(`auctions/${auctionId}/alloc-lots`, data);
};

export const saveLotExchangeRequest = (data) => {
  return POST('exchange-offers', data);
};

export const putEditExchangeOffer = (id, data) => {
  return PUT(`exchange-offers/${id}`, data);
};

export const postAddExchangeLot = (id, data) => {
  return POST(`exchange-offers/${id}/lots?expand[0]=sale.car&expand[1]=sale.city&expand[2]=sale.car.brand`, data);
};

export const getExchangeRequest = (pagination, filters) => {
  const params = (filters) ? makeFiltersList(filters, exchangeParams(pagination)) : exchangeParams(pagination);
  return GET('exchange-offers', params);
};

export const getExchangeOffer = (id) => {
  const params = {
    'expand[0]': 'sellerable',
    'expand[1]': 'lots',
    'expand[2]': 'lots.sale',
    'expand[3]': 'lots.sale.car',
    'expand[4]': 'lots.sale.car.brand',
    'expand[5]': 'lots.sale.car.body',
    'expand[6]': 'lots.sale.car.transmission',
    'expand[7]': 'lots.sale.city',
    'expand[8]': 'lots.sale.status',
    'expand[9]': 'lots.sale.car.type',
    'expand[10]': 'lots.sale.car.model',
    'expand[11]': 'lots.reservedBy',
  };
  return GET(`exchange-offers/${id}`, params);
};

export const changeExchangeOfferLotStatus = (auctionId, lotId, approve) => {
  return POST(`exchange-offers/${auctionId}/lots/${lotId}/approve`, { approve });
};

export const postReserveLot = (id, lotId) => {
  return POST(`exchange-offers/${id}/lots/${lotId}/reserve`);
};

export const getRequisitesSearch = (params) => {
  return GET('requisites/search', params);
};

export const deleteAutoBet = (auction_id, lot_id) => {
  return DELETE(`auctions/${auction_id}/lots/${lot_id}/auto_bet`);
};

export const putApproveLot = (auctionId, lotId, approve) => {
  return POST(`auctions/${auctionId}/lots/${lotId}/approve`, { approve });
};

export const getAuctionReports = (id, params) => {
  return GET(`auctions/${id}/reports?expand[0]=createdBy&expand[1]=file`, params);
};

export const postAuctionsLotsByVin = (data) => {
  return POST('auctions/lots/waiting-approval?expand[0]=sales.car&expand[1]=sales.car.body&expand[2]=sales.city&expand[3]=sales.car.brand&expand[4]=sales.car.model&expand[5]=sales.car.engine', data);
};

export const postAuctionsLotsApprove = (data) => {
  return POST('auctions/lots/approve', data);
};

export const postBlitzBet = (auction_id, lot_id) => {
  return POST(`auctions/${auction_id}/lots/${lot_id}/blitz-bet`);
};
