import axios from 'axios';

import { NO_AUTH_URL } from '@utils/common';

import { API_URL_V1 } from './config';

const axiosInstance = (config, skipRedirect401) => {
  return axios(config)
    .catch((error) => {
      if (!skipRedirect401 && error.response?.status === 401) {
        window.location = NO_AUTH_URL;
      }

      return error.response?.data ? { ...error.response?.data, status: error.response?.status } : error;
    });
};

export async function getFileLink(action = '', fileType = '') {
  return axiosInstance({ url: API_URL_V1 + action, responseType: 'blob', withCredentials: true, method: 'GET' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers['content-disposition'].split('filename="')[1].split('.')[0];

      return { url, download: `${fileName}.${fileType}` };
    });
}

export const Send = (url = '', method, data = {}, isFile = false, params = {}, allowCredentials = true, headers) => {
  const config = {
    method,
    url: API_URL_V1 + url,
    params,
    headers: headers || (isFile
      ? { }
      : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
    data: isFile ? data : JSON.stringify(data),
    withCredentials: allowCredentials, // разрешить передавать заголовки в запросе, включая куки
  };

  return axiosInstance(config)
    .then((resp) => {
      return resp.data || resp;
    });
};

export const request = (url, method, requestConfig, additionalOptions) => {
  const config = {
    method,
    url: API_URL_V1 + url,
    ...requestConfig,
  };

  return axiosInstance(config, additionalOptions?.skipRedirect401)
    .then((resp) => {
      return resp.data || resp;
    });
};

export const GET = (url, params) => {
  return Send(url, 'get', undefined, false, params);
};

export const POST = (url, data, params, config) => {
  return request(url, 'post', { ...config, data, params });
};
export const PUT = (url, data, params) => {
  return Send(url, 'put', data, false, params);
};

export const DELETE = (url, data, params) => {
  return Send(url, 'delete', data, false, params);
};
