import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@components/button/button';
import { auctionStore } from '@mobx/auction';
import { deleteUserRequest, getUsersWithAccesses } from '@api/users';

import { AccessUsersAndGroupsModal } from './users-and-groups/access-users-and-groups-modal';

import './access-rights.scss';

export const AccessRights = observer((props) => {
  const { auction } = auctionStore;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalTypeIsAccess, setModalTypeIsAccess] = useState(null);
  const [accessList, setAccessList] = useState([]);
  const [error, setError] = useState();

  const requestChangeAccessList = () => {
    getUsersWithAccesses(auction.id).then((resp) => {
      if (resp.message) {
        setError(resp.errors || resp.message);

        setTimeout(() => setError(null), 5000);
      } else {
        setAccessList(resp);
      }
    });
  };

  useEffect(() => {
    if (auction) {
      requestChangeAccessList();
    }
  }, []);

  const getFilteredUsersAndGroups = (type, isAccess) => {
    return accessList.reduce((acc, item) => {
      if (item.owner.includes(type)) {
        if ((item.has_permission && isAccess) || (!isAccess && !item.has_permission)) {
          acc.push(item.owner_model.id);
        }
      }

      return acc;
    }, []);
  };

  const onDeleteUserOrGroup = (item) => {
    if (item?.owner?.includes('U_')) {
      deleteUserRequest({ accesses: [{ owner_type: 'user', owner_id: item.owner_model?.id }] }, item.auction_id)
        .then(() => setAccessList((resp) => resp.filter((user) => user !== item)));
    } else if (item?.owner?.includes('G_')) {
      deleteUserRequest({ accesses: [{ owner_type: 'group', owner_id: item.owner_model?.id }] }, item.auction_id)
        .then(() => setAccessList((resp) => resp.filter((user) => user !== item)));
    }
  };

  const elContent = (isAccess) => {
    const itemsList = accessList.filter((item) => ((item.has_permission && isAccess) || (!isAccess && !item.has_permission)));

    return (
      <div className="mt-30px">
        <div className="access-users-wrapper__header">
          <div className="d-flex flex-column">
            <span className="bold-text">
              {isAccess ? 'Пользователи и группы с доступом' : 'Пользователи и группы без доступа'}
            </span>
            <span className="info-name">
              {isAccess ? 'Смогут участвовать в аукционе' : 'Не увидят аукцион и не смогут участвовать'}
            </span>
          </div>
          <div className="d-grid gap-5px justify-content-end w-70">
            <Button
              size="s"
              className="d-flex align-items-center gap-5px"
              onClick={() => {
                setShowModal(true);
                setModalTypeIsAccess(isAccess);
              }}
              preset={isAccess ? 'primary' : 'secondary'}
            >
              {isAccess ? <img src="/img/icons/plus-icon.svg" alt="" /> : <img src="/img/icons/blue-plus-icon.svg" alt="" />}
              Добавить
            </Button>
          </div>
        </div>
        {itemsList?.length ? (
          <div className={`access-users-wrapper__table access-users-wrapper__table_${isAccess ? 'access' : 'block'}`}>
            <div className="access-users-wrapper__table-th">ФИО или группа</div>
            {itemsList.map((item) => {
              return (
                <div
                  key={`${item?.owner}-${item?.owner_model?.id}`}
                  className="access-users-wrapper__table-td"
                >
                  <div className="d-flex align-items-center gap-5px">
                    {isAccess ? (
                      <img
                        src={`/img/icons/table-icon-${item?.owner?.includes('U_') ? 'user' : 'group'}.svg`}
                        alt=""
                      />
                    ) : (
                      <img
                        src={`/img/icons/table-deleted-icon-${item?.owner?.includes('U_') ? 'user' : 'group'}.svg`}
                        alt=""
                      />
                    )}
                    <Link
                      to={`/${item?.owner?.includes('G_') ? 'user-group' : 'user'}/${item.owner_model?.id}`}
                      className="link-user-or-group"
                    >
                      {item?.owner?.includes('G_') ? item?.owner_model?.name : item?.owner_model?.full_name}
                    </Link>
                  </div>
                  <img
                    className="cursor-pointer"
                    src="/img/icons/cancel-icon.svg"
                    onClick={() => onDeleteUserOrGroup(item)}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="hint info-name mt-20px">{isAccess === false && 'Здесь можно указать тех, кто входит в одну из групп слева, но не должен иметь доступ к аукциону'}</div>
        )}
        {showModal && modalTypeIsAccess === isAccess && (
          <AccessUsersAndGroupsModal
            show={true}
            onHide={() => setShowModal(false)}
            auctionId={auction?.id}
            onChange={requestChangeAccessList}
            selectedUsers={getFilteredUsersAndGroups('U_', isAccess)}
            selectedGroups={getFilteredUsersAndGroups('G_', isAccess)}
            isAccess={isAccess}
          />
        )}
        {Boolean(error) && modalTypeIsAccess === isAccess && (
          <div className="mt-2 mb-2 alert alert-danger">{error}</div>
        )}
      </div>
    );
  };

  const getElEmptyContent = () => {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mt-20px">
          <div>
            <div className="row-text">Пользователи с доступом</div>
            <div className="info-name">Смогут участвовать в аукционе</div>
          </div>
          <Button
            onClick={() => {
              setShowModal(true);
              setModalTypeIsAccess(true);
            }}
            preset="primary"
            size="s"
          >
            <img src="/img/icons/plus-icon.svg" alt="" />
            Добавить
          </Button>
        </div>
        <div className="access-rights-wrapper__body mt-20px">
          <div className="message-wrapper">
            <div className="title-name">Аукцион пока никому не доступен</div>
            <div className="message-name">Добавьте пользователей, которые смогут просматривать аукцион и участвовать в нём</div>
          </div>
        </div>
        {showModal && (
          <AccessUsersAndGroupsModal
            show={true}
            onHide={() => setShowModal(false)}
            auctionId={auction?.id}
            onChange={requestChangeAccessList}
            selectedUsers={getFilteredUsersAndGroups('U_', true)}
            selectedGroups={getFilteredUsersAndGroups('G_', true)}
            isAccess={true}
          />
        )}
      </div>
    );
  };

  return (
    <div className="access-rights-wrapper">
      <div className="access-users-wrapper">
        {accessList.length > 0 && elContent(true)}
        {accessList.length > 0 && elContent(false)}
      </div>
      {!accessList.length && getElEmptyContent()}
      <div>
        <div className="btn-footer mt-30px">
          <Button
            preset="secondary"
            className="d-flex align-items-center gap-10px"
            onClick={() => props.onStepChange(true)}
          >
            <img src="/img/icons/arrow-left-icon.svg" alt="" />
            Назад
          </Button>
          <Button
            preset="primary"
            className="d-flex align-items-center gap-10px"
            onClick={() => navigate(`/auction/${auction?.id}`)}
          >
            <img src="/img/icons/tick-icon.svg" alt="" />
            Сохранить аукцион
          </Button>
        </div>
      </div>
    </div>
  );
});
