import React, { forwardRef } from 'react';
import clsx from 'clsx';

import './input.scss';

export const Input = forwardRef((props, ref) => {
  const { className, type, size = 'l', color, autoComplete = 'off', ...rest } = props;

  return (
    <input
      autoComplete={autoComplete}
      type={type || 'text'}
      className={clsx('input', `input_${size}`, color && `input_${color}`, className)}
      ref={ref}
      {...rest}
    />
  );
});
