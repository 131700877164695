import React, { useEffect, useMemo, useState } from 'react';

import { getPhotoSrc } from '@utils/photo';
import { getCurrentTireSets } from '@api/cars';
import { Gallery } from '@components/gallery/gallery';

export const Photos = ({ carSaleInfo, defects, isImported, id }) => {
  const [tires, setTires] = useState([]);
  const [startIndex, setStartIndex] = useState();
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    getCurrentTireSets(id)
      .then((resp) => setTires(resp));
  }, []);

  const tiresPhotos = useMemo(() => {
    const result = [];
    tires?.forEach((tire) => {
      tire?.tires?.forEach((item) => {
        item.photos?.forEach((photo) => {
          result.push({
            original: photo.thumbnails_urls?.middle || photo.file.url,
            thumbnail: photo.thumbnails_urls?.small,
            description: `Колесо ${item.dimensions}`,
          });
        });
      });
    });

    return result;
  }, [tires]);

  const mainPhotos = useMemo(() => {
    const result = [];
    if (carSaleInfo?.photos) {
      carSaleInfo.photos.forEach((photos, index) => {
        if (photos?.photos?.length > 0) {
          photos?.photos?.forEach((photo) => {
            result?.push({
              original: photo.thumbnails_urls?.middle || photo.file.url,
              thumbnail: photo.thumbnails_urls?.small,
              description: photos.name,
            });
          });
        }
      });
    }

    return result;
  }, [carSaleInfo]);

  const defectPhotos = useMemo(() => {
    const photos = [];
    if (isImported) {
      if (defects?.imported_children?.length) {
        defects?.imported_children?.forEach((child) => {
          const func = ((child1) => {
            child1?.imported_defects?.forEach((defects) => {
              if (defects?.photos?.length > 0) {
                defects?.photos?.forEach((photo) => {
                  photos?.push({
                    original: photo.thumbnails_urls?.middle || photo.file.url,
                    thumbnail: photo.thumbnails_urls?.small,
                    description: `${child.name}, ${defects.element_name}, ${defects.defect}`,
                  });
                });
              }
            });
          });

          func(child);
          child?.imported_children?.forEach(func);
        });
      }

      return photos;
    }
    defects?.full_childs?.forEach((child) => {
      child?.full_childs?.forEach((child1) => {
        if (child1.status_report_elements?.length) {
          child1.status_report_elements.forEach((element) => {
            if (element.status_report_values?.length) {
              element.status_report_values.forEach((damageItem) => {
                if (damageItem.photos?.length) {
                  damageItem.photos?.forEach((photo) => {
                    photos.push({
                      original: photo.thumbnails_urls?.middle || photo.file.url,
                      thumbnail: photo.thumbnails_urls?.small,
                      description: `${child1.name}, ${element.name}, ${damageItem.defect?.name || ''}`,
                    });
                  });
                }
              });
            }
          });
        }
      });
    });

    return photos;
  }, [defects]);

  const photos = useMemo(() => {
    return [...mainPhotos, ...defectPhotos, ...tiresPhotos];
  }, [mainPhotos, defectPhotos, tiresPhotos]);

  return (
    <div className="section car-photos" id="photos">
      <div className="title">Фотографии</div>
      <div className="car-photos__group">
        {photos?.length > 0 ? (
          photos.map((photo, index) => {
            return (
              <span
                className="cursor-pointer"
                key={`main-photo-${index}`}
                onClick={() => {
                  setStartIndex(index);
                  setShowGallery(true);
                }}
              >
                <img
                  className="car-sales__car-image"
                  width="360px"
                  height="240"
                  src={getPhotoSrc(photo.original)}
                  alt="car"
                />
              </span>
            );
          })) : 'Нет фотографий'}
      </div>
      {showGallery && (
        <Gallery
          startIndex={startIndex}
          showPlayButton={false}
          items={photos}
          onClose={() => setShowGallery(false)}
          isWithOverlay={true}
          showFullscreenButton={true}
          showThumbnails={true}
        />
      )}
    </div>
  );
};
