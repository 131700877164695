import { GET, POST, Send, DELETE } from './request';

const defaultParams = {
  'expand[0]': 'status',
  'expand[1]': 'roles',
  'expand[2]': 'city',
  'expand[3]': 'legalForm',
};

export const getUsersList = (params) => {
  const newParams = {
    ...defaultParams,
    ...params,
  };

  return GET('users', newParams);
};

const getUserData = (data) => {
  const newData = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      if (key === 'requisite') {
        const requisite = data[key];

        Object.keys(requisite).forEach((reqKey) => {
          if (requisite[reqKey] || reqKey === 'need_deposit') {
            newData.append(`requisite[${reqKey}]`, requisite[reqKey]);
          }
          if (reqKey === 'agent_contract' && requisite[reqKey] === null) {
            newData.append(`requisite[${reqKey}]`, '');
          }
        });
      } else if (key === 'roles') {
        if (data.roles.length) {
          data.roles.forEach((item) => {
            newData.append('roles[]', item);
          });
        } else {
          newData.append('roles[]', []);
        }
      } else {
        newData.append(key, data[key]);
      }
    }
  });

  return newData;
};

export const userCreate = (data) => {
  const newData = getUserData(data);

  return Send('users', 'POST', newData, true, undefined, true, { 'content-type': 'multipart/form-data' });
};

export const userUpdateRequest = (id, data) => {
  const newData = getUserData(data);

  return Send(`users/${id}`, 'POST', newData, true, { _method: 'PUT' }, true, { 'content-type': 'multipart/form-data' });
};

export const filteredUsers = ({ city_id, first_name, middle_name, last_name, created_at, status_id, gt_last_active_at, email, email_verified }, params) => {
  const newParams = { ...defaultParams, ...(params || {}) };

  if (city_id) newParams['filters[city_id]'] = city_id;
  if (first_name) newParams['filters[first_name]'] = first_name;
  if (middle_name) newParams['filters[middle_name]'] = middle_name;
  if (last_name) newParams['filters[last_name]'] = last_name;
  if (created_at) newParams['filters[>created_at]'] = created_at;
  if (status_id) newParams['filters[status_id]'] = status_id;
  if (gt_last_active_at) newParams['filters[gt_last_active_at]'] = gt_last_active_at;
  if (email) newParams['filters[email]'] = email;
  if (email_verified) newParams['filters[email_verified]'] = email_verified;

  return GET('users', newParams);
};

export const getUserById = (id) => {
  const params = {
    'expand[0]': 'status',
    'expand[1]': 'roles',
    'expand[2]': 'city',
    'expand[3]': 'responsible',
    'expand[4]': 'companies',
    'expand[5]': 'requisite',
    'expand[6]': 'requisite.agentContract',
    'expand[7]': 'approveRequests',
    'expand[8]': 'legalForm',
  };

  return GET(`users/${id}`, params);
};

export const getUsersWithAccesses = (auction_id) => {
  return GET(`auctions/${auction_id}/acl`);
};

export const setAccessUsersRequest = (data, auction_id) => {
  return POST(`auctions/${auction_id}/acl`, data);
};

export const deleteUserRequest = (data, auction_id) => {
  return DELETE(`auctions/${auction_id}/acl`, data);
};

export const blockUsersRequest = (ids) => {
  return POST('users/lock', { users: ids });
};

export const unlockUsers = (ids) => {
  return POST('users/unlock', { users: ids });
};

export const getTechSpecialists = () => {
  return GET('users?filters[roles][0]=tech-specialist');
};

export const postChangeUsersPassword = (userId, password) => {
  return POST(`users/${userId}/change-password`, { password });
};

export const postChangeProfilePassword = (password) => {
  return POST('auth/password/change', { password });
};

export const postActivatePromo = (code) => {
  return POST(`temporary-register-links/${code}/activate`);
};

export const postUserApprove = (params) => {
  return POST('users/approve', params);
};

export const getRequisitesTemplates = () => {
  return GET('requisites/templates');
};
