import React from 'react';
import { Link } from 'react-router-dom';

const COLUMNS = [
  {
    dataField: 'name',
    text: 'Название',
    formatter: (cell, row) => (
      <Link to={`/car/${row.id}`}>
        {row.car.brand?.name} {row.car.model?.name} {row.car.year ? `${row.car.year} г.в.` : ''} {row.car.engine_volume} {row.car.transmission?.name}
      </Link>
    ),
  },
  {
    dataField: 'seller',
    text: 'Продавец',
    formatter: (cell, row) => (
      <a
        href={`/${row.owner?.full_name ? 'user' : 'company'}/${row.owner?.id}`}
        target="_blank"
        rel="noreferrer"
      >
        {row.owner?.display_name}
      </a>
    ),
  },
  {
    dataField: 'vin',
    text: 'VIN',
    formatter: (cell, row) => row.car.vin,
  },
  {
    dataField: 'reg_number',
    text: 'Гос. номер',
    formatter: (cell, row) => row.reg_number,
  },
  {
    dataField: 'status',
    text: 'Статус',
    formatter: (cell, row) => row.status.name,
  },
];

export const getColumns = (hasPermission) => {
  const columns = [...COLUMNS];

  if (hasPermission) {
    columns.push({
      dataField: 'buyer',
      text: 'Покупатель ',
      formatter: (cell, row) => (
        <Link to={`/user/${row?.buyer_id}`}>
          {row.buyer?.full_name}
        </Link>
      ),
    });
  }
  return columns;
};
