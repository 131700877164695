import { compact } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Panel } from 'rsuite';
import { observer } from 'mobx-react-lite';

import { getCarSalePhotos, postAddCarSalePhoto, removeCarSalePhoto } from '@api/cars';
import { Loader } from '@components/loader/loader';
import { dictionariesStore } from '@mobx/dictionaries';

import { PhotoField } from './components/photo-field';
import { mainPhotos } from './utils';
import { AdditionalPhoto } from './components/additional-photo';

import './car-form-components.scss';

export const Photos = observer(({ onStepChange, edit, carData, showConfirmPopup, onConfirmChangeStep }) => {
  const id = carData?.id;

  const photoTypes = dictionariesStore.dictionaries.photo_types;
  const [loading, setLoading] = useState(edit);
  const [defaultValues, setDefaultValues] = useState({});
  const [photos, setPhotos] = useState([]);
  const initialPhotos = photos.find((photo) => photo.name === 'Дополнительно');
  const [savedAdditionalPhotos, setSavedAdditionalPhotos] = useState(initialPhotos?.photos);
  const [error, setError] = useState();

  useEffect(() => {
    getCarSalePhotos(id).then(setPhotos);
  }, [id]);

  const defaultInputs = useMemo(() => compact(
    (photoTypes || []).map((photo) => {
      const mainPhoto = mainPhotos.find((mainPhoto) => mainPhoto.name === photo.label);

      return mainPhoto ? { ...photo, ...mainPhoto, type: 'file' } : null;
    }),
  ), [photoTypes]);

  useEffect(() => {
    if (!photoTypes) {
      dictionariesStore.request('photo_types', { params: { 'orders[order]': 'asc' } });
    }
  }, []);

  useEffect(() => {
    if (showConfirmPopup) {
      onConfirmChangeStep();
    }
  }, [showConfirmPopup]);

  useEffect(() => {
    if (photos.length) {
      const newDefaultValues = {};

      photos.forEach((photoObj) => {
        newDefaultValues[photoObj.id] = photoObj.photos.length ? photoObj.photos[photoObj.photos.length - 1] : undefined;
      });

      setDefaultValues(newDefaultValues);
      setLoading(false);

      setSavedAdditionalPhotos(initialPhotos?.photos);
    }
  }, [JSON.stringify(photos), edit]);

  const handleChangeFile = (e) => {
    const files = Array.from(e.target.files);
    let successCounter = 0;

    files.forEach((file, index) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        ++successCounter;
        onSaveRequest(file, index, initialPhotos?.id);

        if (successCounter === files.length) {
          e.target.value = '';
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onDeletePhoto = (photoId) => {
    removeCarSalePhoto(id, [photoId]).then(setPhotos);
  };

  const onDeleteSavedAdditionalPhoto = (photoId) => {
    removeCarSalePhoto(id, [photoId]).then((resp) => {
      if (!resp?.message) {
        setSavedAdditionalPhotos((prev) => prev.filter((item) => item.id !== photoId));
      }
    });
  };

  const onSaveRequest = async (file, index, key) => {
    const formData = new FormData();

    if (!key || !file) return;

    formData.append(`photos[${index}][type]`, key);
    formData.append(`photos[${index}][file]`, file);

    const response = await postAddCarSalePhoto(id, formData);

    if (!response?.errors) {
      setPhotos(response);
    } else {
      setError(response.errors);

      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  return (
    <div>
      <div className="title mb-3">Добавление фотографий автомобиля</div>
      {loading ? <Loader />
        : (
          <>
            <Panel className="all-info-wrapper mt-3">
              <div className="d-flex align-items-center flex-wrap gap-3">
                {defaultInputs.map((photo, index) => {
                  return (
                    <PhotoField
                      photo={photo}
                      key={`photo-field-${index}`}
                      name={photo.value}
                      multiple={false}
                      defaultValue={defaultValues[photo.value]}
                      onDelete={onDeletePhoto}
                      onSaveRequest={onSaveRequest}
                      index={index}
                    />
                  );
                })}
              </div>
              <div className="title mb-2 mt-20px">Загрузите дополнительные фотографии</div>
              <div className="d-grid gap-20px">
                <div className="d-flex align-items-center flex-wrap gap-3">
                  {savedAdditionalPhotos?.map((fileObj, index) => {
                    return (
                      <AdditionalPhoto
                        src={fileObj.file?.url}
                        name={fileObj.file?.name_original}
                        size={fileObj.file?.size}
                        key={`${fileObj.name}-${index}`}
                        id={fileObj.id}
                        onDelete={onDeleteSavedAdditionalPhoto}
                      />
                    );
                  })}
                </div>
                <div className="d-flex align-items-center position-relative">
                  <div className="form-wrapper w-100">
                    <div className="form-group mb-4">
                      <label htmlFor="upload-photo" className="file-wrapper w-100 mb-1">
                        <div className="dashed-border d-flex align-items-center">
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="plus">+</div>
                            <div className="text">
                              Перетащите файлы или <br /> выберите вручную
                            </div>
                          </div>
                        </div>
                      </label>
                      <div className="input-wrapper">
                        <input
                          type="file"
                          multiple={true}
                          name="img"
                          id="upload-photo"
                          onChange={handleChangeFile}
                          className="form-control"
                          placeholder="Фотография"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            {error && (
              <div className="mt-3 mb-3 alert alert-danger break-word">{JSON.stringify(error, null, ' ')}</div>
            )}
            <div className="mt-3 d-flex">
              <button className="btn btn-primary flex-grow-1" type="button" onClick={onStepChange}>
                Следующий шаг
              </button>
              <span className="flex-grow-1" />
            </div>
          </>
        )}
    </div>
  );
});
