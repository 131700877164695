import React, { useState } from 'react';

import { bytesToMegaBytes } from '../utils';

export const DocumentField = (props) => {
  const { defaultValue, name, label } = props;

  const [file, setFile] = useState(
    defaultValue ? {
      size: defaultValue.size,
      name: defaultValue.original_name,
      documentId: defaultValue.document_id,
    } : undefined,
  );

  return (
    <div className="info-field-wrapper document">
      <div className="label document-field__label">{label}</div>
      {file ? (
        <div className="loaded-file d-flex align-items-center">
          <div className="name">{file.name}</div>
          <div className="size">{bytesToMegaBytes(file.size)} MB</div>
          <img
            className="cursor-pointer"
            onClick={() => {
              if (defaultValue?.id) {
                props.onDelete?.(defaultValue?.id, file);
              }

              setFile(null);
            }}
            src="/img/icons/remove-icon.svg"
            alt=""
          />
        </div>
      ) : (
        <label htmlFor={name} className="upload-document">
          <input
            onChange={(e) => {
              const newFile = e.target.files[0];

              if (newFile) {
                setFile({ size: newFile.size, name: newFile.name });
                props.onSave?.(newFile, name, 0);
                e.target.value = '';
              }
            }}
            id={name}
            name={name}
            type="file"
            className="document-field__upload-input"
          />
          <div className="dashed-border d-flex justify-content-center align-items-center upload-document">
            <div className="upload-text">
              <div className="upload-plus">+</div>
              <span>
                Выберите файл
              </span>
            </div>
          </div>
        </label>
      )}
    </div>

  );
};
