import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Pagination as Pages } from 'react-bootstrap';

import './pagination.scss';

export const Pagination = ({ data, onChange, onChangePage, onChangePerPage, perPageList, className, hideInfo }) => {
  const pages = useMemo(() => {
    const result = [];

    if (data) {
      const count = Math.ceil(data.total / data.per_page);

      for (let i = 1; i <= count; i += 1) {
        result.push(i);
      }
    }

    return result;
  }, [data?.total, data?.per_page]);

  if (!data) {
    return null;
  }

  return (
    <div className={clsx('pagination-wrapper', className)}>
      {!hideInfo && (
        <div className="result">
          Результат с {data.from} по {data.to} из {data.total}
        </div>
      )}
      <Pages>
        <Pages.Prev
          className="page-arrow"
          onClick={() => {
            onChange?.({ page: data.current_page - 1, per_page: data.per_page });
            onChangePage?.(data.current_page - 1);
          }}
          disabled={data.current_page === 1}
        />
        {pages.map((index) => (
          <Pages.Item
            disabled={data.per_page > data.total}
            className={data.current_page === index ? 'active-page' : ''}
            key={index}
            onClick={() => {
              onChange?.({ page: index, per_page: data.per_page });
              onChangePage?.(index);
            }}
          >
            {index}
          </Pages.Item>
        ))}
        <Pages.Next
          className="page-arrow"
          disabled={data.current_page === data.last_page}
          onClick={() => {
            onChange?.({ page: data.current_page + 1, per_page: data.per_page });
            onChangePage?.(data.current_page + 1);
          }}
        />
      </Pages>
      {perPageList?.length > 0 && (
        <select
          onChange={(e) => {
            onChange?.({ page: 1, per_page: e.target.value });
            onChangePerPage?.(e);
          }}
          name="actions"
          className="input-light-pagination"
        >
          {perPageList.map((item, index) => {
            return <option value={item} key={`per-page-${index}`}>{item}</option>;
          })}
        </select>
      )}
    </div>
  );
};
