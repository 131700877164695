import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { Steps } from '@components/steps/steps';
import { auctionStore } from '@mobx/auction';
import { postGenerateAuctionLinkRequest } from '@api/auctions';

import { GeneralInformationAuction } from './create-auction-forms/general-information-auction';
import { AddAuctionLots } from './create-auction-forms/add-auction-lot/add-auction-lots';
import { AccessRights } from './create-auction-forms/access-rights/access-rights';

import './auction-form.scss';

const steps = [
  { description: 'Общая информация' },
  { description: 'Лоты' },
  { description: 'Права доступа' },
];

const components = [GeneralInformationAuction, AddAuctionLots, AccessRights];

export const AuctionForm = observer((props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [clickedStep, setClickedStep] = useState();
  const [code, setCode] = useState();
  const { auction } = auctionStore;

  useEffect(() => {
    if (props.edit) {
      auctionStore.fetch(id).then((resp) => {
        if (resp === 'success') {
          setInitialized(true);
        } else {
          navigate('/auctions');
        }
      });
    } else {
      auctionStore.setAuction(null);
      setInitialized(true);
    }
  }, []);

  const onStepChange = (isBack) => {
    setActiveStep((prev) => (isBack ? (prev - 1) : (prev + 1)));
  };

  const onStepClick = ({ index }) => {
    if (props.edit) {
      setClickedStep(index);
    }
  };

  const onConfirmChangeStep = () => {
    setActiveStep(clickedStep);
  };

  if (meStore.noPermission('admin.auctions.edit')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null || !initialized) {
    return null;
  }

  const onGenerateLink = () => {
    postGenerateAuctionLinkRequest({ auction_id: auction.id })
      .then((resp) => {
        setCode(resp.code);
      });
  };

  const renderLink = () => {
    if (auction?.active_temporary_registration_link?.code || code) {
      return (
        <span onClick={() => navigator.clipboard.writeText(`${window.location.origin}/auth/code/${auction?.active_temporary_registration_link?.code || code}`)}>
          <img
            src="/img/icons/blue-copy-icon.svg"
            alt=""
          />
          <span className="ml-5px">Копировать ссылку</span>
        </span>
      );
    }

    return (
      <span className="cursor-pointer" onClick={onGenerateLink}>
        <img
          src="/img/icons/icon-link.svg"
          alt=""
        />
        <span className="ml-5px">Создать ссылку</span>
      </span>
    );
  };

  const Component = components[activeStep];

  return (
    <div className="add-auction-wrapper">
      <Link to="/auctions" className="back-link">
        <img src="/img/icons/left-icon.svg" alt="" />
        Все аукционы
      </Link>
      <div className="d-flex align-items-center mb-3">
        <h2 className="head-text mb-0">{auction ? `Аукцион №${auction.id}` : 'Новый аукцион'}</h2>
        {auction && (
          <span className="cursor-pointer d-flex align-items-center ml-15px add-auction__copy-link">
            {renderLink()}
          </span>
        )}
      </div>
      <Steps steps={steps} step={activeStep} onStepClick={onStepClick} />
      <Component
        onStepChange={onStepChange}
        edit={props.edit}
        onConfirmChangeStep={onConfirmChangeStep}
      />
    </div>
  );
});
