import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { getCarsList, getParamsWithFilters } from '@api/cars';
import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { addPrefixes, setLocalStorageFilters } from '@utils/filters/filter-constants';
import { LotChangeView } from '@components/lot-change-view/lot-change-view';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import useMediaQuery from '@hooks/use-media-query';
import { Button } from '@components/button/button';

import { CarsItem } from '../cars-item';
import { getColumns } from '../utils';

import { CarsFilters } from './cars-filters';

import '../cars.scss';

const VIEW_TILE = 'tile';
const filterPrefix = 'car][';
const listOfPrefixedKeys = ['ids', 'vin', 'brand_id', 'type_id', 'model_id', 'year_gt', 'year_ls'];
const PER_PAGE = [25, 50, 100, 250, 500];

export const CarsTab = observer(() => {
  const isMobile = useMediaQuery();

  const [cars, setCars] = useState();
  const [view, setView] = useState();

  const localStorageFilters = JSON.parse(localStorage.getItem('carsFilters') || null);
  const localStorageParams = JSON.parse(localStorage.getItem('carsParams') || null);

  useEffect(() => {
    setView(isMobile ? VIEW_TILE : (localStorage.getItem('cars_view') || VIEW_TILE));
  }, [isMobile]);

  const columns = getColumns(meStore.hasPermission('admin.sales.view'));

  const requestCars = (params, filters) => {
    getCarsList(getParamsWithFilters(params, filters && addPrefixes(filters, listOfPrefixedKeys, filterPrefix))).then(setCars);
  };

  useEffect(() => {
    requestCars(localStorageParams || { page: 1, per_page: PER_PAGE[0] }, localStorageFilters);
  }, []);

  if (meStore.noPermission('admin.sales.view') && meStore.noPermission('sales.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null) {
    return null;
  }

  const onChangeFilters = (filters, isReset) => {
    if (isReset) {
      requestCars({ page: 1, per_page: cars?.per_page });
      localStorage.removeItem('carsFilters');
    } else {
      requestCars({ page: 1, per_page: cars?.per_page }, filters);
      setLocalStorageFilters(filters, 'carsFilters');
    }
  };

  const elEditBtn = () => {
    return (meStore.hasPermission('admin.sales.edit') || meStore.hasPermission('sales.edit')) && (
      <Button to="/car/create" size="m" className="d-flex align-items-center gap-5px">
        <img srcSet="/img/icons/plus-icon.svg" alt="" />
        {(meStore.noPermission('admin.sales.edit') && meStore.hasPermission('sales.edit'))
          ? 'Добавить'
          : 'Добавить вручную'}
      </Button>
    );
  };

  const elViewSettings = () => {
    return (
      <div className="auctions-wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            <span>Автомобили</span>
          </div>
          <div>
            {!isMobile && (
              <div className="d-flex">
                <LotChangeView setView={setView} nameView="cars_view" />
                {elEditBtn()}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="car-sales__content-head">
        <div className="d-flex justify-content-between">
          <CarsFilters onChange={onChangeFilters} isMobile={isMobile} localStorageFilters={localStorageFilters} />
          {isMobile && elEditBtn()}
        </div>
        {elViewSettings()}
      </div>
      {cars ? (
        <>
          <div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
            {view === 'tile' ? cars.data?.map((car) => <CarsItem car={car} key={`car-sale-${car.id}`} />) : (
              <Table
                data={cars.data}
                columns={columns}
              />
            )}
          </div>
          <Pagination
            data={cars}
            onChange={(params) => {
              requestCars(params, localStorageFilters);
              localStorage.setItem('carsParams', JSON.stringify(params));
            }}
            perPageList={PER_PAGE}
          />
        </>
      ) : (
        'Данных нет'
      )}
    </div>
  );
});
