import React, { useEffect, useRef } from 'react';
import Countdown from 'react-countdown';

import { VIEW_HORIZONTAL } from '@pages/current-trades/current-trades-filters/utils';
import { getCurrencySymbol } from '@utils/common';
import { Button } from '@components/button/button';
import QuestionTooltip from '@components/tooltip/tooltip-question';
import { Tooltip } from '@components/tooltip/tooltip';
import { auctionsStore } from '@mobx/auctions';
import { formatValueWithSpaces } from '@utils/format';

import { LotCars } from './lot-cars/lot-cars';

import './auction-down.scss';

export const LotCardDownAuction = (props) => {
  const { lot, auctionId, lotTimer, view, classes, isWinner, isLost, hideCars, isMobile, elFavouriteBtn } = props;
  const clockRef = useRef();

  useEffect(() => {
    if (lotTimer) {
      clockRef?.current?.start?.();
    }
  }, [lotTimer]);

  const elBetButton = () => {
    let text = 'Купить';

    if (isWinner) {
      text = 'Лот куплен';
    } else if (isLost) {
      text = 'Торги закончились';
    }

    return (
      <Button
        onClick={() => lot.is_active && auctionsStore.betAuction(auctionId, lot.id, lot.price)}
        className="auction__bet-btn"
        disabled={!lot.is_active}
      >
        {text}
      </Button>
    );
  };

  const elPrice = () => {
    return (
      <div className="d-flex align-items-center">
        <Tooltip text="Текущая стоимость" isDisabled={isMobile}>
          <div className="d-flex align-items-center last-bet-wrapper">
            <img src="/img/icons/arrow-up-bordered-icon.svg" alt="" className="auction-down__price-icon" />
            <div className="price">
              {formatValueWithSpaces(lot.price)} {getCurrencySymbol()}
            </div>
          </div>
        </Tooltip>
        {view === VIEW_HORIZONTAL ? (
          <div className="ml-5px">{lot.is_vat_included ? 'С НДС' : 'Без НДС'}</div>
        ) : (
          <QuestionTooltip text={lot.is_vat_included ? 'Цена с НДС' : 'Цена без НДС'} />
        )}
      </div>
    );
  };

  const elBet = () => {
    return (
      <Tooltip text="Шаг понижения" isDisabled={isMobile}>
        <div className="d-flex align-items-center auction-down__decrease-step">
          <img src="/img/icons/bid-down.svg" alt="" />
          <div className="minimal-price">
            {formatValueWithSpaces(lot.minimal_step)} {getCurrencySymbol()}
          </div>
        </div>
      </Tooltip>
    );
  };

  const elTop = () => {
    if (lotTimer) {
      return (
        <>
          <div className="disabled-auctions" />
          <div className="auction-down__prestart-timer">
            <img src="/img/icons/interval-icon.svg" alt="" />
            <Countdown
              ref={clockRef}
              date={lotTimer}
            />
          </div>
        </>
      );
    }

    if (lot.finished_at || !lot.is_active) {
      return <div className="disabled-auctions" />;
    }
  };

  const elActions = () => {
    if (!isMobile && (view === VIEW_HORIZONTAL)) {
      return (
        <div className="actions">
          <div className="w-100 position-relative d-flex justify-content-between align-items-center">
            {elPrice()}
            {elBet()}
            {elBetButton()}
            {elFavouriteBtn}
          </div>
        </div>
      );
    }

    if (isMobile && hideCars) {
      return (
        <div className="actions actions-mobile">
          <div className="d-flex justify-content-between align-items-center">
            {elPrice()}
            {elBet()}
            {elFavouriteBtn}
          </div>
          {elBetButton()}
        </div>
      );
    }

    return (
      <div className="actions">
        {!isMobile && lot.sales.length > 1 ? (
          <div className="w-100 position-relative d-flex justify-content-between align-items-center">
            {elPrice()}
            {elBet()}
            {elBetButton()}
            {elFavouriteBtn}
          </div>
        ) : (
          <div className="w-100 position-relative d-flex flex-column">
            {elBetButton()}
            <div className="d-flex justify-content-between align-items-center mt-15px">
              {elPrice()}
              {elBet()}
              {elFavouriteBtn}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`lot-car-wrapper position-relative ${classes.join(' ')}`}>
      {elTop()}
      {!hideCars && <LotCars lot={lot} view={view} auctionId={auctionId} />}
      {elActions()}
    </div>
  );
};
