import { COUNTRY } from '@api/config';

export const NO_PERMISSION_URL = '/';
export const DEFAULT_APP_URL = '/current-trades';
export const ROOT_URL = '/';
export const NO_AUTH_URL = '/auth/login';
export const isKZ = COUNTRY === 'kz';

export const MENU_WIDTH_OPENED = '292px';
export const MENU_WIDTH_CLOSED = '66px';

export const getOption = (item) => {
  if (!item) return item;

  const { key, id, name, ...rest } = item;

  return {
    value: key || id,
    label: name,
    ...rest,
  };
};

export const getFileExtension = (fileUrl) => {
  if (!fileUrl) return '';

  const split = fileUrl.split('.');

  return split[split.length - 1];
};

export const getLogoSrc = () => {
  return COUNTRY === 'kz' ? '/img/logo_kz.svg' : '/img/logo.svg';
};

export const getMiniLogoSrc = () => {
  return COUNTRY === 'kz' ? '/img/logo_kz_mini.svg' : '/img/side-bar-icons/mini-logo.svg';
};

export const getCurrencySymbol = () => {
  return COUNTRY === 'kz' ? '₸' : '₽';
};

export const getTypeAuctionText = (auctionType) => {
  if (auctionType === 'parallel_increase') {
    return 'На повышение';
  } if (auctionType === 'sequential_decrease') {
    return 'На понижение';
  } if (auctionType === 'buy_now') {
    return 'Buy now';
  }
};

export const LOT_GREEN_STATUSES = ['lot_sold'];
export const LOT_GREY_STATUSES = ['lot_new', 'lot_waiting_approval'];
export const LOT_RED_STATUSES = ['lot_no_bets', 'lot_declined_seller', 'lot_declined_reserved'];
export const LOT_ORANGE_STATUSES = ['lot_active'];
