import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';

export const COLUMNS = [
  {
    dataField: 'title',
    text: 'Название',
    sort: true,
    formatter: (cell, row) => <Link to={`/company/${row.id}`}>{row.title}</Link>,
  },
  {
    dataField: 'name',
    text: 'Юр. название',
    formatter: (cell, row) => row.requisite?.company_name || '-',
  },
  {
    dataField: 'inn',
    text: 'ИНН',
    formatter: (cell, row) => row.requisite?.inn || '-',
  },
  {
    dataField: 'manager',
    text: 'Менеджер',
    formatter: (cell, row) => (row.responsible ? row.responsible?.full_name : '-'),
  },
  {
    dataField: 'type',
    text: 'Тип',
    formatter: (cell, row) => (row.type ? row.type.name : '-'),
  },
  {
    dataField: 'created_at',
    text: 'Создана',
    formatter: (cell, row) => (row.created_at ? moment(row.created_at).format('DD.MM.YYYY') : '-'),
    sort: true,
  },
];
