import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';

import { CarsTab } from './cars-tabs/cars-tab';
import { UploadHistoryTab } from './cars-tabs/upload-history-tab';

import './cars.scss';

export const Cars = observer(() => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      title: 'Список автомобилей',
      content: <CarsTab />,
    },
    {
      id: 1,
      title: 'Загрузки',
      content: <UploadHistoryTab />,
    },
  ];

  if (meStore.noPermission('admin.sales.view') && meStore.noPermission('sales.edit') && meStore.noPermission('admin.sales.edit')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null) {
    return null;
  }

  return (
    <div className="car-sales">
      {meStore.hasPermission('admin.sales.edit') && (
        <div className="tabs-wrapper">
          <div className="tabs d-flex">
            {tabs.map((tab) => {
              return (
                <div key={tab.id}>
                  <button
                    onClick={() => {
                      setActiveTab(tab.id);
                    }}
                    className={`tab-btn ${tab.id === activeTab ? 'tab-btn-active' : ''}`}
                    type="button"
                  >
                    {tab.title}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {tabs[activeTab].id === activeTab && (
        <div className="mt-20px">{tabs[activeTab].content}</div>
      )}
    </div>
  );
});
