import React from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Good } from '@assets/white-check-in-green-circle.svg';
import { ReactComponent as Error } from '@assets/white-cross-in-red-circle.svg';
import { Input as InputField } from '@components/input/input';

import './input.scss';

export const Input = (props) => {
  const { value, label, onChange = () => {}, id, error, success, className, ...rest } = props;

  const inputId = id || uniqueId();

  return (
    <label
      htmlFor={inputId}
      className="input-wrapper d-flex align-items-center justify-content-between"
    >
      {label && <span className="input-label">{label}</span>}
      <div
        className={clsx(className, {
          error,
          success,
        })}
      >
        <InputField
          type="text"
          id={inputId}
          value={value}
          onChange={onChange}
          {...rest}
        />
        <ReactTooltip id={inputId} type="error" effect="solid" />
        <Error
          data-tip={error?.value || error}
          data-for={inputId}
          className={clsx('notice', { error__show: error })}
        />
        <Good className={clsx('notice', { success__show: success })} />
      </div>
    </label>
  );
};
