export const outsideDamagePointsCoordinates = {
  1: {
    top: '-2px',
    left: '243px',
  },
  4: {
    top: '13px',
    left: '243px',
  },
  7: {
    top: '32px',
    left: '188px',
  },
  11: {
    top: '32px',
    left: '298px',
  },
  15: {
    top: '28px',
    left: '243px',
  },
  18: {
    top: '103px',
    left: '243px',
  },
  22: {
    top: '13px',
    left: '217px',
  },
  23: {
    top: '154px',
    left: '218px',
  },
  25: {
    top: '173px',
    left: '243px',
  },
  27: {
    top: '189px',
    left: '160px',
  },
  29: {
    top: '270px',
    left: '243px',
  },
  30: {
    top: '225px',
    left: '243px',
  },
  32: {
    top: '13px',
    left: '270px',
  },
  43: {
    top: '64px',
    left: '114px',
  },
  44: {
    top: '141px',
    left: '131px',
  },
  45: {
    top: '101px',
    left: '139px',
  },
  46: {
    top: '103px',
    left: '100px',
  },
  47: {
    top: '112px',
    left: '122px',
  },
  49: {
    top: '174px',
    left: '158px',
  },
  50: {
    top: '202px',
    left: '128px',
  },
  51: {
    top: '202px',
    left: '94px',
  },
  52: {
    top: '223px',
    left: '114px',
  },
  53: {
    top: '236px',
    left: '149px',
  },
  55: {
    top: '132px',
    left: '89px',
  },
  54: {
    top: '204px',
    left: '180px',
  },
  56: {
    top: '74px',
    left: '127px',
  },
  57: {
    top: '48px',
    left: '93px',
  },
  95: {
    top: '414px',
    left: '118px',
  },
  96: {
    top: '385px',
    left: '142px',
  },
  97: {
    top: '354px',
    left: '133px',
  },
  98: {
    top: '345px',
    left: '101px',
  },
  99: {
    top: '332px',
    left: '120px',
  },
  100: {
    top: '291px',
    left: '128px',
  },
  101: {
    top: '291px',
    left: '94px',
  },
  102: {
    top: '268px',
    left: '114px',
  },
  103: {
    top: '321px',
    left: '151px',
  },
  104: {
    top: '338px',
    left: '177px',
  },
  105: {
    top: '378px',
    left: '95px',
  },
  106: {
    top: '385px',
    left: '127px',
  },
  135: {
    top: '491px',
    left: '243px',
  },
  136: {
    top: '476px',
    left: '243px',
  },
  139: {
    top: '459px',
    left: '243px',
  },
  140: {
    top: '451px',
    left: '189px',
  },
  141: {
    top: '451px',
    left: '297px',
  },
  142: {
    top: '442px',
    left: '243px',
  },
  143: {
    top: '362px',
    left: '243px',
  },
  145: {
    top: '476px',
    left: '222px',
  },
  146: {
    top: '476px',
    left: '265px',
  },
  155: {
    top: '64px',
    left: '372px',
  },
  156: {
    top: '141px',
    left: '354px',
  },
  157: {
    top: '101px',
    left: '347px',
  },
  158: {
    top: '103px',
    left: '385px',
  },
  159: {
    top: '114px',
    left: '364px',
  },
  160: {
    top: '189px',
    left: '326px',
  },
  161: {
    top: '174px',
    left: '331px',
  },
  162: {
    top: '202px',
    left: '361px',
  },
  163: {
    top: '202px',
    left: '392px',
  },
  164: {
    top: '223px',
    left: '372px',
  },
  165: {
    top: '236px',
    left: '337px',
  },
  166: {
    top: '204px',
    left: '311px',
  },
  167: {
    top: '132px',
    left: '398px',
  },
  168: {
    top: '74px',
    left: '359px',
  },
  169: {
    top: '48px',
    left: '395px',
  },
  207: {
    top: '414px',
    left: '372px',
  },
  208: {
    top: '385px',
    left: '344px',
  },
  209: {
    top: '354px',
    left: '354px',
  },
  210: {
    top: '345px',
    left: '385px',
  },
  211: {
    top: '332px',
    left: '367px',
  },
  212: {
    top: '291px',
    left: '365px',
  },
  213: {
    top: '291px',
    left: '392px',
  },
  214: {
    top: '268px',
    left: '372px',
  },
  215: {
    top: '321px',
    left: '337px',
  },
  216: {
    top: '338px',
    left: '310px',
  },
  217: {
    top: '378px',
    left: '393px',
  },
  218: {
    top: '385px',
    left: '359px',
  },
  219: {
    top: '363px',
    left: '337px',
  },
};

export const insideDamagePointsCoordinates = {
  268: {
    top: '138px',
    left: '241px',
  },
  269: {
    top: '117px',
    left: '241px',
  },
  270: {
    top: '153px',
    left: '241px',
  },
  271: {
    top: '160px',
    left: '201px',
  },
  272: {
    top: '184px',
    left: '246px',
  },
  273: {
    top: '53px',
    left: '201px',
  },
  274: {
    top: '195px',
    left: '236px',
  },
  275: {
    top: '160px',
    left: '292px',
  },
  276: {
    top: '238px',
    left: '242px',
  },
  277: {
    top: '143px',
    left: '201px',
  },
  278: {
    top: '172px',
    left: '237px',
  },
  306: {
    top: '203px',
    left: '92px',
  },
  307: {
    top: '183px',
    left: '185px',
  },
  308: {
    top: '226px',
    left: '202px',
  },
  309: {
    top: '138px',
    left: '141px',
  },
  310: {
    top: '278px',
    left: '16px',
  },
  324: {
    top: '314px',
    left: '90px',
  },
  325: {
    top: '283px',
    left: '187px',
  },
  326: {
    top: '315px',
    left: '202px',
  },
  327: {
    top: '353px',
    left: '140px',
  },
  328: {
    top: '452px',
    left: '202px',
  },
  329: {
    top: '452px',
    left: '222px',
  },
  330: {
    top: '452px',
    left: '242px',
  },
  331: {
    top: '427px',
    left: '160px',
  },
  332: {
    top: '427px',
    left: '324px',
  },
  333: {
    top: '452px',
    left: '262px',
  },
  334: {
    top: '414px',
    left: '242px',
  },
  335: {
    top: '486px',
    left: '242px',
  },
  336: {
    top: '452px',
    left: '282px',
  },
  337: {
    top: '314px',
    left: '394px',
  },
  338: {
    top: '283px',
    left: '207px',
  },
  339: {
    top: '315px',
    left: '281px',
  },
  340: {
    top: '353px',
    left: '344px',
  },
  341: {
    top: '203px',
    left: '394px',
  },
  342: {
    top: '183px',
    left: '297px',
  },
  343: {
    top: '226px',
    left: '281px',
  },
  344: {
    top: '138px',
    left: '343px',
  },
  345: {
    top: '278px',
    left: '468px',
  },
};
