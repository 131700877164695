import { makeAutoObservable, runInAction } from 'mobx';

import { getOption } from '@utils/common';
import { getDictionaries } from '@api/dictionaries';

class DictionariesStore {
  constructor() {
    this.dictionaries = {};

    makeAutoObservable(this);
  }

  request(dictionaryKey, config) {
    const { filters, params, saveKey } = config || {};
    const newParams = {};

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value) {
          newParams[`filters[${key}]`] = value;
        }
      }
    }

    if (params) {
      for (const [key, value] of Object.entries(params)) {
        if (value) {
          newParams[key] = value;
        }
      }
    }

    getDictionaries(dictionaryKey, newParams).then((resp) => {
      runInAction(() => {
        this.dictionaries[saveKey || dictionaryKey] = resp.map(getOption);
      });
    });
  }
}

export const dictionariesStore = new DictionariesStore();
