import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { auctionsStore } from '@mobx/auctions';
import { notificationsStore } from '@mobx/notifications';
import { BET_ERROR, BET_SUCCESS } from '@components/notifications/utils';
import { BET_CANCELED, BET_IS_LEAD, BET_WAS_OUTBID } from '@utils/pusher-messages';

import { LotCardBuyNowAuction } from './lot-card-buy-now-auction';
import { LotCardDownAuction } from './lot-card-down-auction';
import { LotCardUpAuction } from './lot-card-up-auction';
import { FavouriteBtn } from './components/favourite-btn';

export const LotCard = observer(({ lot, auctionId, auctionType, lotTimer, view, hideCars, showBtn, isMobile }) => {
  const lotId = lot.id;
  const isLotFinished = !lot.is_active && lot.winning_bet_id;
  const [classes, setClasses] = useState('');

  const lotSuccess = auctionsStore.betSuccess[lotId];
  const lotError = auctionsStore.betError[lotId];
  const lotBetPlaced = auctionsStore.betPlaced[lotId];
  const lotBetCanceled = auctionsStore.betCanceled[lotId];
  const lotBetNotification = auctionsStore.betNotification[lotId];

  useEffect(() => {
    if (lotBetPlaced && lotSuccess) {
      if (lotBetPlaced.jobKey === lotSuccess.key) {
        setClasses('green-background');
      }
    }

    if (lotBetNotification) {
      if ((lotBetNotification.type === BET_WAS_OUTBID && lotBetPlaced) || (lotBetNotification.type === BET_CANCELED && lotBetNotification.has_prev_bet)) {
        setClasses('red-background');
      } else if (lotBetNotification.type === BET_IS_LEAD) {
        setClasses('green-background');
      }
    }

    if (lotBetCanceled) {
      if (lotBetCanceled?.has_prev_bet) {
        setClasses('red-background');
      } else {
        setClasses('');
      }
    }

    if (lotSuccess) {
      notificationsStore.addNotification({ type: BET_SUCCESS, auctionType, autoClose: 5000 });
    }
  }, [lotBetPlaced, lotBetNotification, lotSuccess]);

  useEffect(() => {
    if (lotError) {
      notificationsStore.addNotification({ type: BET_ERROR, text: lotError, auctionType, autoClose: 5000 });
    }
  }, [lotError]);

  useEffect(() => {
    if (lot.lastBet) {
      if (lot.lastBet.is_my) {
        setClasses('green-background');
      } else if (!lot.lastBet.outbid) {
        setClasses('');
      } else if (!lot.lastBet.is_my) {
        setClasses('red-background');
      }
    }
  }, [lot.lastBet]);

  const lotProps = {
    lot,
    auctionId,
    lotError,
    lotSuccess,
    hideCars,
    view,
    isLost: isLotFinished && !lot.lastBet.is_my,
    isWinner: isLotFinished && lot.lastBet.is_my,
    classes: [classes],
    elFavouriteBtn: <FavouriteBtn auctionId={auctionId} lotId={lot.id} isFavourite={lot.isFavourite} />,
  };

  if (auctionType === 'sequential_decrease') {
    return (
      <LotCardDownAuction
        {...lotProps}
        lotTimer={lotTimer}
        isMobile={isMobile}
      />
    );
  } if (auctionType === 'buy_now') {
    return (
      <LotCardBuyNowAuction
        {...lotProps}
      />
    );
  } if (auctionType === 'parallel_increase') {
    return (
      <LotCardUpAuction
        {...lotProps}
        isMobile={isMobile}
        currentBet={lotBetPlaced}
        showBtn={showBtn}
      />
    );
  }
});
