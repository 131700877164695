import React, { useEffect, useState } from 'react';

import { getCarSaleComments } from '@api/cars';

import './specification.scss';

export const Specification = ({ carSaleInfo, id }) => {
  const { car } = carSaleInfo;

  const [comments, setComments] = useState();

  useEffect(() => {
    getCarSaleComments(id).then(setComments);
  }, []);

  return (
    <div className="specification-wrapper section" id="description">
      <div className="title">Спецификация</div>
      <div className="info-car-wrapper">
        <div className="info-car-wrapper__columns">
          <div className="info-car">
            <div className="info-car__field">
              <div className="name">Год выпуска</div>
              <div className="value">{car.year}</div>
            </div>
            <div className="info-car__field">
              <div className="name">Пробег</div>
              <div className="value">{carSaleInfo.mileage ?? '-'}</div>
            </div>
            <div className="info-car__field">
              <div className="name">Кузов</div>
              <div className="value">{car.type?.name ?? '-'}</div>
            </div>
          </div>
          <div className="info-car">
            <div className="info-car__field">
              <div className="name">Цвет</div>
              <div className="value">{carSaleInfo.color.name ?? '-'}</div>
            </div>
            <div className="info-car__field">
              <div className="name">Двигатель</div>
              <div className="value">
                {car?.engine_volume} / {car?.power} / {car?.engine?.name}
              </div>
            </div>
            <div className="info-car__field">
              <div className="name">КПП</div>
              <div className="value">{car.transmission?.name ?? '-'}</div>
            </div>
          </div>
          <div className="info-car">
            <div className="info-car__field">
              <div className="name">Привод</div>
              <div className="value">{car.drive?.name}</div>
            </div>
            <div className="info-car__field">
              <div className="name">VIN</div>
              <div className="value">{car.vin ?? '-'}</div>
            </div>
            <div className="info-car__field">
              <div className="name">Гос номер</div>
              <div className="value">{carSaleInfo.reg_number ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
      {comments && (
        <div className="info-car-wrapper__comment">
          <div className="name">Комментарий</div>
          <div className="value">{comments?.data?.[0]?.body ?? '-'}</div>
        </div>
      )}
    </div>
  );
};
