import React from 'react';

export const COLUMNS = [
  {
    dataField: 'name',
    text: 'Название',
    sort: true,
    checked: true,
    formatter: (cell, row) => {
      return (
        <a href={`/car/${row.id}`} target="_blank" rel="noreferrer">{row.car.brand.name} {row.car.year ? `${row.car.year} г.в.` : ''} {row.car.engine_volume} {row.car.transmission?.name}</a>
      );
    },
  },
  {
    dataField: 'seller',
    text: 'Продавец',
    sort: true,
    checked: true,
    formatter: (cell, row) => {
      return (
        <a href={`/${row.department?.parent_id ? 'department' : 'company'}/${row.department?.id}`} target="_blank" rel="noreferrer">
          {row.department?.display_name}
        </a>
      );
    },
  },
  {
    dataField: 'vin',
    text: 'VIN',
    sort: true,
    checked: true,
    formatter: (cell, row) => {
      return <div>{row.car.vin}</div>;
    },
  },
  {
    dataField: 'reg_number',
    text: 'Гос. номер',
    sort: true,
    checked: true,
    formatter: (cell, row) => {
      return <div>{row.reg_number}</div>;
    },
  },
];
