import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';

import useMediaQuery from '@hooks/use-media-query';
import { getAuctionsList } from '@api/auctions';
import { filteredFilters, setLocalStorageFilters } from '@utils/filters/filter-constants';
import { Pagination } from '@components/pagination/pagination';
import { Table } from '@components/table/table';
import { Filters } from '@pages/sell/filters/filters';

import { getActiveColumns, getFinishedColumns } from './utils';

import './buy.scss';

const PER_PAGE = [25, 50, 100, 250];

export const Buy = observer(() => {
  const [auctions, setAuctions] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'active');

  const localStorageFilters = JSON.parse(localStorage.getItem('buyFilters') || null);
  const localStorageParams = JSON.parse(localStorage.getItem('buyParams') || null);

  const isMobile = useMediaQuery('screen and (max-width: 780px)');

  const tabs = [
    {
      id: 'active',
      title: 'Идут торги',
    },
    {
      id: 'finished',
      title: 'Завершенные',
    },
  ];

  const auctionRequest = (params, filters = {}) => {
    const auctionStatus = activeTab === 'active' ? ['auction_active', 'auction_not_active'] : ['auction_finished'];
    getAuctionsList({ ...params, 'filters[status_codes]': auctionStatus }, 'auctions/buying', filteredFilters(filters)).then((resp) => setAuctions(resp));
  };

  useEffect(() => {
    auctionRequest(localStorageParams || { page: 1, per_page: PER_PAGE[0] }, localStorageFilters);
  }, [activeTab]);

  const onChangeFilters = (filters, isReset) => {
    if (isReset) {
      auctionRequest({ page: 1, per_page: auctions?.per_page });
      localStorage.removeItem('buyFilters');
    } else {
      auctionRequest({ page: 1, per_page: auctions?.per_page }, filters);
      setLocalStorageFilters(filters, 'buyFilters');
    }
  };

  return (
    <div className="buy-wrapper">
      {!isMobile && (
      <Filters
        isMobile={isMobile}
        onChange={onChangeFilters}
        localStorageFilters={localStorageFilters}
      />
      )}
      <div className="buy-wrapper__name-title">Покупаю</div>
      <div className="title">Аукционы</div>
      <div className="tabs-wrapper">
        {isMobile && (
        <Filters
          isMobile={isMobile}
          onChange={onChangeFilters}
          localStorageFilters={localStorageFilters}
        />
        )}
        <div className="tabs">
          {tabs.map((tab) => {
            return (
              <div key={tab.id}>
                <button
                  onClick={() => {
                    setActiveTab(tab.id);
                    setSearchParams({ tab: tab.id });
                  }}
                  className={`tab-btn ${tab.id === activeTab ? 'tab-btn-active' : ''}`}
                  type="button"
                >
                  {tab.title}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {auctions && auctions.data?.length ? (
        <div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
          <Table
            data={auctions?.data}
            columns={activeTab === 'active' ? getActiveColumns : getFinishedColumns()}
          />
        </div>
      ) : (
        'Лотов нет'
      )}
      <Pagination
        data={auctions}
        onChange={(params) => {
          auctionRequest(params, localStorageFilters);
          localStorage.setItem('buyParams', JSON.stringify(params));
        }}
        perPageList={PER_PAGE}
        hideInfo={isMobile}
      />
    </div>
  );
});
