import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { NO_PERMISSION_URL } from '@utils/common';
import { meStore } from '@mobx/me';

import { GroupsTab } from './user-tabs/groups-tab';
import { UsersTab } from './user-tabs/users-tab';

import './users.scss';

export const Users = observer(() => {
  const { search } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const tabHeadings = [
    {
      id: 0,
      title: 'Пользователи',
      content: <UsersTab />,
    },
    {
      id: 1,
      title: 'Группы',
      content: <GroupsTab />,
    },
  ];

  useEffect(() => {
    if (search && search === '?success=true') {
      setActiveTab(1);
    }
  }, []);

  if (meStore.noPermission('admin.users.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null) {
    return null;
  }

  return (
    <div className="users-page-wrapper">
      <div className="tabs d-flex">
        {tabHeadings.map((tab) => {
          return (
            <div key={tab.id}>
              <button
                onClick={() => setActiveTab(tab.id)}
                className={`tab-btn ${tab.id === activeTab ? 'tab-btn-active' : ''}`}
                type="button"
              >
                {tab.title}
              </button>
            </div>
          );
        })}
      </div>
      {tabHeadings[activeTab].id === activeTab && (
        <div className="content">{tabHeadings[activeTab].content}</div>
      )}
    </div>
  );
});
