import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { Pagination } from '@components/pagination/pagination';
import { getCompaniesRequest, getFilteredCompanies } from '@api/companies';
import { Table } from '@components/table/table';
import { Input } from '@components/input/input';
import { Button } from '@components/button/button';

import { COLUMNS } from './utils';

import './companies.scss';

const PER_PAGE = [25, 50, 100, 250, 500];

const DEFAULT_FILTERS = {
  title: '',
  company_name: '',
  inn: '',
};

export const Companies = observer(() => {
  const [companies, setCompanies] = useState();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [openFilter, setOpenFilter] = useState(false);

  const requestCompanies = (params) => {
    getCompaniesRequest(params).then(setCompanies);
  };

  useEffect(() => {
    requestCompanies({ page: 1, per_page: PER_PAGE[0] });
  }, []);

  if (meStore.data === null) {
    return null;
  }

  if (meStore.noPermission('admin.companies.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  const getFilteredCompaniesList = () => {
    getFilteredCompanies(filters, { page: 1, per_page: companies?.per_page }).then(setCompanies);
  };

  const clearAllFilters = () => {
    setFilters(DEFAULT_FILTERS);
    requestCompanies({ page: 1, per_page: companies?.per_page });
  };

  const handleInputChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="companies-wrapper">
      <div className="filters-wrapper">
        <div className="d-flex align-items-center">
          <div onClick={() => setOpenFilter(!openFilter)} className="d-flex align-items-center">
            <div className="title">
              <span>Фильтры</span>
            </div>
            <img
              className={clsx('arrow-down cursor-pointer', { 'rotate-180': !openFilter })}
              src="/img/tabs/hide.svg"
              alt=""
            />
          </div>
        </div>
        <div className={!openFilter ? 'd-none' : 'filter'}>
          <div className="d-flex align-items-center flex-wrap">
            <Input
              placeholder="Название"
              name="title"
              value={filters.title}
              onChange={handleInputChange}
            />
            <Input
              placeholder="Юр. название"
              name="company_name"
              value={filters.company_name}
              onChange={handleInputChange}
            />
            <Input
              placeholder="ИНН"
              name="inn"
              value={filters.inn}
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-20px d-flex gap-10px">
            <Button onClick={getFilteredCompaniesList} preset="primary">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} preset="ghost">
              Отменить все фильтры
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="name">Компании</div>
        {meStore.hasPermission('admin.companies.edit') && (
          <Link to="/create-company" className="btn btn-primary">
            Добавить
          </Link>
        )}
      </div>

      {companies?.data?.length > 0 ? (
        <>
          <Table
            data={companies.data}
            columns={COLUMNS}
          />
          <Pagination
            data={companies}
            onChange={requestCompanies}
            perPageList={PER_PAGE}
          />
        </>
      ) : (
        <p>Нет компаний</p>
      )}
    </div>
  );
});
