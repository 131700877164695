import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { getCarSaleImportFieldsRequest, getLastReport } from '@api/cars';
import { meStore } from '@mobx/me';
import { MobileTabsModal } from '@pages/car/car-info/components/mobile-tabs-modal';
import { Specification } from '@pages/car/car-info/components/description-components/specification';

import { Reports } from './components/reports';
import { Documents } from './components/documents';
import { CarSection } from './components/car-section';
import { Tires } from './components/tires';
import { Changes } from './components/changes';
import { TradesHistory } from './components/trades-history';
import { tabsNames, getAllTabs } from './utils';
import { TechnicalCheck } from './components/technical-check';
import { CarDamage } from './car-damage/car-damage';
import { CarImportedSection } from './components/car-imported-section';
import { Photos } from './components/photos';

import './car-info.scss';

export const CarTabsInfo = observer(({ carSaleInfo, defects, id, isLotSale, isLotActive, isMobile }) => {
  const [lastReport, setLastReport] = useState();
  const [carSaleInfoFields, setCarSaleInfoFields] = useState([]);
  const [isShowMobileTabsModal, setIsShowMobileTabsModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const equipmentAndCompleteness = useMemo(() => {
    let arr = [];

    (carSaleInfo.is_imported ? carSaleInfoFields : carSaleInfo.fields)?.forEach((section) => {
      if (section.key === 'sale_equipment' || section.key === 'sale_completeness') {
        arr = [...arr, section];
      }
    });

    return arr;
  }, [carSaleInfo, carSaleInfoFields]);

  useEffect(() => {
    getLastReport(id).then((resp) => setLastReport(resp));

    if (carSaleInfo.is_imported) {
      getCarSaleImportFieldsRequest(id).then((resp) => setCarSaleInfoFields(resp));
    }
  }, []);

  const tabs = useMemo(() => {
    const additionalFields = carSaleInfo.is_imported ? [] : carSaleInfo.fields.map((section) => {
      return section.key !== 'sale_equipment' && section.name;
    });

    if (carSaleInfo.is_imported) {
      carSaleInfoFields.forEach(((section) => {
        if (section.name === tabsNames.completeness) {
          additionalFields.push(section.name);
        }
      }));
    }

    let allTabs = getAllTabs(additionalFields);

    if (isLotSale || (meStore.noPermission('sales.edit') && meStore.noPermission('admin.sales.edit'))) {
      allTabs = allTabs.filter((item) => {
        return item.value !== tabsNames.reports && item.value !== tabsNames.tradesHistory;
      });
    }

    if (isLotSale || meStore.noPermission('admin.sales.view')) {
      allTabs = allTabs.filter((item) => {
        return item.value !== tabsNames.changes;
      });
    }

    if (carSaleInfo?.is_imported) {
      allTabs = allTabs.filter((item) => item.value !== tabsNames.technicalCheck);
    }

    return allTabs;
  }, [carSaleInfo, carSaleInfoFields]);

  const scrollToEl = (id) => {
    const _element = document.getElementById(id);

    if (_element) {
      const _rect = _element?.getBoundingClientRect();

      if (_rect) {
        window.scrollTo(0, _rect.y + window.scrollY - 55 - (isLotSale ? 44 : 0));
      }
    }
  };

  const hasPermissions = meStore.hasPermission('admin.sales.view');
  const hasPermissionsEdit = meStore.hasPermission('sales.edit');

  return (
    <div className="car-tabs-info-wrapper">
      {isMobile ? (
        <button
          type="button"
          onClick={() => setIsShowMobileTabsModal(true)}
          className={`car-tabs-info-wrapper__btn-tab ${isLotSale && isLotActive ? 'top-with-timer' : 'top-without-timer'}`}
        >
          {selectedTab || 'Все данные'}
          <img src="/img/icons/down-icon.svg" alt="" />
        </button>
      ) : (
        <div className={`car-tabs-info-wrapper__btn-tab ${isLotSale && isLotActive ? 'top-with-timer' : 'top-without-timer'}`}>
          {tabs?.map((tab) => (
            <div
              key={`tab-${tab.key}`}
              onClick={() => {
                setSelectedTab(tab.key);
                scrollToEl(tab.key);
              }}
            >
              <span className={clsx({ 'car-tabs-info-wrapper__btn-tab-selected': selectedTab === tab.key })}>
                {tab.label}
              </span>
            </div>
          ))}
        </div>
      )}
      <div className="spec-info position-relative">
        <Specification carSaleInfo={carSaleInfo} id={id} />
        {carSaleInfo.is_imported
          ? <CarImportedSection sections={equipmentAndCompleteness} />
          : <CarSection sections={equipmentAndCompleteness} />}
        {(hasPermissions || hasPermissionsEdit) && !isLotSale && <Reports carSaleInfo={carSaleInfo} />}
        <Documents id={id} />
        {hasPermissions && !isLotSale && <Changes carSaleInfo={carSaleInfo} /> }
        {(hasPermissions || hasPermissionsEdit) && !isLotSale && <TradesHistory carSaleInfo={carSaleInfo} />}
        <Tires carSaleInfo={carSaleInfo} id={id} />

        <Photos carSaleInfo={carSaleInfo} defects={defects} isImported={carSaleInfo.is_imported} id={id} />
        <CarDamage defects={defects} isImported={carSaleInfo.is_imported} />
        {!carSaleInfo?.is_imported && <TechnicalCheck lastReport={lastReport} />}
      </div>
      {isShowMobileTabsModal && (
        <MobileTabsModal
          options={tabs}
          onChange={scrollToEl}
          handleClose={() => setIsShowMobileTabsModal(false)}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
    </div>
  );
});
