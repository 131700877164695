import { DELETE, GET, POST } from '@api/request';

export const deleteStatusReportDefect = (id, data) => {
  return DELETE(`status_report/${id}/defects`, data);
};

export const getDefects = (groupId) => {
  const params = {};

  if (groupId) params.group_id = groupId;

  return GET('status_report/defects', params);
};

export const getWheels = (id) => {
  return GET(`status_report/${id}/tire_sets`);
};

export const postSetWheels = (id, data) => {
  return POST(`status_report/${id}/tire_sets/mass`, data, null, { headers: { 'content-type': 'multipart/form-data' } });
};

export const deleteWheels = (reportId, wheelsId) => {
  return DELETE(`status_report/${reportId}/tire_sets/${wheelsId}`);
};

export const getStatusReportsGroups = () => {
  return GET('status_report/groups');
};

export const getStatusReportDefects = (statusReportId, groupId) => {
  return GET(`status_report/${statusReportId}/defects?group_id=${groupId}`);
};

export const postCreateReport = (sale_id) => {
  return POST('status_report', { sale_id });
};

export const postCreateSaleDefect = (reportId, data) => {
  return POST(`status_report/${reportId}/defects`, data);
};
