import React, { useState } from 'react';

import { Tabs } from '@pages/car/car-info/tabs';
import { CompaniesTable } from '@components/users-companies-table/compaies-table';
import { UsersTable } from '@components/users-companies-table/users-table';

const options = [
  {
    value: 'companies',
    label: 'Компании',
  }, {
    value: 'users',
    label: 'Пользователи',
  },
];

export const EditWinner = ({ step, stepsNumber, onClickUserOrCompany }) => {
  const [activeTab, setActiveTab] = useState('companies');

  return (
    <div className="mt-25px">
      <div className="edit-trades-result-wrapper__header-text-step">Шаг {step} из {stepsNumber}</div>
      <div className="edit-trades-result-wrapper__header-text">Выберите победителя</div>
      <Tabs options={options} value={activeTab} onChange={setActiveTab} />
      {activeTab === 'companies' && (
        <CompaniesTable onSelectCompany={onClickUserOrCompany} />
      )}
      {activeTab === 'users' && (
        <UsersTable onSelectUser={onClickUserOrCompany} />
      )}
    </div>
  );
};
