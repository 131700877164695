import React, { useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Panel } from 'rsuite';

import { Loader } from '@components/loader/loader';
import { FormField } from '@components/form/form-field';
import { getCarFields, getCarFieldsGroups, putUpdateCarFields } from '@api/cars';

import { BooleanField } from './components/boolean-field';
import { EnumField } from './components/enum-field';
import { ModalConfirm } from './components/modal-confirm';
import { fieldType, getArraySubmitValues } from './utils';

export const Equipment = ({ onStepChange, edit, carData, showConfirmPopup, onConfirmChangeStep }) => {
  const carId = carData?.id;

  const [carFields, setCarFields] = useState();
  const [loading, setLoading] = useState(edit);
  const [defaultValues, setDefaultValues] = useState({});
  const [fieldsGroups, setFieldsGroups] = useState();

  const context = useForm({ defaultValues: {}, mode: 'onChange' });
  const values = context.watch();
  const { isDirty } = context.formState;

  const childs = useMemo(() => fieldsGroups?.[1]?.childs || [], [fieldsGroups]);

  const activeAccordions = childs.map((_, index) => index);

  useEffect(() => {
    getCarFields(carId).then(setCarFields);
    getCarFieldsGroups(carData?.car?.type?.key).then(setFieldsGroups);
  }, [carId]);

  const saveRequest = () => {
    const fields = getArraySubmitValues(values, true);

    if (fields.length) {
      return putUpdateCarFields(carId, { fields });
    }
  };

  const onSubmit = context.handleSubmit(
    async (formData) => {
      try {
        const response = await saveRequest();

        if (!response?.message) onStepChange();
      } catch (err) {
        console.error(err);
      }
    },
  );

  useEffect(() => {
    if (edit && carFields?.length) {
      const newDefaultValues = {};
      const category = carFields.find((item) => item.name === 'Комплектация');

      category?.childs.forEach((child) => {
        child.fields.forEach((field) => {
          if (field.type === 'bool' || field.type === 'int') {
            newDefaultValues[`${field.id}_`] = String(field.saved_value?.value);
          } else if (field.type === 'enum') {
            newDefaultValues[`${field.id}_`] = { label: field.saved_value?.value, value: field.saved_value?.value };
          } else if (field.type === 'array') {
            newDefaultValues[`${field.id}_`] = field.saved_value?.value?.map((item) => {
              return { label: item, value: item };
            });
          } else {
            newDefaultValues[`${field.id}_`] = field.saved_value?.value;
          }
        });
      });

      context.reset(newDefaultValues);

      setDefaultValues(newDefaultValues);
      setLoading(false);
    }
  }, [carFields, edit]);

  if (!childs) return null;

  return (
    <div>
      <div className="title mb-4">
        <span>Комплектация</span>
      </div>
      {loading
        ? <Loader />
        : (
          <FormProvider {...context}>
            <Panel className="all-info-wrapper">
              <Accordion alwaysOpen defaultActiveKey={activeAccordions}>
                {childs.map(({ name, key, fields }, index) => {
                  return (
                    <Accordion.Item key={key} eventKey={index}>
                      <Accordion.Header>{name}</Accordion.Header>
                      <Accordion.Body>
                        {fields.map((field) => {
                          const { type, id } = field;
                          const fieldName = field?.key || `${field?.id}_`;

                          if (type === 'enum' || type === 'array') {
                            return (
                              <div className="info-field-wrapper" key={id}>
                                <EnumField
                                  field={field}
                                  defaultValue={defaultValues[fieldName]}
                                  isClearable={true}
                                />
                              </div>
                            );
                          }
                          if (type === 'bool') {
                            return (
                              <div className="info-field-wrapper" key={id}>
                                <BooleanField
                                  field={{ ...field, fieldName }}
                                  value={values[fieldName]}
                                />
                              </div>
                            );
                          }
                          return (
                            <div className="info-field-wrapper" key={id}>
                              <FormField
                                name={field.key || `${field.id}_`}
                                label={field.name}
                                className="info-field"
                                placeholder={field.name}
                                as={fieldType[type]}
                                defaultValue={values[fieldName]}
                                key={field.name}
                              />
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Panel>
            <div className="d-flex align-items-center gap-3 mt-3 mob-button-wrapper">
              <button className="btn btn-primary flex-grow-1" type="submit" onClick={onSubmit}>
                Сохранить
              </button>
              {edit && (
                <button className="btn btn-light flex-grow-1" type="button" onClick={onStepChange}>
                  Пропустить
                </button>
              )}
            </div>
          </FormProvider>
        )}
      {showConfirmPopup && (
        <ModalConfirm
          onSave={saveRequest}
          onClose={onConfirmChangeStep}
          isDirty={isDirty}
        />
      )}
    </div>
  );
};
