import moment from 'moment/moment';
import React from 'react';
import { Link } from 'react-router-dom';

export const COLUMNS = [
  {
    dataField: 'first_name',
    text: 'ФИО',
    sort: true,
    formatter: (cell, row) => (
      <Link to={`/user/${row.id}`}>
        {row.full_name}
      </Link>
    ),
  },
  {
    dataField: 'phone',
    text: 'Телефон',
    sort: true,
  },
  {
    dataField: 'status',
    text: 'Статус',
    sort: true,
    formatter: (cell, row) => (row.status ? row.status.name : ''),
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    formatter: (cell, row) => moment(row.created_at).format('DD.MM.YYYY'),
    sort: true,
  },
];
