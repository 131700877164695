import React from 'react';

import { DEFECTS_SHORTHAND } from './utils';

export const DefectShort = ({ defects, handleShowReport, rootName, removeDefectById }) => {
  let hasOk = false;
  let hasDefects = false;

  if (!defects?.status_report_values?.length) {
    return <span>Не осматривалось</span>;
  }

  defects?.status_report_values.forEach((defect) => {
    if (defect?.defect?.name === 'ОК') {
      hasOk = true;
    } else {
      hasDefects = true;
    }
  });

  return defects?.status_report_values.map((defect) => {
    if (hasOk && hasDefects && defect?.defect?.name === 'ОК') return;

    let shortHand = defect?.defect?.name ? DEFECTS_SHORTHAND[defect?.defect?.name] : null;

    if (shortHand === 'Не ОК' && !defect?.description?.length && defect?.photos?.length > 0) {
      shortHand = '--';
    } else if (shortHand === 'Не ОК' && defect?.description?.length > 0 && (!defect?.photos?.length || defect?.photos?.length > 0)) {
      shortHand = '';
    }

    let description;

    if (defect?.description) {
      if (defect.description.length >= 20) {
        description = defect.description.slice(0, 19).concat('...');
      } else {
        description = defect?.description;
      }
    }

    return (
      <div key={defect.defect.name + defect.defect.id}>
        {shortHand === 'ОК' ? (
          <>
            <span className="green-text">{shortHand}</span>{' '}
            <button
              onClick={() => removeDefectById(defect.id)}
              className="btn-secondary ml-5px"
              type="submit"
            >
              Удалить
            </button>
          </>
        ) : (
          <>
            <span
              className="link"
              onClick={() => handleShowReport(defect, defects, rootName)}
            >
              {shortHand}
            </span>{' '}
            {(description || defect?.photos?.length > 0) && (
              <span
                onClick={() => handleShowReport(defect, defects, rootName)}
                className="link"
              >
                {shortHand === '' && description} {defect?.photos?.length > 0 && `(${defect?.photos?.length} фото)`}
              </span>
            )}
          </>
        )}
      </div>
    );
  });
};
