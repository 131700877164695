import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import clsx from 'clsx';

import { Select } from '@components/select/select';

export const WheelRowAll = ({ form, complectIdx, tireBrands, isMobile }) => {
  const [size, setSize] = useState('');
  const [brand, setBrand] = useState('');

  const ParentWrapper = (isMobile) ? 'div' : 'tr';
  const ChildWrapper = (isMobile) ? 'div' : 'td';

  useEffect(() => {
    if (size) {
      const values = form.getValues();
      const copy = [...values.wheels];
      copy[complectIdx] = {
        ...copy[complectIdx],
        tires: copy[complectIdx].tires.map((i) => ({ ...i, dimensions: size })),
      };
      form.setValue('wheels', copy);
    }
  }, [size]);

  useEffect(() => {
    if (brand) {
      const values = form.getValues();
      const copy = [...values.wheels];
      copy[complectIdx] = {
        ...copy[complectIdx],
        tires: copy[complectIdx].tires.map((i) => ({
          ...i,
          tire_brand_id: brand,
        })),
      };
      form.setValue('wheels', copy);
    }
  }, [brand]);

  return (
    <ParentWrapper
      className={clsx({ 'mob-wheel__wrapper mob-wheel__gap': isMobile, 'bg-altered': !isMobile })}
      style={{ background: '#F6F8FE' }}
    >

      <ChildWrapper
        className={clsx({ 'w-100 mob-bold mob-border-bottom': isMobile })}
        colSpan="2"
      >
        Все
      </ChildWrapper>
      {isMobile && (
        <div className="w-100 mob-bold">
          Марка
        </div>
      )}

      <ChildWrapper className={clsx({ 'w-100 mob-bold': isMobile })}>
        <Select
          options={tireBrands?.map((brand) => ({ value: brand.value, label: brand.label }))}
          valueId={brand}
          size="l"
          placeholder="Марка"
          onChange={({ value }) => setBrand(value)}
        />
      </ChildWrapper>

      {isMobile && (
        <div className="w-100 mob-bold">
          Размерность
        </div>
      )}

      <ChildWrapper className={clsx({ 'w-100 mob-bold': isMobile })}>
        <InputMask
          mask="999/99R99"
          alwaysShowMask
          type="text"
          value={size}
          className="input input_l"
          onChange={(e) => setSize(e.target.value)}
        />
      </ChildWrapper>

      <ChildWrapper />
    </ParentWrapper>
  );
};
