import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { managersStore } from '@mobx/managers';
import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { DocumentField } from '@pages/car-form/car-form-components/components/document-field';
import { Input } from '@components/input/input';
import { Loader } from '@components/loader/loader';
import { getRequisitesTemplates, getUserById, userCreate, userUpdateRequest } from '@api/users';
import { citiesStore } from '@mobx/cities';
import { RadioButton } from '@components/radio-button/radio-button';
import { Select } from '@components/select/select';
import useMediaQuery from '@hooks/use-media-query';
import { Button } from '@components/button/button';

import './user-form.scss';

const DEFAULT_FIELDS = { needDeposit: false, passport: [], other: [] };

export const UserForm = observer(({ isEdit }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState(DEFAULT_FIELDS);
  const [typeFieldPassword, setTypeFieldPassword] = useState('password');
  const [classFieldPassword, setClassFieldPassword] = useState('hide-password-input');
  const [form, setForm] = useState({
    email: '',
    password: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    phone: '',
    city_id: '',
    admin_comment: '',
    responsible_id: '',
    requisite: {},
  });
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [errors, setErrors] = useState();
  const { cities } = citiesStore;
  const isMobile = useMediaQuery();

  useEffect(() => {
    if (!managersStore.managers.length) {
      managersStore.fetch();
    }

    if (!citiesStore.cities.length) {
      citiesStore.fetch();
    }

    getRequisitesTemplates().then((resp) => {
      if (resp?.personal?.fields) {
        const fields = cloneDeep(DEFAULT_FIELDS);
        const requisite = {};

        Object.values(resp.personal.fields).forEach((field) => {
          if (field.key === 'need_deposit') {
            fields.needDeposit = true;
          } else if (field.key.includes('passport')) {
            fields.passport.push(field);
          } else {
            fields.other.push(field);
          }

          requisite[field.key] = '';
        });

        setFields(fields);
        setForm((prev) => ({ ...prev, requisite }));

        if (isEdit) {
          getUserById(id).then(setUser);
        } else {
          setLoading(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      const newUser = {
        email: user.email || '',
        last_name: user.last_name || '',
        first_name: user.first_name || '',
        middle_name: user.middle_name || '',
        phone: user.phone || '',
        city_id: user.city_id || '',
        admin_comment: user.admin_comment || '',
        responsible_id: user.responsible_id,
        requisite: {},
      };

      Object.keys(form?.requisite).forEach((key) => {
        if (key !== 'agent_contract') {
          newUser.requisite[key] = user.requisite?.[key] || '';
        }
      });

      // eslint-disable-next-line no-prototype-builtins
      if (user?.requisite?.hasOwnProperty('need_deposit')) {
        newUser.requisite.need_deposit = String(user.requisite?.need_deposit);
      }

      setForm(newUser);
      const newRoles = [];

      user.roles.forEach((item) => {
        if (item.name !== 'admin') {
          newRoles.push(item.name);
        }
      });

      setRoles(newRoles);
      setLoading(false);
    }
  }, [user]);

  if (meStore.noPermission('admin.users.edit')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null || loading) {
    return <Loader />;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('requisite.')) {
      const newName = name.replace('requisite.', '');

      setForm({
        ...form,
        requisite: {
          ...form.requisite,
          [newName]: value,
        },
      });
    } else {
      setForm({
        ...form,
        [name]: (name === 'phone') ? value.replace(/[^0-9.]/g, '') : value,
      });
    }
  };

  const hidePassword = () => {
    if (typeFieldPassword === 'text') {
      setClassFieldPassword('hide-password-input');
      setTypeFieldPassword('password');
    } else {
      setClassFieldPassword('hide-password-input-active');
      setTypeFieldPassword('text');
    }
  };

  const disabledField = (field) => {
    return isEdit && !user?.metadata?.allowed_fields?.update?.includes(field);
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setRoles((prev) => [...prev, e.target.name]);
    } else {
      setRoles((prev) => prev.filter((item) => item !== e.target.name));
    }
  };

  const saveOrEdit = () => {
    const data = {
      ...form,
      roles,
    };

    if (!data?.requisite?.need_deposit) {
      delete data.requisite.need_deposit;
    } else {
      data.requisite.need_deposit = data.requisite.need_deposit === 'true' ? 1 : 0;
    }

    if (!isEdit && window.location.search) {
      const search = new URLSearchParams(window.location.search);
      const department_id = search.get('department_id');

      if (department_id) {
        data.department_id = Number(department_id);
      }
    }

    if (isEdit && !user?.email_verified_at) {
      delete data.roles;
    }

    if (isEdit) {
      Object.keys(data).forEach((key) => {
        if (data[key] === user[key]) {
          delete data[key];
        }
      });

      Object.keys(data?.requisite).forEach((key) => {
        if (data.requisite[key] === user?.requisite?.[key]) {
          delete data.requisite[key];
        }
      });

      const prevRoles = user?.roles?.filter((role) => role.name !== 'admin').map((role) => role.name);

      if (isEqual(prevRoles, data?.roles)) {
        delete data.roles;
      }
    }

    const request = isEdit ? userUpdateRequest(id, data) : userCreate(data);

    request.then((resp) => {
      if (resp.message) {
        setErrors(resp.errors);

        setTimeout(() => {
          setErrors(null);
        }, 5000);
      } else {
        navigate(`/user/${resp.id}`);
      }
    });
  };

  const renderStaticInput = ({ type, name, label, placeholder }) => {
    return (
      <div key={`static-input-${name}`} className="create-user-wrapper__field">
        <div className="create-user-wrapper__field-name">{label}</div>
        <Input
          type={type}
          name={name}
          onChange={handleInputChange}
          value={form[name]}
          placeholder={!isMobile && placeholder}
          disabled={disabledField(name)}
        />
      </div>
    );
  };

  const renderDynamicInput = (item) => {
    return (
      <div key={item.key} className="create-user-wrapper__field">
        <div className="create-user-wrapper__field-name">{item.name}</div>
        <Input
          type={item.type === 'string' ? 'text' : item.type}
          name={`requisite.${item.key}`}
          onChange={handleInputChange}
          value={form.requisite?.[item.key]}
          placeholder={!isMobile && item.name}
        />
      </div>
    );
  };

  return (
    <div className="create-user">
      <div className="head-text">
        <span>{id ? 'Редактирование' : 'Добавление'} пользователя</span>
      </div>

      <div className="create-user-wrapper mt-4">
        {renderStaticInput({ type: 'email', name: 'email', label: 'Почта', placeholder: 'Почта *' })}
        {!isEdit && (
        <div className="create-user-wrapper__field create-user-wrapper__field">
          <div className="create-user-wrapper__field-name">Пароль</div>
          <div className="create-user-wrapper__field-password">
            <Input
              type={typeFieldPassword}
              name="password"
              autoComplete="new-password"
              onChange={handleInputChange}
              value={form.password}
              placeholder={!isMobile && 'Пароль *'}
              disabled={disabledField('password')}
            />
            <span onClick={hidePassword} className={classFieldPassword} />
          </div>

        </div>
        )}
        {renderStaticInput({ name: 'last_name', label: 'Фамилия', placeholder: 'Фамилия *' })}
        {renderStaticInput({ name: 'first_name', label: 'Имя', placeholder: 'Имя *' })}
        {renderStaticInput({ name: 'middle_name', label: 'Отчество', placeholder: 'Отчество' })}
        <div className="create-user-wrapper__field">
          <div className="create-user-wrapper__field-name">Телефон</div>
          <InputMask
            name="phone"
            mask="+9 (999) 999-99-99"
            alwaysShowMask={false}
            className="input input_l"
            value={form.phone}
            onChange={handleInputChange}
            placeholder={!isMobile && 'Телефон *'}
            disabled={disabledField('phone')}
          />
        </div>
        {renderStaticInput({ name: 'admin_comment', label: 'Заявленная компания', placeholder: 'Заявленная компания' })}
        <div className="create-user-wrapper__field">
          <div className="create-user-wrapper__field-name">Город</div>
          <Select
            options={cities?.map((city) => ({ value: city.id, label: city.name }))}
            valueId={form?.city_id || ''}
            size="m"
            placeholder={!isMobile && 'Город'}
            onChange={({ value }) => handleInputChange({ target: { name: 'city_id', value } })}
          />
        </div>
        <div className="create-user-wrapper__field">
          <div className="create-user-wrapper__field-name">Ответственный менеджер</div>
          <Select
            options={managersStore.managers?.map((user) => ({ value: user.id, label: user.full_name }))}
            valueId={form.responsible_id}
            size="m"
            placeholder={!isMobile && 'Ответственный менеджер'}
            onChange={({ value }) => handleInputChange({ target: { name: 'responsible_id', value } })}
          />
        </div>
        {fields.needDeposit && (
        <div className="create-user-wrapper__field deposit">
          <div className="create-user-wrapper__field-name">Депозит</div>
          <div className="create-user-wrapper__field-deposit">
            <RadioButton
              id="input-yes"
              label="Да"
              name="requisite.need_deposit"
              value="true"
              onChange={handleInputChange}
              defaultChecked={form.requisite?.need_deposit === 'true'}
            />
            <RadioButton
              id="input-no"
              label="Нет"
              value="false"
              name="requisite.need_deposit"
              onChange={handleInputChange}
              defaultChecked={form.requisite?.need_deposit === 'false'}
            />
          </div>
        </div>
        )}
        {(!isEdit || user?.email_verified_at) && (
        <div className="create-user-wrapper__field roles">
          <div className="create-user-wrapper__field-name">Роль</div>
          <div className="create-user-wrapper__field-roles">
            <div className="d-flex form-check-inline">
              <input
                type="checkbox"
                name="buyer"
                onChange={handleChangeCheckbox}
                id="checkboxBuyer"
                checked={roles.includes('buyer')}
                disabled={disabledField('roles')}
              />
              <label className="form-check-label" htmlFor="checkboxBuyer">
                Покупатель
              </label>
            </div>
            <div className="d-flex form-check-inline">
              <input
                type="checkbox"
                name="seller"
                onChange={handleChangeCheckbox}
                id="checkboxSeller"
                checked={roles.includes('seller')}
                disabled={disabledField('roles')}
              />
              <label className="form-check-label" htmlFor="checkboxSeller">
                Продавец
              </label>
            </div>
          </div>
        </div>
        )}
      </div>
      {fields.passport.length > 0 && (
      <div>
        <div className="head-text mt-4">Паспортные данные</div>
        <div className="create-user-wrapper mt-2">
          {fields.passport.map(renderDynamicInput)}
        </div>
      </div>
      )}
      {fields.other.length > 0 && (
      <div>
        <div className="head-text mt-4">Реквизиты</div>
        <div className="create-user-wrapper mt-2">
          {fields.other.map((item) => {
            return (item.key === 'agent_contract' && item.type === 'file') ? (
              <div key={item.key} className="mt-2 document-field__wrapper">
                <DocumentField
                  label="Договор"
                  name="requisite.agent_contract"
                  defaultValue={user?.requisite?.agent_contract}
                  onSave={(file, name) => handleInputChange({ target: { name, value: file } })}
                  onDelete={() => {
                    handleInputChange({ target: { name: 'requisite.agent_contract', value: null } });
                  }}
                />
              </div>
            ) : renderDynamicInput(item);
          })}
        </div>
      </div>
      )}
      <Button onClick={saveOrEdit} preset="primary" className="w-100">
        Сохранить пользователя
      </Button>
      {errors
        ? Object.values(errors).map((error) => (
          <Alert key={error} variant="danger">
            {error}
          </Alert>
        ))
        : ''}
    </div>
  );
});
