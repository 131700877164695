import React, { useState } from 'react';

import { getPhotoSrc } from '@utils/photo';

import { bytesToMegaBytes } from '../utils';

export const PhotoField = ({ photo, name, index, defaultValue, ...props }) => {
  const { label, placeholder } = photo;
  const [previewImage, setPreviewImage] = useState(
    defaultValue ? {
      src: getPhotoSrc(defaultValue?.file?.url),
      size: defaultValue?.file?.size,
      name: defaultValue?.file?.original_name,
    } : undefined,
  );

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage({ src: reader.result, size: file.size, name: file.name });
        props.onSaveRequest?.(file, index, e.target.name);
        e.target.value = '';
      };
      reader.readAsDataURL(file);
    }
  };

  const removeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (defaultValue?.id) {
      props.onDelete?.(defaultValue?.id);
    }

    setPreviewImage(null);
  };

  const resetClick = (e) => {
    e.preventDefault();
  };

  return (
    <label className="img-upload mt-3 d-flex flex-column justify-content-center" htmlFor={name} onClick={previewImage ? resetClick : undefined}>
      <input
        className="document-field__upload-input"
        type="file"
        id={name}
        name={name}
        onChange={handleChange}
      />
      <img className="img-photo" src={previewImage?.src || placeholder} alt="" />
      <div className="img-name mt-2">{label}</div>
      {previewImage ? (
        <div className="d-flex align-items-center justify-content-between loaded-file mt-2">
          <div className="name">{previewImage?.name}</div>
          <div className="size">{bytesToMegaBytes(previewImage?.size)} MB</div>
          <div>
            <img
              className="cursor-pointer"
              onClick={(e) => removeFile(e)}
              src="/img/icons/remove-icon.svg"
              alt=""
            />
          </div>
        </div>
      ) : (
        <span className="bt btn-primary mt-2">
          Добавить фото
        </span>
      )}
    </label>
  );
};
