import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import moment from 'moment';

import { Select } from '@components/select/select';
import { managersStore } from '@mobx/managers';
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { editAuctionRequest, getEditFieldAuction, createAuctionRequest } from '@api/auctions';
import { auctionStore } from '@mobx/auction';
import { Input } from '@components/input/input';
import { formatToUTC } from '@utils/date';

import { AUCTION_NAMES, SEQUENTIAL_DECREASE, PARALLEL_INCREASE, BUY_NOW } from '../utils';

import '../auction-form.scss';

const DEFAULT_INTERVAL = 60;
const INTERVALS = [
  { value: 60, label: '1 мин' },
  { value: 120, label: '2 мин' },
  { value: 300, label: '5 мин' },
  { value: 900, label: '15 мин' },
  { value: 'custom', label: 'Другой' },
];

export const GeneralInformationAuction = observer((props) => {
  const { auction } = auctionStore;
  const [auctionSuccess, setAuctionSuccess] = useState('');
  const isInit = useRef();
  const [auctionError, setAuctionError] = useState();
  const [dateType, setDateType] = useState('text');
  const [intervalValue, setIntervalValue] = useState(DEFAULT_INTERVAL);
  const [customInterval, setCustomInterval] = useState();
  const [formattedDate, setFormattedDate] = useState();
  const [timeUnit, setTimeUnit] = useState(1);
  const [editFields, setEditFields] = useState({});
  const [form, setForm] = useState({
    auction_type: 'parallel_increase',
    start_at: '',
    responsible_id: '',
    is_seller_hidden: false,
    is_increasing: true,
    is_open: false,
    is_step_fixed: false,
    is_sequential: false,
    sellerable_type: 'department',
    sellerable_id: '',
  });
  const isAuctionCreated = Boolean(auction);
  const isSellerDisabled = Boolean(auction && !editFields?.allowed_fields?.includes('sellerable_id'));

  useEffect(() => {
    if (!managersStore.managers.length) {
      managersStore.fetch();
    }
  }, []);

  useEffect(() => {
    if (auction && !isInit.current) {
      isInit.current = true;

      setForm({
        ...form,
        start_at: auction.start_at,
        is_seller_hidden: auction.is_seller_hidden,
        is_increasing: auction.is_increasing,
        auction_type: auction.auction_type,
        is_open: auction.is_open,
        is_step_fixed: auction.is_step_fixed,
        is_sequential: auction.is_sequential,
        sellerable_type: 'department',
        sellerable_id: auction.sellerable_id,
        responsible_id: auction.responsible_id || '',
      });

      setFormattedDate(moment(auction.start_at).format('DD.MM.YYYY HH.mm'));

      if (INTERVALS.find((item) => item.value === auction.interval)) {
        setIntervalValue(auction.interval);
      } else {
        setIntervalValue('custom');
        setCustomInterval(auction.interval);
      }

      getEditFieldAuction(Number(auction.id), 'update').then((resp) => setEditFields(resp));
    }
  }, [auction]);

  const handleChangeAuctionType = (auctionType) => {
    setForm({
      ...form,
      auction_type: auctionType,
    });
  };

  const handleInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onClickInterval = (intervalTime) => {
    if (auction && !editFields?.allowed_fields?.includes('interval')) return;

    setIntervalValue(intervalTime);
  };

  const onClickSetTimeUnit = (time) => {
    if (auction && !editFields?.allowed_fields?.includes('interval')) return;

    setTimeUnit(time);
  };

  const createAuction = () => {
    const newInterval = intervalValue === 'custom' ? (customInterval * timeUnit) : intervalValue;
    const newResponsibleId = form.responsible_id ? Number(form.responsible_id) : '';
    const data = auction ? {} : { ...form, interval: newInterval, responsible_id: newResponsibleId };

    if (auction) {
      Object.keys(form).forEach((key) => {
        if (key === 'responsible_id') {
          if (newResponsibleId !== auction[key]) {
            data.responsible_id = newResponsibleId;
          }
        } else if (form[key] !== auction[key]) {
          data[key] = form[key];
        }
      });

      if (!data.sellerable_id) {
        delete data.sellerable_type;
      }

      if (newInterval !== auction.interval) {
        data.interval = newInterval;
      }
    }

    if (data.start_at) {
      data.start_at = formatToUTC(data.start_at);
    }

    if (data.auction_type === 'buy_now') {
      delete data.interval;
    }

    if (Object.keys(data).length) {
      const request = auction ? editAuctionRequest(auction?.id, data) : createAuctionRequest(data);

      request.then((resp) => {
        if (resp.message) {
          setAuctionError(resp.errors || resp.message);

          setTimeout(() => setAuctionError(null), 5000);
        } else {
          setAuctionSuccess(auction ? 'Аукцион успешно обновлен' : 'Аукцион успешно добавлен');
          auctionStore.setAuction(resp);
          props.onStepChange();

          setTimeout(() => setAuctionSuccess(null), 5000);
        }
      });
    } else {
      props.onStepChange();
    }
  };

  return (
    <div>
      <div className="info-wrapper">
        <div className={`d-flex align-items-center justify-content-between mt-2 ${isAuctionCreated && 'info-wrapper__input_disabled'}`}>
          <div className="name w-30">Тип аукциона</div>
          <div className="d-flex align-items-center position-relative w-70">
            <div className="btn-wrapper">
              <span
                onClick={() => {
                  if (isAuctionCreated) return;

                  handleChangeAuctionType('parallel_increase');
                }}
                className={clsx('btn-wrapper__type cursor-pointer', {
                  'btn-wrapper__type-active': form.auction_type === 'parallel_increase',
                })}
              >
                {AUCTION_NAMES[PARALLEL_INCREASE]}
              </span>
              <span
                onClick={() => {
                  if (isAuctionCreated) return;

                  handleChangeAuctionType('sequential_decrease');
                }}
                className={clsx('btn-wrapper__type cursor-pointer', {
                  'btn-wrapper__type-active': form.auction_type === 'sequential_decrease',
                })}
              >
                {AUCTION_NAMES[SEQUENTIAL_DECREASE]}
              </span>
              <span
                onClick={() => {
                  if (isAuctionCreated) return;

                  handleChangeAuctionType('buy_now');
                }}
                className={clsx('btn-wrapper__type cursor-pointer', {
                  'btn-wrapper__type-active': form.auction_type === 'buy_now',
                })}
              >
                {AUCTION_NAMES[BUY_NOW]}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="name w-30">Дата начала</div>
          <div className="d-flex align-items-center w-70">
            <Input
              type={dateType}
              onFocus={() => setDateType('datetime-local')}
              onBlur={() => setDateType('text')}
              step="900"
              max="24:00"
              name="start_at"
              data-date-format="dd-mm-yy"
              onChange={(e) => {
                handleInputChange(e);
                setFormattedDate(moment(e.target.value).format('DD.MM.YYYY HH.mm'));
              }}
              value={formattedDate || 'ДД.ММ.ГГГГ, 00:00'}
              className="input-date"
              disabled={Boolean(auction && !editFields?.allowed_fields?.includes('start_at'))}
            />
          </div>
        </div>
        {form?.auction_type !== 'buy_now' && (
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="name w-30">Интервал продления/понижения</div>
            <div className="d-grid gap-5px w-70 justify-content-start">
              <div className="btn-wrapper">
                {INTERVALS.map((item, index) => {
                  return (
                    <span
                      onClick={() => onClickInterval(item.value)}
                      key={`interval-${index}`}
                      className={clsx('btn-wrapper__type cursor-pointer', {
                        'btn-wrapper__type-active': intervalValue === item.value,
                      })}
                    >
                      {item.label}
                    </span>
                  );
                })}
              </div>
              {auctionError?.interval ? (
                <span className="error-message">{auctionError?.interval[0]}</span>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
        {intervalValue === 'custom' && (
          <div className="d-flex align-items-center justify-content-end">
            <div className="d-flex w-70 gap-10px align-items-center mt-2">
              <Input
                className="input-time"
                type="number"
                autoComplete="off"
                onChange={(e) => {
                  if (auction && !editFields?.allowed_fields?.includes('interval')) return;
                  setCustomInterval(e.target.value);
                }}
                placeholder="0"
                value={customInterval}
                disabled={Boolean(auction && !editFields?.allowed_fields?.includes('interval'))}
              />
              <div className="btn-wrapper__time">
                <span
                  onClick={() => onClickSetTimeUnit(1)}
                  className={clsx('btn-wrapper__time-type cursor-pointer', {
                    'btn-wrapper__type-active': timeUnit === 1,
                  })}
                >
                  сек
                </span>
                <span
                  onClick={() => onClickSetTimeUnit(60)}
                  className={clsx('btn-wrapper__time-type cursor-pointer', {
                    'btn-wrapper__type-active': timeUnit === 60,
                  })}
                >
                  мин
                </span>
                <span
                  onClick={() => onClickSetTimeUnit(3600)}
                  className={clsx('btn-wrapper__time-type cursor-pointer', {
                    'btn-wrapper__type-active': timeUnit === 3600,
                  })}
                >
                  час
                </span>
              </div>
            </div>
          </div>
        )}
        <div className={`d-flex align-items-center justify-content-between mt-2 ${isSellerDisabled && 'info-wrapper__input_disabled'}`}>
          <div className="name w-30">Продавец</div>
          <div className="d-flex align-items-center position-relative w-70">
            <InputRequisitesSearch
              name="sellerable_id"
              defaultValue={auction?.sellerable?.display_name}
              placeholder="Наименование продавца"
              disabled={isSellerDisabled}
              onChange={(sellerable_id) => {
                setForm({ ...form, sellerable_id });
              }}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <label className="d-flex align-items-center w-70 mt-2 gap-5px cursor-pointer">
            <input
              type="checkbox"
              name="is_seller_hidden"
              onChange={(e) => setForm({ ...form, [e.target.name]: e.target.checked })}
              checked={form.is_seller_hidden}
              disabled={Boolean(auction && !editFields?.allowed_fields?.includes('is_seller_hidden'))}
            />
            <span className="text-seller">
              Скрыть продавца
            </span>
          </label>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="name w-30">Ответственный менеджер</div>
          <div className="d-flex align-items-center position-relative w-70">
            <Select
              options={managersStore.managers?.map((user) => ({ value: user.id, label: user.full_name }))}
              valueId={form.responsible_id}
              size="m"
              placeholder="Не выбран"
              onChange={({ value }) => handleInputChange({ target: { name: 'responsible_id', value } })}
              disabled={Boolean(auction && !editFields?.allowed_fields?.includes('responsible_id'))}
            />
          </div>
        </div>
      </div>
      <div className="info-wrapper-error">
        {auctionSuccess && (
          <div className="mt-2 mb-2 alert alert-success">{auctionSuccess}</div>
        )}
        {auctionError && (
          <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(auctionError, null, ' ')}</div>
        )}
        {auctionError?.is_open ? (
          <div className="mt-2 mb-2 alert alert-danger">{auctionError?.is_open[0]}</div>
        ) : (
          ''
        )}
        {auctionError?.is_sequential ? (
          <div className="mt-2 mb-2 alert alert-danger">{auctionError?.is_sequential[0]}</div>
        ) : (
          ''
        )}
      </div>
      <div className="auction-buttons-footer">
        <button
          // disabled={!(form.interval && form.type_auction)}
          onClick={createAuction}
          className="btn btn-primary mt-5 d-flex align-items-center gap-10px"
          type="button"
        >
          Добавить лоты
          <img src="/img/icons/arrow-right-icon.svg" alt="" />
        </button>
      </div>
    </div>
  );
});
