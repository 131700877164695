import React, { useEffect, useState, useRef } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useMediaQuery from '@hooks/use-media-query';
import { pusherStore } from '@mobx/pusher';
import { meStore } from '@mobx/me';
import { auctionsStore } from '@mobx/auctions';
import { AuctionItem } from '@components/auction-item/auction-item';
import { Loader } from '@components/loader/loader';
import { NO_PERMISSION_URL } from '@utils/common';
import { LotChangeView } from '@components/lot-change-view/lot-change-view';
import { Pagination } from '@components/pagination/pagination';
import { getAuction } from '@api/auctions';
import { filteredFilters } from '@utils/filters/filter-constants';

import { CurrentTradesFilters } from './current-trades-filters/current-trades-filters';
import { VIEW_HORIZONTAL, VIEW_TILE } from './current-trades-filters/utils';

import './current-trades.scss';

const PER_PAGE_LIST = [25, 50, 100, 250];

export const CurrentTrades = observer(({ endpointUrl, disableAddButton, tabModifiers }) => {
  const { auctions } = auctionsStore;
  const user = meStore.data;
  const endPoint = endpointUrl ?? null;

  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery();

  const [loading, setLoading] = useState(true);
  const [auction, setAuction] = useState(null);
  const $ref = useRef();
  const [fixedAuctionHeader, setFixedAuctionHeader] = useState();
  const [view, setView] = useState();

  const localStorageFilters = JSON.parse(localStorage.getItem('auctionsFilters') || null);
  const localStorageParams = JSON.parse(localStorage.getItem('auctionsParams') || null);

  useEffect(() => {
    setView(isMobile ? VIEW_TILE : (localStorage.getItem('auction_view') || VIEW_HORIZONTAL));
  }, [isMobile]);

  const onScrollContainer = () => {
    const containerScrollTop = window.scrollY + 50;
    let newFixedElem;

    // eslint-disable-next-line guard-for-in
    for (const key in $ref.current?.children) {
      const elem = $ref.current?.children[key];

      if (elem.classList?.contains('card-auction') && containerScrollTop >= elem.offsetTop) {
        newFixedElem = elem.id;
      }
    }

    if (fixedAuctionHeader !== Number(newFixedElem)) {
      setFixedAuctionHeader(Number(newFixedElem));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollContainer);

    return () => {
      window.removeEventListener('scroll', onScrollContainer);
    };
  }, []);

  const setLocalStorageFilters = (filters) => {
    const newFilters = {};

    Object.entries(filters)?.forEach(([key, value]) => {
      if (value) {
        newFilters[key] = value;
      }
    });

    localStorage.setItem('auctionsFilters', JSON.stringify(newFilters));
  };

  const requestAuctions = (params, filters) => {
    return auctionsStore.getAuctions({
      'filters[status_codes]': ['auction_active', 'auction_not_active'],
      'per_page': params?.per_page || auctions.per_page,
      'page': params?.page || auctions.page,
      'orders[planned_finish_at]': 'asc',
      ...(params || {}),
    }, endPoint, filters);
  };

  const onChangeFilters = (filters, isReset) => {
    if (isReset) {
      requestAuctions(null, endpointUrl || null);
      localStorage.removeItem('auctionsFilters');
    } else {
      requestAuctions(null, filteredFilters(filters));
      setLocalStorageFilters(filters, 'auctionsFilters');
    }
  };

  useEffect(() => {
    if (user.user?.id) {
      pusherStore.subscribeAuctions({ userId: user.user.id });
    }

    return () => {
      pusherStore.disconnect();
    };
  }, [user.user?.id]);

  useEffect(() => {
    requestAuctions(localStorageParams || { per_page: PER_PAGE_LIST[0] }, filteredFilters(localStorageFilters)).then(() => {
      setLoading(false);
      const auctionId = searchParams.get('auctionId');

      if (searchParams.get('auctionId')) { // здесь отрабатывается переход по ссылке, полученной кликом по одной из дорожек в календаре торгов
        setTimeout(() => {
          const _element = document.getElementById(auctionId); // взятый из урл id - это ссылка на один из элементов аукциона

          if (_element) {
            const _rect = _element?.getBoundingClientRect(); // парсим ее координаты

            if (_rect) {
              window.scrollTo(0, _rect.y - 80);
            }
          } else {
            getAuction(auctionId).then((resp) => setAuction(resp));
          }
        }, 200);
      }
    });
  }, []);

  if (meStore.noPermission('auctions.view') && meStore.noPermission('admin.auctions.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  const elViewSettings = () => {
    return (
      <div className="auctions-wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            <span>Аукционы</span>
          </div>
          {!isMobile && (
            <div className="d-flex">
              <LotChangeView nameView="auction_view" setView={setView} />
              {meStore.hasPermission('admin.auctions.edit') && (!disableAddButton) && (
                <Link to="/add-auction" className="btn btn-primary">
                  Добавить
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading || !meStore.data) {
    return <Loader />;
  }

  return (
    <div className="total-wrapper" ref={$ref}>
      <div className="header-wrapper">
        <CurrentTradesFilters
          isMobile={isMobile}
          onChange={onChangeFilters}
          localStorageFilters={localStorageFilters}
        />
        {elViewSettings()}
      </div>
      {auction?.is_active && (
        <AuctionItem
          isMobile={isMobile}
          fixedHeader={fixedAuctionHeader === auction.id}
          auction={auction}
          view={view}
          hasViewPermission={isMobile ? VIEW_TILE : meStore.hasPermission('admin.auctions.view')}
        />
      )}
      {auctions.data?.length > 0 ? (
        <>
          {auctions.data.map((item) => (
            <AuctionItem
              isMobile={isMobile}
              fixedHeader={fixedAuctionHeader === item.id}
              key={item.id}
              auction={item}
              view={view}
              hasViewPermission={isMobile ? VIEW_TILE : meStore.hasPermission('admin.auctions.view')}
            />
          ))}
          <Pagination
            data={auctions}
            onChange={(params) => {
              requestAuctions(params, localStorageFilters);
              localStorage.setItem('auctionsParams', JSON.stringify(params));
            }}
            perPageList={PER_PAGE_LIST}
            hideInfo={isMobile}
          />
        </>
      ) : 'Активных аукционов нет'}
    </div>
  );
});
