import React, { useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment/moment';

import { Table } from '@components/table/table';
import { postCreateReport } from '@api/reports';
import { Button } from '@components/button/button';

import './car-info-components.scss';

const columns = [
  {
    dataField: 'id',
    text: 'Номер отчета',
    formatter: (cell, row) => <Link to={`/car/${row?.sale_id}/report/${cell}`}>{cell}</Link>,
  },
  {
    dataField: 'responsible_id',
    text: 'Технический специалист',
    formatter: (cell, row) => row?.responsible?.full_name || '-',
  },
  {
    dataField: 'status_id',
    text: 'Статус отчета',
    formatter: (cell, row) => row?.status?.name,
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    formatter: (cell, row) => (row?.created_at ? moment(row?.created_at).format('DD.MM.YYYY') : '-'),
  },
  {
    dataField: 'updated_at',
    text: 'Дата изменения',
    formatter: (cell, row) => moment(row?.updated_at).format('DD.MM.YYYY') || '-',
  },
  {
    dataField: 'updated_by',
    text: 'Внес изменения',
    formatter: (cell, row) => (row?.updated_by?.full_name || '-'),
  },
  {
    dataField: 'closed_at',
    text: 'Дата закрытия',
    formatter: (cell, row) => (row?.closed_at ? moment(row?.closed_at).format('DD.MM.YYYY') : '-'),
  },
  {
    dataField: 'closed_by',
    text: 'Закрыл отчет',
    formatter: (cell, row) => (row?.closed_by?.full_name || '-'),
  },
];

export const Reports = ({ carSaleInfo }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { status_reports } = carSaleInfo;

  const statusReports = useMemo(() => {
    if (status_reports) {
      const newArr = [...status_reports];
      return newArr.reverse();
    }
    return [];
  }, [status_reports]);

  const handleCreateReportAndRedirect = async () => {
    postCreateReport(id).then((resp) => {
      if (resp.id) {
        navigate(`/car/${id}/report/${resp.id}`);
      }
    });
  };

  return (
    <div className="section" id="reports">
      <div className="section-header">
        <div className="title">Отчёты</div>
        {carSaleInfo?.metadata?.actions?.['statusReports.create'] && (
          <Button onClick={handleCreateReportAndRedirect} preset="primary" className="mb-10px">
            Добавить
          </Button>
        )}
      </div>
      {statusReports?.length ? (
        <Table
          data={statusReports}
          columns={columns}
        />
      ) : <div>Нет данных</div>}
    </div>
  );
};
