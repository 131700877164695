import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Select } from '@components/select/select';
import { Input } from '@components/input/input';
import { Button } from '@components/button/button';
import { carBrandsStore } from '@mobx/car-brands';
import { FiltersModal } from '@pages/sell/filters/filters-modal';
import { getSaleModelsRequest } from '@api/dictionaries';

import './filters.scss';

const DEFAULT_FILTERS = {
  brand_id: '',
  model_id: '',
  vin: '',
  reg_number: '',
};

export const Filters = ({ isMobile, onChange, localStorageFilters }) => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [openFilter, setOpenFilter] = useState(false);
  const [isShowCurrentTradesFiltersModal, setIsShowCurrentTradesFiltersModal] = useState(false);
  const filtersCount = localStorageFilters && Object.keys(localStorageFilters).length;

  const { brands } = carBrandsStore;
  const [models, setModels] = useState([]);

  useEffect(() => {
    if (!brands.length) carBrandsStore.fetch();
    if (localStorageFilters) {
      setFilters((prev) => ({ ...prev, ...localStorageFilters }));

      if (localStorageFilters?.brand_id) {
        getSaleModelsRequest(localStorageFilters.brand_id, 'car').then(setModels);
      }
    }
  }, []);

  const applyFilters = () => onChange(filters, false);

  const clearAllFilters = () => {
    setOpenFilter(!openFilter);
    setFilters(DEFAULT_FILTERS);
    onChange(null, true);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {isMobile ? (
        <button
          type="button"
          onClick={() => setIsShowCurrentTradesFiltersModal(true)}
          className="btn-filters"
        >
          <img src="/img/icons/filter-icon.svg" alt="" />
          Фильтры
          {filtersCount > 0 && <div className="filters-count">{filtersCount}</div>}
        </button>
      ) : (
        <div className="filters-wrapper">
          <div className="d-flex align-items-center position-relative">
            <div onClick={() => setOpenFilter(!openFilter)} className="d-flex align-items-center">
              <div className="title">Фильтры</div>
              {filtersCount > 0 && <div className="filters-count">{filtersCount}</div>}
              <img
                className={clsx('arrow-down cursor-pointer', { 'rotate-180': !openFilter })}
                src="/img/tabs/hide.svg"
                alt=""
              />
            </div>
          </div>
          <div className={!openFilter ? 'd-none' : 'filter'}>
            <div className="d-flex align-items-center flex-wrap gap-10px">
              <Select
                options={brands}
                valueId={filters?.brand_id || ''}
                size="m"
                placeholder="Марка"
                onChange={({ value }) => {
                  handleFilterChange({ target: { name: 'brand_id', value } });
                  getSaleModelsRequest(value, 'car').then(setModels);
                }}
              />
              <Select
                options={models?.map((model) => ({ value: model.id, label: model.name }))}
                valueId={filters?.model_id || ''}
                size="m"
                placeholder="Модель"
                onChange={({ value }) => {
                  handleFilterChange({ target: { name: 'model_id', value } });
                }}
              />
              <Input
                size="m"
                onChange={handleFilterChange}
                value={filters?.vin ?? ''}
                name="vin"
                placeholder="VIN"
              />
              <Input
                placeholder="Гос. номер"
                name="reg_number"
                value={filters.reg_number}
                onChange={handleFilterChange}
                className="input_small"
                size="m"
              />
            </div>
            <div className="d-flex gap-10px mt-20px">
              <Button onClick={applyFilters} preset="primary">
                Применить фильтры
              </Button>
              <Button onClick={clearAllFilters} preset="secondary">
                Отменить все фильтры
              </Button>
            </div>
          </div>
        </div>
      )}
      {isShowCurrentTradesFiltersModal && (
        <FiltersModal
          handleClose={() => setIsShowCurrentTradesFiltersModal(false)}
          applyFilters={applyFilters}
          clearAllFilters={clearAllFilters}
          handleFilterChange={handleFilterChange}
          filters={filters}
          brands={brands}
        />
      )}
    </>
  );
};
