import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import './button.scss';

export const Button = forwardRef((props, ref) => {
  const { preset, size = 'l', type, className, to, href, ...rest } = props;
  const classes = clsx('btn', `btn-${preset || 'primary'}`, `btn_${size || 'l'}`, className);

  if (to) {
    return <Link to={to} className={classes} ref={ref} {...rest}>{props.children}</Link>;
  }

  if (href) {
    return <a href={href} className={classes} ref={ref} {...rest}>{props.children}</a>;
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type || 'button'} className={classes} ref={ref} {...rest}>{props.children}</button>
  );
});
