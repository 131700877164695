import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { UsersDepartmentModal } from '@components/users-departments-modal/users-department-modal';
import { DropdownButton } from '@components/dropdown/dropdown-button';
import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { COLUMNS } from '@components/users-departments-modal/utils';
import { getRequisitesTemplates } from '@api/users';
import { Loader } from '@components/loader/loader';
import { Table } from '@components/table/table';
import { getCompaniesUsersRequest, getSingleCompanyRequest } from '@api/companies';

import './company.scss';

export const Company = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [company, setCompany] = useState();
  const [users, setUsers] = useState();
  const [fields, setFields] = useState([]);
  const [show, setShow] = useState(false);

  const requestUsers = () => {
    getCompaniesUsersRequest(id).then(setUsers);
  };

  const handleClose = (needRequest) => {
    if (needRequest) {
      requestUsers();
    }

    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getSingleCompanyRequest(id).then(setCompany);
    requestUsers();

    getRequisitesTemplates().then((resp) => {
      if (resp?.legal?.fields) {
        setFields(Object.values(resp.legal.fields));
      }
    });
  }, []);

  if (meStore.noPermission('admin.companies.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null || !company) {
    return <Loader />;
  }

  const renderDynamicField = (item) => {
    return (
      <div key={item.key} className="company-info__field-wrapper d-grid grid-columns-30-70">
        <div className="name">{item.name}</div>
        <div className="value">{company?.requisite?.[item.key] || '-'}</div>
      </div>
    );
  };

  return (
    <div className="company-wrapper">
      <div className="actions d-flex align-items-center justify-content-between">
        <div className="title">{company.title}</div>
        <Link to={`/company/${id}/edit`} className="btn btn-primary">
          Редактировать
        </Link>
      </div>
      <div className="company-info mt-3">
        <div className="d-grid grid-columns-30-70 company-info__field-wrapper">
          <div className="name">Название организации</div>
          <div className="value">{company.title}</div>
        </div>
        <div className="d-grid grid-columns-30-70 company-info__field-wrapper">
          <div className="name">Тип компании</div>
          <div className="value">
            {company.type_id ? company.type?.name : '-'}
          </div>
        </div>
        <div className="d-grid grid-columns-30-70 company-info__field-wrapper">
          <div className="name">Ответственный менеджер</div>
          <div className="value">
            {company.responsible?.full_name || '-'}
          </div>
        </div>
        <div className="d-grid grid-columns-30-70 company-info__field-wrapper">
          <div className="name">Логотип</div>
          <div className="value">
            {company?.logo?.url ? (
              <a href={company.logo.url} target="_blank" rel="noreferrer">{company.logo.original_name}</a>
            ) : '-'}
          </div>
        </div>
        <div className="d-flex justify-content-start gap-50px company-info__field-wrapper">
          <div className="d-flex form-check-inline checkbox">
            <input
              type="checkbox"
              name="roles"
              value="buyer"
              id="checkboxBuyer"
              disabled={true}
              checked={Boolean(company.roles?.find((item) => item.name === 'buyer'))}
            />
            <label className="form-check-label" htmlFor="checkboxBuyer">
              Покупатель
            </label>
          </div>
          <div className="d-flex form-check-inline checkbox">
            <input
              type="checkbox"
              name="roles"
              value="seller"
              id="checkboxSeller"
              disabled={true}
              checked={Boolean(company.roles?.find((item) => item.name === 'seller'))}
            />
            <label className="form-check-label" htmlFor="checkboxSeller">
              Продавец
            </label>
          </div>
        </div>
      </div>

      {fields.length > 0 && (
        <div className="mt-4">
          <div className="title">Реквизиты</div>
          <div className="company-info mt-3">
            {fields.map((item) => (item.key === 'agent_contract' ? (
              <div key={item.key} className="d-grid grid-columns-30-70 company-info__field-wrapper">
                <div className="name">Документ</div>
                <div className="value">
                  {company?.requisite?.agent_contract?.url ? (
                    <a href={company.requisite.agent_contract.url} download={company.requisite.agent_contract.original_name}>
                      {company.requisite.agent_contract.original_name}
                    </a>
                  ) : '-'}
                </div>
              </div>
            ) : renderDynamicField(item)))}
          </div>
        </div>
      )}
      <div className="mt-4">
        <div className="d-flex align-items-center justify-content-between ">
          <div className="title">Пользователи</div>
          {meStore.hasPermission('admin.companies.edit') && (
            <DropdownButton
              btnText="Действия"
              options={[{
                label: 'Изменить пользователей',
                value: 'add',
                onClick: handleShow,
              }, {
                label: 'Создать пользователя',
                value: 'create',
                onClick: () => {
                  navigate(`/users/create?department_id=${id}`);
                },
              },
              ]}
            />
          )}
        </div>
        {show && (
          <UsersDepartmentModal
            type="company"
            company_id={company?.id}
            show={true}
            onHide={handleClose}
            selected={users?.data?.map((item) => item.id)}
          />
        )}
        <div className="mt-3">
          {users?.data?.length > 0 ? (
            <Table
              data={users.data}
              columns={COLUMNS}
            />
          ) : (
            <p>Нет пользователей</p>
          )}
        </div>
      </div>
    </div>
  );
});
