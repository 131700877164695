import React from 'react';

import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';

export const CarSeller = (props) => {
  return (
    <div>
      <div className="info-field-wrapper">
        <div className="d-flex align-items-center input-wrapper justify-content-between">
          <span className="input-label">Продавец</span>
          <div className="input-field-wrapper d-flex align-items-center info-field add-car-suggestions">
            <InputRequisitesSearch
              isChooseSeller={true}
              placeholder="Продавец"
              className="input-field w-100"
              defaultValue={props.defaultDepartmentValue}
              onChange={props.setDepartmentId}
              disabled={props.disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
