import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { FormField } from '@components/form/form-field';
import { dictionariesStore } from '@mobx/dictionaries';

const dictionariesDepends = {
  car_model_id: 'car_brand_id',
  car_brand_id: 'car_type_key',
  body_id: 'car_type_key',
};

const getDictionaryFilters = (dictionary, state) => {
  if (!state?.[dictionariesDepends[dictionary]]?.value) return {};
  return { [dictionariesDepends[dictionary]]: state?.[dictionariesDepends[dictionary]]?.value };
};

const getDictionaryParams = (dictionary) => {
  if (dictionary === 'color_id' || dictionary === 'body_id') {
    return { 'orders[name]': 'asc' };
  }
  return {};
};

const getDisabled = (dictionary, state) => {
  switch (dictionary) {
    case 'car_model_id':
      return !state?.car_brand_id?.value;
    default:
      return false;
  }
};

export const DictionaryField = observer(({ field, formState, ...restProps }) => {
  const { dictionary, key: name } = field;
  const {
    car_type_key: { value },
  } = formState;

  const additionalFilters = getDictionaryFilters(name, formState);
  const isDisabled = getDisabled(name, formState) || restProps.disabled;

  useEffect(() => {
    if (!isDisabled && (!dictionariesStore.dictionaries[dictionary] || dictionary === 'car_models' || dictionary === 'car_bodies' || dictionary === 'car_brands')) {
      dictionariesStore.request(dictionary, { filters: { car_type_key: value, ...additionalFilters }, params: getDictionaryParams(name) });
    }
  }, [dictionary, isDisabled, JSON.stringify(additionalFilters)]);

  return (
    <FormField
      label={field.name}
      name={name}
      className="info-field"
      {...restProps}
      options={dictionariesStore.dictionaries[dictionary]}
      as="select"
      isDisabled={isDisabled}
    />
  );
});
