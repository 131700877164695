import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Header } from '@components/header/header';
import { Sidebar } from '@components/sidebar/sidebar';
import { layoutStore } from '@mobx/layout';
import useMediaQuery from '@hooks/use-media-query';
import { meStore } from '@mobx/me';
import { Notifications } from '@components/notifications/notifications';
import { ChooseProfilePopup } from '@components/choose-profile-popup/choose-profile-popup';
import { postImpersonationCompany } from '@api/auth';

import './layout.scss';

export const LayoutAuth = observer(({ children }) => {
  return !meStore.data ? children : <Navigate to="/" />;
});

export const LayoutMain = observer(({ children, noMenu }) => {
  const isMobile = useMediaQuery();
  const open = isMobile ? !layoutStore.isSidebarOpened : layoutStore.isSidebarOpened; // в мобильной версии вместо сайдбара бургер, по умолчанию он закрыт
  const [isShowChooseProfilePopup, setIsShowChooseProfilePopup] = useState(false);

  const onChangeImpersonation = (company_id) => {
    postImpersonationCompany({ company_id })
      .then((resp) => {
        if (!resp.message) {
          meStore.setData(resp);
          setIsShowChooseProfilePopup(false);
        }
      });
  };

  useEffect(() => {
    if (meStore.isLogined && meStore.data) {
      const userRoles = meStore?.data?.user?.roles?.map((item) => item.name) || [];

      if (meStore.data?.impersonate_as?.type === 'company' || userRoles.includes('admin')) {
        return;
      }

      const availableImpersonation = meStore.data?.available_impersonation;

      if (!availableImpersonation?.length) {
        setIsShowChooseProfilePopup(false);
      } else if (availableImpersonation?.length === 1 && !userRoles.includes('buyer') && !userRoles.includes('seller')) {
        onChangeImpersonation(availableImpersonation[0].id);
      } else {
        setIsShowChooseProfilePopup(true);
      }
    }
  }, [meStore.isLogined, meStore.data]);

  if (noMenu) {
    return (
      <>
        <Header open={open} toggleSideBar={() => layoutStore.toggleSidebar()} hideBurger={true} />
        <div className="children">{children}</div>
      </>
    );
  }

  return (
    <div className="d-flex">
      <Sidebar open={open} isMobile={isMobile} />
      <div className={clsx('wrapper', { 'wrapper-full': !open })}>
        <Header open={open} toggleSideBar={() => layoutStore.toggleSidebar()} />
        <div className="children">{children}</div>
      </div>
      <Notifications />
      {isShowChooseProfilePopup && (
        <ChooseProfilePopup
          handleClose={() => setIsShowChooseProfilePopup(false)}
          data={meStore.data}
          onChangeImpersonation={onChangeImpersonation}
        />
      )}
    </div>
  );
});

export const LayoutPageNotFound = ({ children }) => {
  return <main style={{ padding: '1rem 0' }}>{children}</main>;
};
