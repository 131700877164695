import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Table } from '@components/table/table';
import { Input } from '@components/input/input';
import { Pagination } from '@components/pagination/pagination';
import { blockUsersRequest, filteredUsers } from '@api/users';
import { citiesStore } from '@mobx/cities';
import { meStore } from '@mobx/me';
import { Select } from '@components/select/select';
import { dictionariesStore } from '@mobx/dictionaries';
import { Button } from '@components/button/button';

import { getColumns } from './utils';

import './user-tabs.scss';

const DEFAULT_FILTERS = {
  first_name: '',
  middle_name: '',
  last_name: '',
  created_at: '',
  status_id: '',
  city_id: '',
  gt_last_active_at: '',
  email: '',
  email_verified: '',
};

const PER_PAGE = [25, 50, 100, 250, 500];

export const UsersTab = observer(() => {
  const [users, setUsers] = useState();
  const { user_status } = dictionariesStore.dictionaries;
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { cities } = citiesStore;
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const columns = getColumns(setSorting);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
    onSelectAll: (isSelect, rows) => {
      const filteredRows = [];
      rows.some((item) => {
        if (rows.indexOf(item.id) === -1) {
          filteredRows.push(item);
        }
      });
      if (isSelect) {
        setSelectedRow(filteredRows);
      } else {
        setSelectedRow([]);
      }
    },
    onSelect: (row, isSelect, rowIndex) => {
      const rows = [];
      rows.push(row);
      if (isSelect) {
        setSelectedRow((prev) => [...prev, ...rows]);
      } else {
        selectedRow.splice(rowIndex, 1);
        setSelectedRow(selectedRow);
      }
    },
  };

  const getFilteredUsersList = (params, newFilters) => {
    const newParams = params || { page: 1, per_page: users?.per_page };

    if (sorting?.field) {
      newParams[`orders[${sorting.field}]`] = sorting?.order;
    }

    filteredUsers(newFilters || filters, newParams).then(setUsers);
  };

  const onClickApplyFilters = () => {
    getFilteredUsersList();
  };

  useEffect(() => {
    if (!user_status?.length) {
      dictionariesStore.request('status', { filters: { entity: 'user' }, saveKey: 'user_status' });
    }

    if (!citiesStore.cities.length) {
      citiesStore.fetch();
    }
  }, []);

  useEffect(() => {
    const params = { page: users?.page || 1, per_page: users?.per_page || PER_PAGE[0] };

    getFilteredUsersList(params);
  }, [JSON.stringify(sorting)]);

  const handleInputChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const clearAllFilters = () => {
    setFilters(DEFAULT_FILTERS);
    getFilteredUsersList({ page: 1, per_page: users?.per_page }, DEFAULT_FILTERS);
  };

  const handleBlock = async () => {
    const ids = selectedRow.map((user) => user.id);
    await blockUsersRequest(ids);
    getFilteredUsersList();
  };

  const elFilters = () => {
    return (
      <div className="filters-wrapper">
        <div className="d-flex align-items-center">
          <div onClick={() => setOpenFilter(!openFilter)} className="d-flex align-items-center">
            <div className="title">
              <span>Фильтры</span>
            </div>
            <img
              className={clsx('arrow-down cursor-pointer', { 'rotate-180': !openFilter })}
              src="/img/tabs/hide.svg"
              alt=""
            />
          </div>
        </div>
        <div className={!openFilter ? 'd-none' : 'filter'}>
          <div className="d-flex align-items-center flex-wrap">
            <Input
              size="m"
              placeholder="Имя"
              name="first_name"
              value={filters.first_name}
              onChange={handleInputChange}
            />
            <Input
              size="m"
              placeholder="Фамилия"
              name="last_name"
              value={filters.last_name}
              onChange={handleInputChange}
            />
            <Input
              size="m"
              placeholder="Отчество"
              name="middle_name"
              value={filters.middle_name}
              onChange={handleInputChange}
            />
            <Select
              options={cities?.map((city) => ({ value: city.id, label: city.name }))}
              valueId={filters?.city_id}
              size="m"
              placeholder="Город"
              onChange={({ value }) => handleInputChange({ target: { name: 'city_id', value } })}
            />
            <Select
              options={user_status}
              valueId={filters?.status_id}
              size="m"
              placeholder="Статус"
              onChange={({ value }) => handleInputChange({ target: { name: 'status_id', value } })}
            />
            <Input
              size="m"
              name="created_at"
              onChange={handleInputChange}
              value={filters.created_at}
              type="date"
              placeholder="Дата создания"
            />
            <Input
              size="m"
              name="gt_last_active_at"
              onChange={handleInputChange}
              value={filters.gt_last_active_at}
              type="date"
              placeholder="Дата последней активности"
            />
            <Input
              size="m"
              placeholder="Email"
              name="email"
              value={filters.email}
              onChange={handleInputChange}
              autoComplete="new-password"
            />
            <Select
              options={[{ value: '1', label: 'Да' }, { value: '0', label: 'Нет' }]}
              valueId={filters.email_verified}
              size="m"
              name="email_verified"
              placeholder="Email подтвержден"
              onChange={({ value }) => handleInputChange({ target: { name: 'email_verified', value } })}
            />
          </div>
          <div className="mt-20px d-flex gap-10px">
            <Button onClick={onClickApplyFilters} preset="primary">
              Применить фильтры
            </Button>
            <Button onClick={clearAllFilters} preset="ghost">
              Отменить все фильтры
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="users-tab-wrapper">
      {elFilters()}
      {!openFilter ? <hr className="mt-4" /> : ''}
      <div className="users-list">
        <div className="users-table-wrapper">
          <div className="d-flex align-items-center justify-content-between header mt-4 mb-4">
            <div className="title">Пользователи</div>
            {meStore.hasPermission('admin.users.edit') && (
              <Button to="/users/create" preset="primary">
                Добавить
              </Button>
            )}
          </div>
          {users?.data?.length > 0 ? (
            <>
              <Table
                data={users.data}
                columns={columns}
                selectRow={selectRow}
                classes="users-table-wrapper__table"
              />
              <div className="mt-2">
                <Button preset="primary" onClick={handleBlock}>
                  Заблокировать
                </Button>
              </div>
              <Pagination
                data={users}
                onChange={(params) => getFilteredUsersList(params)}
                perPageList={PER_PAGE}
              />
            </>
          ) : (
            <p>Нет пользователей</p>
          )}
        </div>
      </div>
    </div>
  );
});
