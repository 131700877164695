import React, { useState } from 'react';

import { Tooltip } from '@components/tooltip/tooltip';
import { CarChecks } from '@components/car-checks/car-checks';
import { TermsOfCooperationModal } from '@components/car-checks/terms-of-cooperation-modal';
import { getCurrencySymbol } from '@utils/common';
import { formatValueWithSpaces } from '@utils/format';

import './car-summary.scss';

export const CarSummary = ({ carSaleInfo, isMobile, lot, isLotSale, id, elEditButton }) => {
  const { car, city, owner } = carSaleInfo;
  const [showModal, setShowModal] = useState(false);

  const getCommission = () => {
    const currentPrice = lot.lastBet?.sum || lot.starting_price;
    const commission = currentPrice * (lot.commission / 100);

    if (commission > lot.minimal_commission) {
      return commission;
    }

    return lot.minimal_commission;
  };

  return (
    <div className="car-summary-wrapper">
      <div className="car-summary-wrapper__auction">
        <div className="car-summary-wrapper__auction-field">
          <div className="name">Марка:</div>
          <div className="value">{car?.brand?.name}</div>
        </div>
        <div className="car-summary-wrapper__auction-field">
          <div className="name">Модель:</div>
          <div className="value">{car?.model?.name}</div>
        </div>
        <div className="car-summary-wrapper__auction-field">
          <div className="name">Город:</div>
          <div className="value">{city?.name}</div>
        </div>
        <div className="car-summary-wrapper__auction-field">
          <div className="name">Продавец:</div>
          <div className="value">{owner?.display_name}</div>
        </div>
        {lot && (
          <div className="car-summary-wrapper__auction-field">
            <div className="name">Комиссия:</div>
            <div className="value commission cursor-pointer">
              {formatValueWithSpaces(getCommission())} {getCurrencySymbol()}
              <Tooltip text="Условия и сборы" isDisabled={isMobile}>
                <img
                  src="/img/icons/commission-info.svg"
                  alt=""
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      <CarChecks
        status={carSaleInfo.status?.code}
        vin={carSaleInfo.car?.vin}
        statusReportFile={carSaleInfo.status_report_file}
        inspectionType={carSaleInfo.inspection_type}
        isEdit={carSaleInfo.metadata?.actions?.update}
        lot={lot}
        isLotSale={isLotSale}
        isLotSaleCarPage={true}
        isMobile={isMobile}
        id={id}
        elEditButton={elEditButton}
      />
      {showModal && (
        <TermsOfCooperationModal
          handleClose={() => setShowModal(false)}
          lot={lot}
        />
      )}
    </div>
  );
};
