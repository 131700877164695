import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { formatPhoneMask } from '@utils/format';
import { DropdownButton } from '@components/dropdown/dropdown-button';
import { meStore } from '@mobx/me';
import { getRequisitesTemplates, getUserById } from '@api/users';
import { InfoList } from '@components/info-list/info-list';
import { RadioButton } from '@components/radio-button/radio-button';
import { Loader } from '@components/loader/loader';
import { getCompanies } from '@pages/user/utils';

import './user-profile.scss';

export const UserProfile = observer(() => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    getUserById(meStore.data?.user?.id).then(setUser);

    getRequisitesTemplates().then((resp) => {
      if (resp?.personal?.fields) {
        setFields(Object.values(resp.personal.fields));
      }
    });
  }, []);

  if (!user) {
    return <Loader />;
  }

  const renderDynamicField = (item) => {
    return (
      <div key={item.key} className="user-info__field-wrapper">
        <div className="name">{item.name}</div>
        <div className="value">
          {item.key === 'need_deposit' ? (
            user?.requisite?.[item.key] ? 'Да' : 'Нет'
          )
            : (user?.requisite?.[item.key] || '-')}
        </div>
      </div>
    );
  };

  const elActions = () => {
    if (meStore.hasPermission('admin.users.edit')) {
      return (
        <div className="d-flex actions">
          <div className="d-flex align-items-center action">
            <DropdownButton
              btnText="Действия"
              options={[
                {
                  label: 'Редактировать',
                  value: 'edit',
                  onClick: () => navigate(`/users/${user.id}/edit`),
                },
                {
                  label: 'Изменить пароль',
                  value: 'changePassword',
                  onClick: () => navigate(`/users/${user.id}/change-password`),
                },
              ]}
            />
          </div>
        </div>
      );
    }

    return (
      <Link to="/profile/change-password" className="btn btn-primary">
        Изменить пароль
      </Link>
    );
  };

  return (
    <div className="user-profile">
      <InfoList
        title={`${user.first_name} ${user.last_name}`}
        actions={elActions()}
        fields={[
          { label: 'ФИО', value: `${user.first_name} ${user.last_name} ${user.middle_name}` },
          { label: 'Телефон', value: formatPhoneMask(user.phone) },
          { label: 'Город', value: user.city ? user.city.name : '-' },
          { label: 'Почта', value: user.email },
          { label: 'Ответственный менеджер', value: user.responsible?.full_name || '-' },
          { label: 'Заявленная компания', value: user.declared_company_name || '-' },
          { label: 'Правовая форма', value: user.legal_form?.name || '-' },
          { label: 'Компании', value: getCompanies(user) },
          { custom: (
            <div className="info-list__item align-items-center">
              <div className="name">Депозит</div>
              <div className="d-flex gap-10px align-items-center h-40px">
                <RadioButton
                  id="input-yes"
                  label="Да"
                  name="requisite.need_deposit"
                  value="true"
                  disabled={true}
                  defaultChecked={user.requisite?.need_deposit === true}
                />
                <RadioButton
                  id="input-no"
                  label="Нет"
                  value="false"
                  name="requisite.need_deposit"
                  disabled={true}
                  defaultChecked={user.requisite?.need_deposit === false}
                />
              </div>
            </div>
          ) },
          { custom: (
            <div className="info-list__item align-items-center">
              <div className="info-list__item-label">Роль</div>
              <div className="info-list__item-value user-profile__item-checkboxs">
                <div className="user-profile__item-checkbox">
                  <input
                    type="checkbox"
                    name="roles"
                    value="buyer"
                    id="checkboxBuyer"
                    disabled={true}
                    checked={Boolean(user?.roles?.find((item) => item.name === 'buyer'))}
                  />
                  <label className="form-check-label" htmlFor="checkboxBuyer">
                    Покупатель
                  </label>
                </div>
                <div className="user-profile__item-checkbox">
                  <input
                    type="checkbox"
                    name="roles"
                    value="seller"
                    id="checkboxSeller"
                    disabled={true}
                    checked={Boolean(user.roles.find((item) => item.name === 'seller'))}
                  />
                  <label className="form-check-label" htmlFor="checkboxSeller">
                    Продавец
                  </label>
                </div>
              </div>
            </div>
          ) },
        ]}
      />

      {fields.length > 0 && (
        <div className="mt-4">
          <div className="title">Реквизиты</div>
          <div className="user-info mt-3">
            {fields.map((item) => (item.key === 'agent_contract' ? (
              <div key={item.key} className="user-info__field-wrapper">
                <div className="name">Договор</div>
                <div className="value">
                  {user?.requisite?.agent_contract?.url ? (
                    <a href={user.requisite.agent_contract.url} download={user.requisite.agent_contract.original_name}>
                      {user.requisite.agent_contract.original_name}
                    </a>
                  ) : '-'}
                </div>
              </div>
            ) : renderDynamicField(item)))}
          </div>
        </div>
      )}
    </div>
  );
});
